<template v-if="isCupsleeve">
  <mcs-alert
    boldText="FREE Shipping"
    text="now available on ALL cupsleeve orders!"
    type="help"
    class="mt-3"
  />
  <!-- <v-alert
    text
    color="error"
    border="left"
    class="mt-4 body-2 font-weight-regular"
  >
    <a
      target="_blank"
      class="d-flex align-center bare-link"
      @click="go"
    >
      <div class="mr-3">
        <div><strong>Click this image</strong> to view the difference in cupsleeve paper.</div>
        <br />It is very important that you select the right option for your specific use case.
      </div>
      <img
        class="rounded-lg"
        src="https://res.cloudinary.com/mycreativeshop/image/upload/c_scale,q_100,w_150/v1615928314/public/cupsleeve-paper-type-guide.jpg"
      />
    </a>
  </v-alert> -->
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'CupsleeveAlert',

  data() {
    return {};
  },

  computed: { ...mapGetters({ project: 'printcart/project' }) },

  methods: {
    go() {
      var url =
        'https://res.cloudinary.com/mycreativeshop/image/upload/c_scale,q_100,w_1200/v1615928314/public/cupsleeve-paper-type-guide.jpg';
      if (this.$store.state.config.route.iframe) {
        window.parent.postMessage(
          { type: 'go-to-url', data: { url: this.project.detailUrl, newWindow: false } },
          '*'
        );
      } else {
        window.open(url, '_blank');
      }
    }
  }
};
</script>
