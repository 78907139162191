import { DESIGN_PROJECT_STATUS } from '../../utils/constants';

const state = {
  projects: [],
  selectedProjectId: 0,
  loadingProjects: false,
  showTeam: false
};

const getters = {
  claimedProjects(state, rootState) {
    return state.projects.filter((x) => x.statusId === DESIGN_PROJECT_STATUS.ASSIGNED);
  },

  underReviewProjects(state) {
    return state.projects.filter((x) => x.statusId === DESIGN_PROJECT_STATUS.UNDER_REVIEW);
  },

  completedProjects(state) {
    return state.projects.filter((x) => x.statusId === DESIGN_PROJECT_STATUS.COMPLETE);
  },

  availableProjects(state) {
    return state.projects.filter((x) => x.statusId === DESIGN_PROJECT_STATUS.OPEN);
  },

  hidePrice(state, getters, rootState, rootGetters) {
    if (rootGetters['user/isAdmin']) return false;
    return rootState.user.username !== 'umairzulfiqar29@gmail.com';
  }
};

const actions = {
  getAll({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`designProject/get?team=${state.showTeam}`)
        .then((response) => {
          commit('SET_PROJECTS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDetail({ commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`designProject/detail?id=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  claim({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`designProject/claim?id=${id}`)
        .then((response) => {
          commit('UPDATE_PROJECT', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  release({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`designProject/release?id=${id}`)
        .then((response) => {
          commit('UPDATE_PROJECT', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  submitReview({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`designProject/submitReview?id=${id}`)
        .then((response) => {
          commit('UPDATE_PROJECT', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  retractReview({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`designProject/retractReview?id=${id}`)
        .then((response) => {
          commit('UPDATE_PROJECT', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setTeamOwnerApproved({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('designProject/setTeamOwnerApproved', payload)
        .then((response) => {
          commit('UPDATE_PROJECT', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },

  UPDATE_PROJECT(state, item) {
    var index = state.projects.findIndex((x) => x.id === item.id);
    this._vm.$set(state.projects, index, Object.assign(state.projects[index], item));
  },

  SET_SELECTED_PROJECT_ID(state, id) {
    state.selectedProjectId = id;
  },

  SHOW_TEAM(state, val) {
    state.showTeam = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
