<template>
  <Drawer
    :id="drawerId"
    preloadContent
    :height="height"
    :bottom="!expanded"
    :class="[expanded ? '' : 'rounded-t-xl']"
    :loading="loading"
    :width="expanded ? '90%' : undefined"
    hide-header
  >
    <div
      v-if="open"
      :class="{
        'd-flex': true,
        'flex-row': true,
        'justify-space-between': true,
        'h-full': true,
        'w-full': true
      }"
    >
      <template v-if="expanded">
        <RoutesMap
          v-if="is_eddm"
          id="audienceExpandedMap"
          class="w-full"
          :expanded="expanded"
          @expand="expand"
          @search="searchRoutes"
          @selected="routeSelected"
        />
        <ListPurchaseMap
          v-else-if="is_list_purchase"
          id="listPurchaseExpandedMap"
          class="w-full"
          :expanded="expanded"
          @expand="expand"
        />
      </template>
      <div
        class="h-full d-flex flex-column"
        :style="{ width: expanded ? '450px' : '100%' }"
      >
        <div class="pa-4 text-h6 text-truncate d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <div v-if="title">
              <v-icon
                v-if="!showUploadGuide"
                class="mr-1"
                color="black"
              >
                mdi-account-supervisor-circle
              </v-icon>
              {{ title }}
            </div>
          </div>
          <mcs-btn
            icon
            color="black"
            prepend-icon="mdi-close"
            @click="customBackFunc(true)"
          ></mcs-btn>
        </div>
        <div class="px-4 h-full d-flex flex-column autoscroll-y">
          <MailingListGuide
            v-if="showUploadGuide"
            @back="showUploadGuide = false"
          />
          <SelectAudience
            v-else-if="selectAudience"
            @add="selectAudience = false"
            @guide="showUploadGuide = true"
          />
          <Routes
            v-else-if="is_eddm"
            ref="routes"
            :expanded="expanded"
            @expand="expand"
          />
          <Upload
            v-else-if="is_upload"
            ref="upload"
            @reset="resetUpload"
            @guide="showUploadGuide = true"
          />
          <ListPurchase
            v-else-if="is_list_purchase"
            ref="list_purchase"
            :expanded="expanded"
            @expand="expand"
          />
        </div>

        <v-divider class="mt-4" />
        <v-row
          v-if="!selectAudience && !showUploadGuide"
          class="flex-grow-0 greyLight py-2 ma-0 px-4"
        >
          <v-col cols="auto">
            <mcs-btn
              large
              color="black"
              :disabled="saveDisabled"
              :loading="saving"
              @click="save"
            >
              Save
            </mcs-btn>
          </v-col>
          <v-col class="my-auto">
            <div v-if="is_upload && has_addresses_mapping">
              <span class="font-weight-bold">Next step:</span>
              We'll check for duplicates & validate addresses
            </div>
            <template v-else>
              <div class="d-flex flex-column">
                <div>
                  <span class="body-2 mr-1">Addresses:</span>
                  <v-progress-circular
                    v-if="count_loading"
                    :size="25"
                    color="primary"
                    indeterminate
                  />
                  <span
                    v-else-if="address_count"
                    class="font-weight-bold"
                  >
                    {{ address_count | separator }}
                  </span>
                  <span
                    v-else-if="is_list_purchase && list_purchases.length"
                    class="font-weight-bold"
                  >
                    0 (Refine search)
                  </span>
                  <v-icon
                    v-else
                    small
                  >
                    mdi-minus
                  </v-icon>
                </div>
                <div class="d-flex align-center font-weight-bold mt-1">
                  <Error
                    v-if="maximum && address_count > maximum"
                    :value="maximum"
                    label="Max"
                    :tooltip="`The maximum number of addresses we can support in our mailing service is ${$options.filters.separator(
                      maximum
                    )}. Please remove addresses to continue or consider ordering prints without our mailing service.`"
                  />
                  <Error
                    v-else-if="minimum && address_count && address_count < minimum"
                    :value="minimum"
                    label="Min"
                    :tooltip="`The minimum number of addresses to qualify for our mailing service is ${$options.filters.separator(
                      minimum
                    )}. Please add more addresses to continue or consider ordering prints within our mailing service.`"
                  />
                  <template v-else>
                    <span class="body-2 mr-1">Total Price:</span>
                    <v-progress-circular
                      v-if="count_loading"
                      :size="25"
                      color="primary"
                      indeterminate
                    />
                    <v-icon
                      v-else-if="!address_count"
                      small
                    >
                      mdi-minus
                    </v-icon>
                    <template v-else>
                      <div
                        v-if="priceOriginal > price"
                        class="text-decoration-line-through mr-2"
                      >
                        {{ priceOriginal | currency }}
                      </div>
                      <div :class="{ 'success--text': priceOriginal > price }">
                        {{ price | currency }}
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
      </div>
    </div>
  </Drawer>
</template>

<script>
import Drawer from '@/components/layout/Drawer';
import MailingListGuide from '@/components/print/checkout/snailblast/MailingListGuide';
import SelectAudience from '@/components/print/checkout/snailblast/SelectAudience.vue';
import Error from '@/components/print/checkout/snailblast/audience/Error';
import ListPurchase from '@/components/print/checkout/snailblast/listpurchase/Index';
import ListPurchaseMap from '@/components/print/checkout/snailblast/listpurchase/Map';
import Routes from '@/components/print/checkout/snailblast/routes/Index.vue';
import RoutesMap from '@/components/print/checkout/snailblast/routes/Map';
import Upload from '@/components/print/checkout/snailblast/upload/Index';
import { DRAWERS } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'SnailblastAudienceDrawer',

  components: {
    Drawer,
    MailingListGuide,
    SelectAudience,
    Routes,
    RoutesMap,
    Upload,
    ListPurchase,
    ListPurchaseMap,
    Error
  },

  data() {
    return {
      valid: true,
      loading: false,
      validating: false,
      drawerId: DRAWERS.SNAILBLAST_AUDIENCE,
      selectAudience: false,
      expanded: false,
      showUploadGuide: false
    };
  },

  computed: {
    height() {
      if (this.expanded || this.$vuetify.breakpoint.mobile) {
        return '100%';
      }
      return '90%';
    },
    saveText() {
      return 'Save Shipping';
    },
    title() {
      if (this.showUploadGuide) {
        return 'Mailing List Guide';
      }
      if (this.selectAudience) {
        return 'Audience';
      }
      if (this.is_eddm) {
        return 'Every Door Direct Mail (EDDM®)';
      }
      if (this.is_list_purchase) {
        return 'Build Target List';
      }
      if (this.is_upload) {
        return 'Upload List';
      }
      return '';
    },
    open() {
      return this.drawerId === this.topDrawer?.id;
    },
    saveDisabled() {
      if (this.is_upload && this.has_addresses_mapping) {
        return !this.addresses_mapping_ready;
      }
      return (
        (!!this.maximum && this.address_count > this.maximum) ||
        (!!this.minimum && this.address_count < this.minimum)
      );
    },
    maximum() {
      const list = this.mailing_options.filter((x) => x.campaignTypeId === this.campaign.typeId);
      if (list.length) {
        return list[0].maximum;
      }
      return 0;
    },
    minimum() {
      const list = this.mailing_options.filter((x) => x.campaignTypeId === this.campaign.typeId);
      if (list.length) {
        return list[0].minimum;
      }
      return 0;
    },
    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt',
      topDrawer: 'drawers/topDrawer',
      campaign: 'snailblast/campaign/edit_campaign',
      selected_routes: 'snailblast/routes/selected',
      price: 'printcart/price',
      priceOriginal: 'printcart/priceOriginal',
      route_address_count: 'snailblast/routes/address_count',
      has_addresses_mapping: 'snailblast/campaign/has_addresses_mapping',
      is_eddm: 'snailblast/campaign/is_eddm',
      is_upload: 'snailblast/campaign/is_upload',
      addresses: 'snailblast/campaign/addresses',
      is_list_purchase: 'snailblast/campaign/is_list_purchase',
      list_purchase_address_count: 'snailblast/campaign/list_purchase_address_count',
      count_loading: 'snailblast/campaign/count_loading',
      saving: 'snailblast/campaign/saving',
      address_count: 'snailblast/campaign/address_count',
      mailing_options: 'printcart/mailing_options',
      addresses_mapping_ready: 'snailblast/campaign/addresses_mapping_ready',
      list_purchases: 'snailblast/campaign/list_purchases',
      campaign_idea: 'printcart/campaign_idea'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.open) {
        // var drawerData = this.initialData(this.drawerId);
        this.expanded = false;
        this.showUploadGuide = false;
        this.$store.dispatch('snailblast/campaign/setSaving', false);
        this.selectAudience = true;
        if (this.is_upload && (this.campaign.recipientCount || this.has_addresses_mapping)) {
          this.selectAudience = false;
        } else if ((this.is_list_purchase || this.is_eddm) && this.campaign.recipientCount) {
          this.selectAudience = false;
        } else if (this.is_list_purchase && this.campaign_idea) {
          this.selectAudience = false;
        }
      }
    }
  },

  mounted() {},

  methods: {
    save() {
      if (this.is_eddm) {
        this.$refs.routes.save();
      } else if (this.is_upload) {
        this.$refs.upload.save();
      } else if (this.is_list_purchase) {
        this.$refs.list_purchase.save();
      }
    },
    expand() {
      this.expanded = !this.expanded;
    },
    resetUpload() {
      this.$store.dispatch('snailblast/campaign/resetUpload').then(() => {
        this.selectAudience = true;
      });
    },
    customBackFunc(isClose) {
      if (this.expanded) {
        this.expand();
      } else if (isClose) {
        this.$store.commit('drawers/GO_BACK');
      } else if (this.showUploadGuide) {
        this.showUploadGuide = false;
      } else if (this.selectAudience) {
        this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
      } else {
        this.selectAudience = true;
      }
    },
    searchRoutes(data) {
      this.$refs.routes.getRoutes(data);
    },
    routeSelected(data) {
      this.$refs.routes.scrollToItem(data);
    }
  }
};
</script>

<style scoped>
nav {
  top: inherit !important;
  bottom: 0 !important;
}
.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 90% !important;
}
</style>
