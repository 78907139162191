import { ASSET_PROJECT_STATUS } from '../../utils/constants';

const state = {
  projects: [],
  selectedProjectId: 0,
  loadingProjects: false
};

const getters = {
  claimedProjects(state, rootState) {
    return state.projects.filter(
      (x) => x.inspirationalImageStatusId === ASSET_PROJECT_STATUS.ASSIGNED
    );
  },

  underReviewProjects(state) {
    return state.projects.filter(
      (x) => x.inspirationalImageStatusId === ASSET_PROJECT_STATUS.UNDER_REVIEW
    );
  },

  completedProjects(state) {
    return state.projects.filter(
      (x) => x.inspirationalImageStatusId === ASSET_PROJECT_STATUS.PUBLISHED
    );
  },

  availableProjects(state) {
    return state.projects.filter((x) => x.inspirationalImageStatusId === ASSET_PROJECT_STATUS.OPEN);
  }
};

const actions = {
  getAll({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('imageProject/get')
        .then((response) => {
          commit('SET_PROJECTS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDetail({ commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`imageProject/detail?id=${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  claim({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`imageProject/claim?id=${id}`)
        .then((response) => {
          commit('UPDATE_PROJECT', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  release({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`imageProject/release?id=${id}`)
        .then((response) => {
          commit('UPDATE_PROJECT', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadImages({ commit }, req) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (var file of req.files) {
        formData.append('file', file);
      }
      this._vm.$api
        .post(`imageProject/uploadImages?id=${req.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          commit('UPDATE_PROJECT', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },

  UPDATE_PROJECT(state, item) {
    var index = state.projects.findIndex((x) => x.id === item.id);
    this._vm.$set(state.projects, index, Object.assign(state.projects[index], item));
  },

  SET_SELECTED_PROJECT_ID(state, id) {
    state.selectedProjectId = id;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
