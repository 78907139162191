import waitFor from '../utils/waitFor';

export default {
  data() {
    return {};
  },

  methods: {
    $loadCustomerIO() {
      if (this.$store.getters['user/isMCS']) {
        var str =
          '<script>!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdp.customer.io/v1/analytics-js/snippet/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._writeKey=key;analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.3";analytics.load("15b98bd9ca1e40bb4357",{"integrations":{"Customer.io In-App Plugin":{siteId:"62b538157eb318add530"}}});analytics.page();}}();</script>';
        var range = document.createRange();
        var documentFragment = range.createContextualFragment(str);
        document.head.appendChild(documentFragment);

        var checker = () => {
          return typeof window.analytics !== 'undefined' && typeof window.analytics === 'object';
        };
        waitFor(checker, 60000, 'customerio').then(() => {
          window.analytics.identify(this.$store.state.user.username, {
            name: this.$store.state.user.profile.name,
            plan: this.$store.state.user.plan.name,
            // Strongly recommended when you first identify someone
            // This is the timestamp when the user first appears (in Unix format)
            created_at: this.$store.state.user.profile.createdDate
          });
        });
      }
    }
  }
};
