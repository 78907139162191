<template>
  <mcs-sheet
    class="relative overflow-hidden"
    :class="[lightColor.NAME]"
  >
    <div class="d-flex flex-column flex-grow-1 ml-4">
      <div class="d-flex justify-space-between align-center">
        <div class="text-h6">
          {{ title }}
        </div>
        <mcs-btn
          icon
          prependIcon="mdi-close"
          :color="color.NAME"
          @click="closeFunc"
        />
      </div>
      <div class="mt-2">
        {{ message }}
      </div>
    </div>
    <div
      class="bar"
      :style="{ 'background-color': color.HEX, 'border-color': color.HEX }"
    />
  </mcs-sheet>
</template>

<script>
import { COLORS } from '../../utils/constants';

export default {
  name: 'McsToast',

  props: {
    isError: Boolean,
    message: { type: String, default: '' },
    closeFunc: { type: Function, default: () => {} }
  },

  computed: {
    title() {
      return this.isError ? 'Error' : 'Success';
    },

    color() {
      return this.isError ? COLORS.ERROR : COLORS.SUCCESS;
    },

    lightColor() {
      return this.isError ? COLORS.ERROR_LIGHT : COLORS.SUCCESS_LIGHT;
    }
  }
};
</script>

<style scoped>
.bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16px;
  border-right: solid 1.5px;
}
</style>
