import { sortBy } from 'lodash';
import { ENUMS, ROUTES } from '../constants';
export default {
  computed: {
    parentId() {
      let val = this.$route.params.parentId;
      if (val && val.length > 0) {
        val = parseInt(val);
      }
      return val || null;
    },
    collectionId() {
      const id = this.$store.getters['admin/collections/id'];

      const collections =
        sortBy(this.$store.getters['admin/collections/list'], [
          function (o) {
            return id !== o.id;
          }
        ]);
      let val = collections.map(x => x.id)[0];
      if (val && val.length > 0) {
        val = parseInt(val);
      }
      return val || null;
    },
    links() {
      return [
        {
          title: 'Templates',
          path: this.getAssetPath(ENUMS.ASSET_TYPES.TEMPLATES)
        },
        {
          title: 'Colors',
          path: this.getAssetPath(ENUMS.ASSET_TYPES.COLORS)
        },
        {
          title: 'Images',
          path: this.getAssetPath(ENUMS.ASSET_TYPES.IMAGES)
        }
      ];
    }
  },
  methods: {
    getPublishedColor(published, hex) {
      if (published) {
        return 'successLight';
      }
      return 'accentLight';
    },
    getAssetPath(type, parentId) {
      const link = parentId ? ROUTES.ADMIN_ASSETS_CHILD_SETUP.PATH.replace(':parentId', parentId) : ROUTES.ADMIN_ASSETS_SETUP.PATH;

      return link.replace(':typeId', type);
    }
  }
};
