import { APP_EVENTS } from '../utils/constants';
import waitFor from '../utils/waitFor';

export default {
  created() {
    this.$eventBus.$once(APP_EVENTS.APP_DATA_READY, () => {
      this.$loadHelpscout();
    });

    this.$eventBus.$on(APP_EVENTS.OPEN_HELPSCOUT, (opts) => {
      opts = opts || {};
      if (this.$store.state.config.helpscoutReady) {
        window.Beacon('open');
        if (opts.ask) window.Beacon('navigate', '/ask/');
      }
    });
  },

  data() {
    return {};
  },

  methods: {
    $loadHelpscout() {
      var beaconId = 'b1774456-e1e1-4335-bf72-d1c8d069f462';
      var config = {
        color: this.$vuetify.theme.themes.light.primary,
        docsEnabled: true,
        display: {
          style: 'manual',
          iconImage: 'question',
          position: 'right'
        }
      };
      var identifyMethod = 'prefill';
      var user = {
        userId: this.$store.state.user.userId,
        accountId: 'mcs'
      };
      if (!this.$store.state.user.plan.isGuest) {
        user.email = this.$store.state.user.username;
        user.country = this.$store.state.user.profile.country?.iso;
        user.name = this.$store.state.user.profile.name;
      }
      var hsChecker = () => {
        return typeof window.Beacon !== 'undefined' && typeof window.Beacon === 'function';
      };
      waitFor(hsChecker, 60000, 'helpscout').then(() => {
        window.Beacon('init', beaconId);
        window.Beacon('on', 'ready', () => {
          window.Beacon('config', config);
          window.Beacon('on', 'search', (query) => {
            console.log('helpscout searched');
          });
          this.$store.commit('config/SET_HELPSCOUT_READY');
          window.Beacon(identifyMethod, user);
        });
      });
    }
  }
};
