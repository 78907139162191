import numeral from 'numeral';

const percentage = (value) => numeral(value).format('0.[00]%');

const separator = (value) => numeral(value).format('0,0');

const currency = (value) => numeral(value).format('$0,0.00');

const currencyShort = (value) => numeral(value).format('$0,0');

const currencyBig = (value) => numeral(value).format('$0,0.0a');

const currencySmall = (value) => numeral(value).format('$0,0.000');

const twoDecimals = (value) => numeral(value).format('0.00');

const oneDecimal = (value) => numeral(value).format('0.0');

const noDecimal = (value) => numeral(value).format('0');

const noDecimalAbs = (value) => numeral(Math.abs(value)).format('0');

export default {
  percentage,
  separator,
  currency,
  currencyShort,
  currencyBig,
  currencySmall,
  twoDecimals,
  oneDecimal,
  noDecimal,
  noDecimalAbs
};
