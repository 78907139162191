<template>
  <v-alert
    :color="color.fill"
    :dismissible="dismissible"
    :style="{ border: 'solid 1.5px ' + color.other + '!important' }"
  >
    <div class="d-flex justify-space-between">
      <div>
        <v-icon
          v-if="prependIcon"
          class="mr-3"
          small
        >
          {{ prependIcon }}
        </v-icon>
        <span
          v-if="boldText"
          class="font-weight-bold"
        >
          {{ boldText }}
        </span>
        <template v-if="text">
          <span
            v-if="boldText"
            class="mx-1 font-weight-bold"
          >
            -
          </span>
          <span v-html="text"></span>
        </template>

        <slot />
      </div>
      <router-link
        v-if="routeUrl"
        :to="routeUrl"
        class="font-weight-bold text-decoration-none flex-shrink-0 align-self-center ml-4 black--text"
      >
        {{ linkText }}
      </router-link>
      <a
        v-else-if="linkUrl"
        :href="linkUrl"
        :target="openLinkInNewWindow ? '_blank' : ''"
        class="font-weight-bold text-decoration-none flex-shrink-0 align-self-center ml-4 black--text"
      >
        {{ linkText }} >
      </a>
      <slot name="actions"></slot>
    </div>
    <template
      v-if="dismissible"
      #close="{ toggle }"
    >
      <v-icon
        small
        class="ml-4"
        @click="dismiss(toggle)"
      >
        mdi-close
      </v-icon>
    </template>
  </v-alert>
</template>

<script>
import { ALERT_TYPES, COLORS } from '../../utils/constants';

export default {
  name: 'McsAlert',

  props: {
    type: { type: String, default: 'alert' }, // valid types are: 'Error', 'Alert', 'Success', 'Help'
    boldText: { type: String, default: '' },
    text: { type: String, default: '' },
    prependIcon: { type: String, default: '' },
    linkUrl: { type: String, default: '' },
    linkText: { type: String, default: 'View Link ' },
    routeUrl: { type: String, default: '' },
    openLinkInNewWindow: Boolean,
    dismissible: Boolean,
    dismissibleKey: { type: String, default: '' }
  },

  computed: {
    color() {
      var color = {
        fill: '',
        border: ''
      };
      switch (this.type) {
        case ALERT_TYPES.ALERT:
          color.fill = COLORS.ACCENT_LIGHT.NAME;
          color.other = COLORS.ACCENT.HEX;
          break;
        case ALERT_TYPES.SUCCESS:
          color.fill = COLORS.SUCCESS_LIGHT.NAME;
          color.other = COLORS.SUCCESS.HEX;
          break;
        case ALERT_TYPES.ERROR:
          color.fill = COLORS.ERROR_LIGHT.NAME;
          color.other = COLORS.ERROR.HEX;
          break;
        case ALERT_TYPES.HELP:
          color.fill = COLORS.PRIMARY_LIGHT.NAME;
          color.other = COLORS.PRIMARY.HEX;
          break;
        case ALERT_TYPES.INFO:
          color.fill = COLORS.GREY_LIGHT.NAME;
          color.other = COLORS.GREY.HEX;
          break;
      }
      return color;
    }
  },

  methods: {
    dismiss(toggle) {
      toggle();
      this.$emit('dismiss');
      if (this.dismissibleKey) {
        this.setLocal(this.dismissibleKey, true);
      }
    }
  }
};
</script>

<style></style>
