<template>
  <div>
    <v-row>
      <v-col cols="12">
        <AddressCard
          :address="address"
          button-icon="mdi-pencil"
          button-icon-small
          @click="clearCandidates"
        >
          <template #top>
            <mcs-alert
              text="The address you entered could not be verified."
              type="alert"
              prepend-icon="mdi-alert-circle"
            ></mcs-alert>
          </template>
        </AddressCard>
      </v-col>
      <v-col
        cols="12"
        class="font-weight-bold"
      >
        Use a suggested alternative
      </v-col>
      <v-col
        v-for="(item, i) in candidates"
        :key="i"
        cols="12"
      >
        <AddressCard
          :address="item"
          @click="$emit('set', item)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="auto"
        class="font-weight-bold"
      >
        Use your original address
      </v-col>
      <v-spacer />
      <v-tooltip
        bottom
        color="black"
        max-width="300"
        min-width="250"
        nudge-left="25"
      >
        <template #activator="{ on, attrs }">
          <v-col
            cols="auto"
            class="accent--text body-2 my-auto no-user-select"
            v-bind="attrs"
            v-on="on"
          >
            Not recommended
            <v-icon
              small
              color="accent"
              class="ml-1 hover-pointer"
            >
              mdi-alert-circle
            </v-icon>
          </v-col>
        </template>
        <span class="body-2">
          If possible, please edit your address above or use a suggested alternative. Validated
          addresses ensure you receive the fastest and most affordable shipping options.
        </span>
      </v-tooltip>
    </v-row>
    <v-row>
      <v-col
        v-if="address"
        cols="12"
      >
        <AddressCard
          :address="address"
          @click="$emit('set')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddressCard from '@/components/print/checkout/addresses/Card';
import { mapGetters } from 'vuex';
export default {
  components: { AddressCard },
  props: {
    address: { type: Object, default: () => {} }
  },
  data: () => ({
    saving: false
  }),
  computed: {
    ...mapGetters({
      state_abbr: 'lookups/state_abbr',
      candidates: 'user/candidates'
    })
  },
  mounted() {},
  methods: {
    clearCandidates() {
      this.$store.dispatch('user/clearCandidates');
    }
  }
};
</script>
