<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    content-class="align-self-start rounded-xl"
    @keydown.esc="cancel"
    @click:outside="cancel"
  >
    <mcs-card :title="title">
      <v-divider />
      <div
        v-show="!!message"
        class="body-2 py-5"
      >
        <div v-html="message" />
      </div>
      <template v-if="showSupport">
        <v-divider />
        <div class="d-flex align-center py-2 justify-space-between">
          <div class="mr-2 font-weight-medium body-2">Need more help?</div>
          <mcs-btn
            color="grey"
            @click="support"
          >
            Contact Support
            <v-icon class="ml-2">
              {{ icon }}
            </v-icon>
          </mcs-btn>
        </div>
      </template>

      <v-divider />
      <template #actions>
        <mcs-btn
          v-show="options.cancelText !== '' && !options.closeOnly"
          color="error"
          text
          @click.native="cancel"
        >
          {{ options.cancelText }}
        </mcs-btn>
        <v-spacer />
        <mcs-btn
          v-show="options.okText !== '' || options.closeOnly"
          :color="options.color"
          text
          @click.native="ok"
        >
          {{ options.closeOnly ? 'Close' : options.okText }}
        </mcs-btn>
      </template>
    </mcs-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Alert Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="alert"></confirm>
 *
 * Call it:
 * this.$refs.alert.open('Delete', 'Are you sure?', { color: 'red' }).then(() => {})
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$alert
 * <template>
 *   <v-app>
 *     ...
 *     <alert ref="alert"></alert>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$alert = this.$refs.alert.open
 * }
 */
import { APP_EVENTS } from '../../utils/constants';

export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 400,
      zIndex: 200,
      okText: 'Ok',
      cancelText: 'Cancel',
      closeOnly: false,
      hideSupport: false
    },
    icon: 'mdi-face-agent'
  }),

  computed: {
    showSupport() {
      return this.$store.state.config.helpscoutReady && !this.options.hideSupport;
    }
  },

  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.$store.commit('config/INCREMENT_DIALOGS_OPEN');
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      this.resolve(true);
      this.dialog = false;
      this.$store.commit('config/DECREMENT_DIALOGS_OPEN');
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
      this.$store.commit('config/DECREMENT_DIALOGS_OPEN');
    },
    support() {
      this.$eventBus.$emit(APP_EVENTS.OPEN_HELPSCOUT, { ask: true });
    }
  }
};
</script>
