<template>
  <v-dialog
    v-model="dialog"
    width="600"
    @click:outside="cancel"
  >
    <mcs-sheet>
      <mcs-btn
        icon
        color="black"
        prepend-icon="mdi-close"
        class="float-right"
        @click="cancel"
      />
      <div class="text-h6">Move {{ rootFolderName }}</div>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row>
          <v-col cols="12">
            <v-treeview
              v-if="items && items.length"
              v-model="tree"
              :items="items"
              item-key="id"
              selectable
              selection-type="independent"
              :open="open"
              @input="changeFolder"
            >
              <template #prepend="{ open }">
                <v-icon>
                  {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                </v-icon>
              </template>
            </v-treeview>
          </v-col>
          <v-col cols="auto">
            <mcs-btn
              large
              color="black"
              :loading="saving"
              @click="save"
            >
              Save
            </mcs-btn>
          </v-col>
        </v-row>
      </v-form>
    </mcs-sheet>
  </v-dialog>
</template>

<script>
import { cloneDeep, uniq } from 'lodash';
import { mapGetters } from 'vuex';
export default {
  props: {
    foldersGetter: { type: String, default: '' },
    rootFolderName: { type: String, default: '' },
    saveAction: { type: String, default: '' }
  },
  data: () => ({
    dialog: false,
    saving: false,
    valid: false,
    item: {},
    tree: [],
    open: [],
    items: [],
    additionalIds: []
  }),
  computed: {
    folders() {
      return this.$store.getters[this.foldersGetter];
    },
    ...mapGetters({})
  },
  methods: {
    changeFolder() {
      if (this.tree.length) {
        this.tree = [this.tree[this.tree.length - 1]];
      }
    },
    cancel() {
      this.dialog = false;
    },
    showDialog(item, additionalIds) {
      this.dialog = true;
      this.item = cloneDeep(item);
      this.additionalIds = additionalIds || [];
      this.items = [{ id: null, name: this.rootFolderName, children: this.getItems(null) }];
      this.tree = [this.item.folderId];
      this.open = [null, this.item.folderId];
    },
    loadData() {},
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        const ids = uniq(this.additionalIds.concat(this.item.id));
        Promise.all(
          ids.map((x) => this.$store.dispatch(this.saveAction, { id: x, folderId: this.tree[0] }))
        )
          .then((data) => {
            this.$emit('save');
            if (this.$refs.form) {
              this.$refs.form.reset();
            }
            this.dialog = false;
          })
          .catch((error) => {
            this.logError(error, 'An error occurred while moving an item.');
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
    getItems(parentId) {
      return this.folders
        .filter((x) => x.parentId === parentId)
        .map((x) => ({ id: x.id, name: x.name, children: this.getItems(x.id) }));
    }
  }
};
</script>
