<template>
  <mcs-btn
    color="primary"
    :outlined="$vuetify.breakpoint.mobile"
    class="mr-3 px-4"
    :href="bindings.href"
    prependIcon="mdi-plus"
    v-on="bindings.onClick !== null ? { click: bindings.onClick } : {}"
  >
    New Design
  </mcs-btn>
</template>

<script>
import { DRAWERS } from '../../utils/constants';

export default {
  name: 'NewDesignButton',

  data() {
    return {};
  },

  computed: {
    catalogUrl() {
      return this.$store.getters['config/catalogUrl'];
    },

    useCatalogDrawer() {
      return this.$store.state.config.inDevEnv || !this.$store.getters['user/isMCS'];
    },

    bindings() {
      return {
        href: this.useCatalogDrawer ? null : this.catalogUrl,
        onClick: this.useCatalogDrawer ? this.openCatalogDrawer : null
      };
    }
  },

  methods: {
    openCatalogDrawer() {
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.CATALOG_DRAWER);
    }
  }
};
</script>

<style></style>
