const state = {
  list: []
};

const getters = {
  list(state) {
    return state.list || [];
  }
};

const actions = {
  get({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('designgroups/get')
        .then((response) => {
          commit('SET_LIST', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  save({ commit }, designgroup) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('designgroups/save', designgroup)
        .then((response) => {
          commit('SET_DESIGN_GROUP', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_DESIGN_GROUP(state, payload) {
    const index = state.list.findIndex((x) => x.id === payload.id);
    if (index >= 0) {
      this._vm.$set(state.list, index, payload);
    } else {
      state.list.push(payload);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
