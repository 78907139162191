<template>
  <mcs-btn
    icon
    small
    :class="[cartItems.length > 0 ? 'mr-5' : 'mr-3']"
    color="black"
    :disabled="disabled"
    @click="click"
  >
    <v-badge
      :value="cartItems.length > 0"
      color="primary"
      :content="cartItems.length"
    >
      <v-icon
        size="18"
        class="thick"
      >
        mdi-cart-outline
      </v-icon>
    </v-badge>
  </mcs-btn>
</template>

<script>
import { mapGetters } from 'vuex';
import { DRAWERS, PRINT_CHECKOUT_SCREENS } from '../../utils/constants';

export default {
  name: 'CartButton',

  props: { disableLoadData: { type: Boolean, default: false } },

  data() {
    return {};
  },

  computed: {
    disabled() {
      return false;
    },

    ...mapGetters({
      cartItems: 'printcart/cart_lines'
    })
  },

  mounted() {
    if (!this.disableLoadData) {
      this.$store.dispatch('printcart/getCart');
    }
  },

  methods: {
    click() {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.PRINT_CHECKOUT,
        data: { screen: PRINT_CHECKOUT_SCREENS.CART }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.PRINT_CHECKOUT);
    }
  }
};
</script>

<style></style>
