import { APP_EVENTS, DRAWERS, POSTMESSAGE_EVENTS } from './constants';

export default {
  mixins: [],

  data() {
    return {};
  },

  methods: {
    bindPostMessageHandlers() {
      var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      var eventer = window[eventMethod];
      var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

      // Listen to message from child window
      eventer(messageEvent, async (e) => {
        if (e.data && e.data.type) {
          console.log('MCS DASHBOARD CAUGHT POSTMESSAGE -> ' + e.data.type, e.data.data);
        }

        if (e.data.type === POSTMESSAGE_EVENTS.IFRAMED_PRINT_DRAWER_OPENED) {
          // Payload Structure
          // {
          //    id: a project id
          //    url: a preview url
          // }
          this.$eventBus.$emit(APP_EVENTS.REFRESH_PRINT_DRAWER);
          this.$store.commit('projects/SET_PROJECT_PREVIEW', e.data.data);
        }

        if (e.data.type === POSTMESSAGE_EVENTS.IFRAMED_PRINT_UPDATE_PREVIEW) {
          // Payload Structure
          // {
          //    id: a project id
          //    url: a preview url
          // }
          this.$store.commit('projects/SET_PROJECT_PREVIEW', e.data.data);
        }

        if (e.data.type === POSTMESSAGE_EVENTS.IFRAMED_PRINT_PROJECT_APPROVED) {
          // Payload Structure
          // {
          //    projectId: a project id
          // }
          this.$eventBus.$emit(APP_EVENTS.REFRESH_SELECTED_PRINT_PROJECT, e.data.data);
          this.$store.commit('drawers/CLOSE_DRAWER', DRAWERS.PRINT_SELECT_PROJECT);
        }

        if (e.data.type === POSTMESSAGE_EVENTS.IFRAMED_SNAILBLAST_REVIEW_COMPLETE) {
          // Payload Structure
          // {
          //    projectId: a project id
          // }
          this.$eventBus.$emit(APP_EVENTS.REFRESH_PROJECT_DETAIL, e.data.data);
          this.$eventBus.$emit(APP_EVENTS.REFRESH_SELECTED_PRINT_PROJECT, e.data.data);
          this.$store.commit('drawers/CLOSE_DRAWER', DRAWERS.EDITOR_DRAWER);
        }

        if (e.data.type === POSTMESSAGE_EVENTS.CLOSE_IFRAMED_EDITOR) {
          this.$store.commit('drawers/CLOSE_DRAWER', DRAWERS.EDITOR_DRAWER);
        }

        if (e.data.type === POSTMESSAGE_EVENTS.CREATE_PROJECT) {
          window.location.href = e.data.data.url;
        }

        if (e.data.type === POSTMESSAGE_EVENTS.OPEN_URL) {
          e.data.data.newWindow
            ? window.open(e.data.data.url, '_blank')
            : (window.location.href = e.data.data.url);
        }

        if (e.data.type === POSTMESSAGE_EVENTS.IFRAMED_CATALOG_LOADED) {
          this.$eventBus.$emit(APP_EVENTS.IFRAMED_CATALOG_LOADED);
        }
      });
    }
  }
};
