<template>
  <mcs-sheet
    v-ripple
    no-padding
    outlined
    class="px-3 py-2 hover-pointer"
    @click="clickButton"
  >
    <slot name="top" />
    <v-row>
      <v-col
        cols="auto"
        class="body-1"
      >
        <div class="font-weight-bold pt-1">
          {{ address.line1 }}
        </div>
        <div v-if="address.line2">
          {{ address.line2 }}
        </div>
        <div class="pb-1">
          {{ address.city }} {{ address.state || state_abbr(address.stateProvinceId) }}
          {{ address.postalCode }}
        </div>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="my-auto"
      >
        <v-icon
          color="black"
          :small="buttonIconSmall"
        >
          {{ buttonIcon }}
        </v-icon>
      </v-col>
    </v-row>
  </mcs-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    address: { type: Object, default: () => {} },
    buttonIcon: { type: String, default: 'mdi-chevron-right' },
    buttonIconSmall: { type: Boolean, default: false }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters({ state_abbr: 'lookups/state_abbr' })
  },
  mounted() {},
  methods: {
    clickButton() {
      this.loading = true;
      this.$emit('click');
    }
  }
};
</script>
