import { ROUTES } from '../constants';
import { getLocal, setLocal } from '../local';
export default {
  methods: {
    setLocal(key, val) {
      setLocal(key, val);
    },
    getLocal(key, val) {
      return getLocal(key, val);
    },
    getCampaignIdeasLink(id) {
      return ROUTES.CAMPAIGN_IDEAS.PATH.replace(':id', id);
    },
    setBrandColor() {
      var primaryColor = this.$store.getters['user/master_account'].primaryColor;
      console.log('primaryColor', primaryColor);
      if (primaryColor && this.$portalMode()) {
        this.$vuetify.theme.themes.light.brand = primaryColor;
      }
    }
  }
};
