<template>
  <mcs-sheet
    outlined
    class="relative overflow-hidden"
  >
    <div
      class="d-flex"
      :class="{ 'ml-4': showBar }"
    >
      <v-icon
        v-if="icon"
        :size="iconSize"
        class="mr-4"
      >
        {{ icon }}
      </v-icon>
      <template v-if="title || subtitle">
        <div class="d-flex flex-column flex-grow-1">
          <div class="d-flex justify-space-between align-center">
            <div class="subtitle-1 font-weight-bold">
              {{ title }}
            </div>
            <slot name="actions" />
          </div>
          <div
            v-if="subtitle"
            class="mt-2 body-2"
          >
            {{ subtitle }}
          </div>
        </div>
      </template>
      <template v-else>
        <slot />
      </template>
    </div>
    <div
      v-if="showBar"
      class="bar"
      :style="{ 'background-color': barColor, 'border-color': barBorderColor }"
    />
  </mcs-sheet>
</template>

<script>
import { COLORS } from '../../utils/constants';

export default {
  name: 'McsActionCard',

  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    showBar: Boolean,
    barColor: { type: String, default: COLORS.SUCCESS_LIGHT.HEX }
  },

  data() {
    return {
      barBorderColor: COLORS.GREY.HEX
    };
  },

  computed: {
    iconSize() {
      if (this.title && this.subtitle) return 40;
      else return 20;
    }
  }
};
</script>

<style scoped>
.bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16px;
  border-right: solid 1.5px;
}
</style>
