<template>
  <div
    class="accent w-full h-full d-flex align-center justify-center hover-pointer black--text"
    @click.stop="click"
  >
    <v-icon size="20"> mdi-alert-outline </v-icon>
    <div class="body-2 d-flex">
      <span class="font-weight-bold">This account is past due - </span>
      <div
        class="ml-1"
        v-html="message"
      />
    </div>
  </div>
</template>

<script>
import { ROUTES } from '../../utils/constants';

export default {
  name: 'SubscriptionPastDueAlert',

  data() {
    return {};
  },

  computed: {
    message() {
      return this.$store.state.config.route.path === ROUTES.SUBSCRIPTION.PATH
        ? 'take action below to enable unlimited access'
        : '<span class="primary--text">click here</span> to enable unlimited access!';
    }
  },

  methods: {
    click() {
      this.$router.push(ROUTES.SUBSCRIPTION.PATH);
    }
  }
};
</script>

<style></style>
