<template>
  <div>
    <v-dialog
      v-model="show"
      :width="width + 'px'"
      content-class="no-shadow"
      @input="close"
    >
      <div
        class="d-flex h-full w-full align-center justify-center"
        @click="close"
      >
        <img
          :src="imageUrl"
          style="max-width: 100%; max-height: 100%"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { APP_EVENTS } from '../../utils/constants';

export default {
  name: 'ZoomImageOverlay',

  data() {
    return {
      show: false,
      width: 400,
      height: 400,
      imageUrl: '#'
    };
  },

  computed: {},

  mounted() {
    this.$eventBus.$on(APP_EVENTS.SHOW_IMAGE_OVERLAY, (data) => {
      // data is: { imageUrl: string }
      if (data.show) this.$store.commit('config/INCREMENT_DIALOGS_OPEN');
      else this.$store.commit('config/DECREMENT_DIALOGS_OPEN');
      this.show = data.show;
      this.imageUrl = data.imageUrl;
      this.width = document.body.clientWidth - 50;
      this.height = document.body.clientHeight - 50;
    });
  },

  methods: {
    close() {
      this.$eventBus.$emit(APP_EVENTS.SHOW_IMAGE_OVERLAY, { show: false });
    }
  }
};
</script>

<style>
.no-shadow {
  box-shadow: none;
}
</style>
