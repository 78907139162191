const state = {
  route: { path: '/' },
  appDataLoaded: false,
  routeKey: 'a',
  mixpanelConfig: {
    token: '679f523c8fefadfb7d4627a4456eabf1',
    proxyDomain: 'https://proxy-s2qfilzjba-uc.a.run.app'
  },
  analytics: { ga4Id: 'G-J3NTCS3ZNQ' },
  smartyKey: '194697856503628174',
  showConsoleErrorsForUsers: ['67402520-0edf-4757-a620-a3f2c29c0199'],
  logoUrlDark: '',
  logoUrlLight: '',
  faviconurl: '',
  snailblastLogo:
    'https://res.cloudinary.com/mycreativeshop/image/upload/v1721396535/public/sb-logo.svg',
  webUrl: '',
  dashboardUrl: '',
  numDialogsOpen: 0,
  pageLoading: false,
  blockUILoading: false,
  runas: localStorage.getItem('runas'),
  stripePublicKey: '',
  helpscoutReady: false,
  disableAlertBar: false,
  inDevEnv: process.env.VUE_APP_IN_DEV === 'true'
};

const getters = {
  enableSubscriptions(state, getters, rootState, rootGetters) {
    return rootGetters['user/isMCS'];
  },

  catalogUrl(state) {
    return state.webUrl + 'templates';
  },

  anyDialogsOpen(state) {
    return state.numDialogsOpen > 0;
  },

  runas(state) {
    return state.runas;
  }
};

const actions = {};

const mutations = {
  SET_APP_DATA_LOADED(state, payload) {
    state.appDataLoaded = payload;
  },

  SET_ROUTE(state, payload) {
    state.route = payload;
  },

  REFRESH_CURRENT_ROUTE(state) {
    state.routeKey = state.routeKey === 'a' ? 'b' : 'a';
  },

  SET_APP_DATA(state, payload) {
    state.logoUrlDark = payload.logoUrlDark;
    state.logoUrlLight = payload.logoUrlLight;
    state.faviconUrl = payload.faviconUrl;
    state.webUrl = payload.webUrl;
    state.dashboardUrl = payload.dashboardUrl.slice(0, -1);
    state.stripePublicKey = payload.stripePublicKey;
  },

  SET_DARK_LOGO_URL(state, url) {
    state.logoUrlDark = url;
  },

  INCREMENT_DIALOGS_OPEN(state) {
    state.numDialogsOpen++;
  },

  DECREMENT_DIALOGS_OPEN(state) {
    state.numDialogsOpen--;
  },

  SET_PAGE_LOADING(state, loading) {
    state.pageLoading = loading;
  },

  SET_BLOCK_UI_LOADING(state, loading) {
    state.blockUILoading = loading;
  },

  SET_RUNNING_AS(state, user) {
    state.runas = user;
    if (!user) {
      localStorage.removeItem('runas');
      document.cookie =
        'runas=;path=/;domain=mycreativeshop.com;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
  },

  SET_HELPSCOUT_READY(state) {
    state.helpscoutReady = true;
  },

  SET_DISABLE_ALERT_BAR(state, val) {
    state.disableAlertBar = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
