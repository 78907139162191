const state = {
  items: []
};

const getters = {};

const actions = {};

const mutations = {
  SET_TEAMS(state, payload) {
    state.items = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
