<template>
  <mcs-sheet
    no-padding
    class="d-flex h-full flex-column autoscroll-y pb-4"
    outlined
  >
    <template v-if="selectedField == null">
      <v-text-field
        v-model="search"
        class="mx-4"
        prepend-icon="mdi-magnify"
        placeholder="Search filter"
        label="Search filter"
        flat
        solo
        hide-details
      >
        <template #append>
          <mcs-btn
            v-if="!campaign_idea"
            text
            color="error"
            @click="clearFilters"
          >
            Clear filters
          </mcs-btn>
        </template>
      </v-text-field>
      <v-divider class="mb-4" />
      <div class="px-4 d-flex h-full autoscroll-y flex-column">
        <v-row
          v-for="(field, i) in fieldsToShow"
          :key="i"
          class="hover-pointer flex-grow-0"
          @click="selectField(field)"
        >
          <v-col cols="auto">
            <FieldLabel :field="field" />
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <mcs-btn
              v-if="!selected(field)"
              icon
              color="black"
              prepend-icon="mdi-chevron-right"
              @click="selectedFieldId = field.id"
            ></mcs-btn>
            <v-chip
              v-else
              class="px-0"
              small
              @click="selectedFieldId = field.id"
            >
              <v-badge
                color="black"
                left
                inline
                :content="filters.filter((x) => x.field.id === field.id).length"
              >
                <v-icon> mdi-chevron-right </v-icon>
              </v-badge>
            </v-chip>
          </v-col>
        </v-row>
      </div>
    </template>
    <TextFilter
      v-else-if="selectedField.typeId == enums.SNAILBLAST.LIST_PURCHASE_FIELD_TYPES.TEXT"
      :field="selectedField"
      @back="selectedFieldId = null"
      @setfilters="setFilters"
    />
    <YearRange
      v-else-if="selectedField.typeId == enums.SNAILBLAST.LIST_PURCHASE_FIELD_TYPES.YEAR_RANGE"
      :field="selectedField"
      @back="selectedFieldId = null"
      @setfilters="setFilters"
    />
    <Dropdown
      v-else-if="selectedField.typeId == enums.SNAILBLAST.LIST_PURCHASE_FIELD_TYPES.DROPDOWN"
      :field="selectedField"
      @back="selectedFieldId = null"
      @setfilters="setFilters"
    />
    <Bool
      v-else-if="selectedField.typeId == enums.SNAILBLAST.LIST_PURCHASE_FIELD_TYPES.BOOL"
      :field="selectedField"
      @back="selectedFieldId = null"
      @setfilters="setFilters"
    />
    <Select
      v-else
      :field="selectedField"
      @back="selectedFieldId = null"
      @setfilters="setFilters"
    />
  </mcs-sheet>
</template>

<script>
import Bool from '@/components/print/checkout/snailblast/listpurchase/filters/Bool';
import Dropdown from '@/components/print/checkout/snailblast/listpurchase/filters/Dropdown';
import FieldLabel from '@/components/print/checkout/snailblast/listpurchase/filters/field/Label';
import Select from '@/components/print/checkout/snailblast/listpurchase/filters/Select';
import TextFilter from '@/components/print/checkout/snailblast/listpurchase/filters/Text';
import YearRange from '@/components/print/checkout/snailblast/listpurchase/filters/YearRange';
import { ANALYTICS_EVENTS, ENUMS } from '@/utils/constants';
import snailblast from '@/utils/mixins/snailblast';
import { orderBy } from 'lodash';
import { mapGetters } from 'vuex';
export default {
  name: 'SnailblastListPurchaseFilters',

  components: { TextFilter, YearRange, Dropdown, Select, Bool, FieldLabel },

  mixins: [snailblast],

  data: () => ({
    search: '',
    loading: false,
    selectedFieldId: null,
    enums: ENUMS
  }),

  computed: {
    fieldsToShow() {
      let list = this.fields(this.database_id);

      if (this.search) {
        list = list.filter((x) => x.name.toLowerCase().includes(this.search.toLowerCase()));
      }

      list = list.map((x) =>
        Object.assign(x, {
          selected: this.selected(x),
          isBool: x.typeId === ENUMS.SNAILBLAST.LIST_PURCHASE_FIELD_TYPES.BOOL
        })
      );

      if (this.campaign_idea) {
        list = list.filter((x) => x.selected);
      }
      return orderBy(list, ['selected', 'isBool', 'sortOrder'], ['desc', 'desc', 'asc']);
    },
    selectedField() {
      return this.fields(this.database_id).find((x) => x.id === this.selectedFieldId);
    },
    selected() {
      return (field) => {
        return !!this.filters.filter((x) => x.field.id === field.id).length;
      };
    },
    selectedIds: {
      get() {
        return this.filters.map((x) => x.field.id);
      },
      set(val) {
        // do nothing
      }
    },
    ...mapGetters({
      fields: 'snailblast/lookup/list_purchase_fields',
      field: 'snailblast/lookup/list_purchase_field',
      list_purchases: 'snailblast/campaign/list_purchases',
      database_id: 'snailblast/campaign/list_purchase_database_id',
      filters: 'snailblast/campaign/list_purchase_filters',
      analytics_data: 'snailblast/campaign/analytics_data',
      campaign_idea: 'printcart/campaign_idea'
    })
  },

  created() {},
  methods: {
    selectField(field) {
      if (!this.filters.length) {
        this.setListPurchaseField('filters', [], true);
      }
      this.selectedFieldId = field ? field.id : null;
    },
    async setFilters(filters, stayOnFilters) {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.FILTER_SET,
        this.analytics_data
      );
      if (!this.list_purchases.length) {
        await this.addListPurchase(await this.getDefaultListPurchase());
      }
      this.setListPurchaseField('filters', filters);
      this.selectedFieldId = null;
      if (!stayOnFilters) {
        this.$emit('back');
      }
    },
    removeFilter(id) {
      this.setListPurchaseField(
        'filters',
        this.filters.filter((x) => x.field.id !== id)
      );
    },
    clearFilters() {
      this.setListPurchaseField('filters', []);
      this.$emit('back');
    }
  }
};
</script>
