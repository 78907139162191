<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    persistent
    :style="{ zIndex: options.zIndex }"
    :content-class="`${options.top ? 'align-self-start' : ''} rounded-xl`"
    @keydown.esc="cancel"
    @keydown.enter.prevent="agree"
  >
    <mcs-card :title="title">
      <v-divider />
      <div
        v-show="!!message"
        class="body-2 pt-5 pb-3"
      >
        <div v-html="message" />
      </div>
      <v-select
        v-if="options.selectList.options.length"
        v-model="selectValue"
        class="my-3"
        :items="options.selectList.options"
        :label="options.selectList.label"
        item-text="name"
        item-value="id"
        filled
        dense
        hide-details
      />
      <v-form
        v-if="options.showTextInputField"
        ref="form"
        v-model="textInputValid"
        lazy-validation
      >
        <v-text-field
          v-model="textInputValue"
          class="mt-3"
          outlined
          :rules="options.textFieldRules"
        />
      </v-form>
      <v-divider />
      <template #actions>
        <mcs-btn
          color="error"
          text
          @click.native="cancel"
        >
          {{ options.cancelText }}
        </mcs-btn>
        <v-spacer />
        <mcs-btn
          color="primary"
          text
          :disabled="!textInputValid"
          @click.native="agree"
        >
          <span v-if="options.selectList.options.length">
            <template v-if="options.okText">{{ options.okText }}</template>
            <template v-else>Select</template>
          </span>
          <span v-else>{{ options.okText }}</span>
        </mcs-btn>
      </template>
    </mcs-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    selectValue: null,
    textInputValue: '',
    textInputValid: true,
    options: {
      color: 'primary',
      width: 400,
      zIndex: 200,
      top: true,
      okText: 'Ok',
      cancelText: 'Cancel',
      selectList: {
        options: [],
        label: ''
      },
      textFieldRules: [],
      showTextInputField: false
    }
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.$store.commit('config/INCREMENT_DIALOGS_OPEN');
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      if (this.options.selectList.options.length) {
        this.selectValue = this.options.selectList.options[0].id;
      }
      if (this.options.showTextInputField) this.textInputValue = this.options.textInputValue;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      if (this.options.selectList.options.length) {
        this.resolve(this.selectValue);
      } else if (this.options.showTextInputField) {
        this.resolve(this.textInputValue);
      } else {
        this.resolve(true);
      }
      this.dialog = false;
      this.$store.commit('config/DECREMENT_DIALOGS_OPEN');
      this.$reset();
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
      this.$store.commit('config/DECREMENT_DIALOGS_OPEN');
      this.$reset();
    },
    $reset() {
      this.options.okText = 'Ok';
      this.options.cancelText = 'Cancel';
      this.options.width = 400;
      this.options.top = true;
      this.options.selectList.options = [];
      this.options.showTextInputField = false;
      this.options.textInputValue = '';
      this.options.textFieldRules = [];
      this.textInputValid = true;
    }
  }
};
</script>
