const state = {
  list: []
};

const getters = {
  list(state, rootState) {
    return state.list;
  }
};

const actions = {
  getAll({ commit }, search) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`customerProjects/get?search=${search}`)
        .then((response) => {
          commit('SET_LIST', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  ignore({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`customerProjects/ignore?id=${id}`)
        .then(() => {
          commit('REMOVE', id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  claim({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`customerProjects/claim?id=${id}`)
        .then((response) => {
          commit('SET_PROJECT', response);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  notify({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`customerProjects/notify?id=${req.id}`, req.email)
        .then(() => {
          commit('REMOVE', req.id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_PROJECT(state, item) {
    const index = state.list.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      this._vm.$set(state.list, index, item);
    } else {
      state.list.push(item);
    }
  },
  REMOVE(state, id) {
    const index = state.list.findIndex((x) => x.id === id);
    if (index >= 0) {
      state.list.splice(index, 1);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
