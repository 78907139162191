<template>
  <mcs-sheet
    class="grey"
    dense
  >
    <v-row dense>
      <v-col
        v-for="(item, i) in items"
        :key="i"
        :cols="12 / items.length"
      >
        <mcs-btn
          :text="value != item.id"
          block
          :color="value == item.id ? 'white' : 'black'"
          @click="$emit('input', item.id)"
        >
          {{ item.title }}
        </mcs-btn>
      </v-col>
    </v-row>
  </mcs-sheet>
</template>

<script>
export default {
  props: { items: { type: Array, default: () => {} }, value: { type: String, default: '' } }
};
</script>
