<template>
  <div>
    <mcs-btn
      v-if="viewOnly"
      large
      block
      :loading="generatingUrl"
      outlined
      color="black"
      @click="viewCert"
    >
      {{ buttonText }}
    </mcs-btn>
    <div
      v-else
      class="d-flex"
    >
      <mcs-btn
        class="flex-grow-1 d-flex border border-black"
        :large="!certificateId"
        :small="!!certificateId"
        :loading="uploading"
        prepend-icon="mdi-cloud-upload"
        color="white"
        @click="handleFileInput"
      >
        {{ buttonText }}
      </mcs-btn>
      <mcs-btn
        v-if="certificateId"
        small
        :loading="generatingUrl"
        class="ml-3 border border-black"
        color="white"
        @click="viewCert"
      >
        View
      </mcs-btn>
      <mcs-btn
        v-if="certificateId"
        small
        :loading="deleting"
        class="ml-3 border border-black"
        color="white"
        @click="deleteCert"
      >
        Delete
      </mcs-btn>
    </div>
    <input
      ref="fileInput"
      class="d-none"
      type="file"
      @change="upload"
    />
  </div>
</template>

<script>
export default {
  name: 'CertificateUploadButton',

  props: {
    buttonText: {
      type: String,
      default: 'Upload Certificate'
    },
    certificateId: {
      type: Number,
      default: 0
    },
    viewOnly: Boolean
  },

  data() {
    return {
      uploading: false,
      generatingUrl: false,
      deleting: false
    };
  },

  computed: {},

  watch: {},

  mounted() {},

  methods: {
    handleFileInput() {
      this.$refs.fileInput.click();
    },

    upload() {
      this.uploading = true;
      var payload = {
        file: this.$refs.fileInput.files[0],
        certificateId: this.certificateId
      };
      this.$store.dispatch('user/addExemptionCertificateFile', payload).finally(() => {
        this.uploading = false;
        this.$root.$snackbarSuccess(
          'Your uploaded certificate has been received and is under review.'
        );
      });
    },

    viewCert() {
      this.generatingUrl = true;
      setTimeout(() => {
        this.$api
          .get(`user/getCertificateUrl?id=${this.certificateId}`)
          .then((response) => {
            const link = document.createElement('a');
            link.href = response.data;
            link.download = 'certificate';
            link.click();
          })
          .finally(() => {
            this.generatingUrl = false;
          });
      }, 1000);
    },

    deleteCert() {
      this.deleting = true;
      this.$store.dispatch('user/deleteExemptionCertificate', this.certificateId).finally(() => {
        this.deleting = false;
        this.$emit('deleted');
      });
    }
  }
};
</script>

<style scoped>
.mcs-btn.v-size--x-large {
  min-width: inherit;
}
</style>
