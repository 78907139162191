<template>
  <div>
    <FieldHeader
      :field="field"
      @back="$emit('back')"
      @save="applyFilters"
    />
    <v-divider />
    <div class="pa-4">
      <v-form
        ref="form"
        v-model="valid"
        @submit="applyFilters"
      >
        <v-row>
          <v-col
            v-if="!loading && field.selectItems.length"
            cols="12"
          >
            <v-select
              v-model="value"
              :items="field.selectItems"
              item-text="name"
              item-value="id"
              dense
              outlined
              hide-details="auto"
              :disabled="!!campaign_idea"
              :rules="[$rules.required]"
              placeholder="Select a filter"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import FieldHeader from '@/components/print/checkout/snailblast/listpurchase/filters/field/Header';
import { mapGetters } from 'vuex';
export default {
  name: 'SnailblastListPurchaseFiltersDropdown',
  components: { FieldHeader },
  props: { field: { type: Object, default: () => {} } },
  data() {
    return {
      value: '',
      valid: false,
      loading: false
    };
  },
  computed: {
    items() {
      if (!this.field) {
        return [];
      }

      return this.field.selectItems || [];
    },
    ...mapGetters({
      lookup_field: 'snailblast/lookup/list_purchase_field',
      filters: 'snailblast/campaign/list_purchase_filters',
      campaign_idea: 'printcart/campaign_idea'
    })
  },
  created() {
    if (!this.items || !this.items.length) {
      this.loading = true;
      this.$store
        .dispatch('snailblast/lookup/getListPurchaseFieldSelectItems', this.field.id)
        .catch((error) => {
          this.logError(error, 'Error getting dropdown items');
        })
        .finally(() => {
          this.loading = false;
        });
    }
    this.value = this.filters.filter((x) => x.field.id === this.field.id).map((x) => x.value)[0];
  },
  methods: {
    applyFilters() {
      if (this.$refs.form.validate()) {
        const newFilters = this.filters.filter((x) => x.field.id !== this.field.id);
        newFilters.push({
          field: this.field,
          value: this.value,
          text: this.items.filter((x) => x.id === this.value).map((x) => x.name)[0]
        });
        this.$emit('setfilters', newFilters);
      }
    }
  }
};
</script>
