const state = {
  templates: [],
  folders: []
};

const getters = {
  all_templates(state) {
    return state.templates || [];
  },
  templates(state, getters) {
    return (id) => {
      return getters.all_templates.filter((x) => x.folderId === id);
    };
  },
  all_folders(state) {
    return state.folders || [];
  },
  folders(state, getters) {
    return (id) => {
      return getters.all_folders
        .filter((x) => x.parentId === id)
        .map((x) =>
          Object.assign(x, {
            folderCount: getters.all_folders.filter((y) => y.parentId === x.id).length,
            templateCount: getters.all_templates.filter((y) => y.folderId === x.id).length
          })
        );
    };
  },
  parents(state) {
    return (id) => {
      const parents = [];
      let current = state.folders.find((x) => x.id === id);
      if (current) {
        do {
          parents.splice(0, 0, current);
          current = state.folders.find((x) => x.id === current.parentId);
        } while (current);
      }
      return parents;
    };
  }
};

const mutations = {
  SET_TEMPLATES(state, item) {
    state.templates = item.templates;
    state.folders = item.folders;
  }
};

const actions = {
  async getTemplates({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('userCollections/getTemplates')
        .then((response) => {
          commit('SET_TEMPLATES', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
