import { BrowserClient, Hub, makeFetchTransport } from '@sentry/browser';

const log = new BrowserClient({
  dsn: process.env.VUE_APP_SENTRY_URL,
  environment: process.env.NODE_ENV,
  transport: makeFetchTransport,
  integrations: []
});

export default {
  methods: {
    logError(error, alertMsg) {
      console.log(error);
      const hub = new Hub(log);

      hub.captureException(error);
      if (alertMsg && this.$root.$alert) {
        this.$root.$alert('Error Occurred', alertMsg);
      }
    }
  }
};
