import { cloneDeep, orderBy, uniqBy } from 'lodash';
const state = () => ({
  cart: {},
  edit_item: {},
  products: [],
  core_products: [],
  material_quantities: [],
  mailing_options: [],
  price: {},
  delivery: [],
  initialLoadComplete: false,
  requireProjectApproval: false,
  loading_price: false
});

const getters = {
  cart: (state) => {
    return state.cart || {};
  },
  cart_lines: (state, getters) => {
    return getters.cart.lines || [];
  },
  edit_item: (state) => {
    return state.edit_item || {};
  },
  campaign: (state, getters) => {
    return getters.edit_item.campaign;
  },
  project: (state, getters) => {
    return getters.edit_item.project || {};
  },
  campaign_idea: (state, getters) => {
    return getters.project.snailblastCampaignIdea;
  },
  page_options: (state, getters) => (pageNumber) => {
    var options = getters.edit_item.pageOptions || [];
    if (options.length && pageNumber === 1) {
      options = options.filter((x) => x.pageId !== 'blank');
    }
    return options;
  },
  page_selections: (state, getters) => {
    return getters.edit_item.pageSelections || [];
  },
  guide: (state, getters) => {
    return getters.project.guide || '';
  },
  editor_url: (state, getters) => {
    return getters.project.editorUrl || '';
  },
  products: (state) => {
    return state.products || {};
  },
  core_products(state) {
    return (categoryId) => {
      return state.core_products.filter((x) => x.categoryId === categoryId);
    };
  },
  material_quantities: (state) => {
    return state.material_quantities || [];
  },
  material_quantity: (state, getters) => {
    return getters.material_quantities.find(
      (x) =>
        x.value === getters.edit_item.quantity && x.material.id === getters.edit_item.materialId
    );
  },
  option_groups: (state, getters) => {
    const options = getters.material_quantity?.productOptions || [];
    if (!options.length) {
      return [];
    }
    const optionGroups = cloneDeep(options)
      .map((x) => x.option.group)
      .map((x) =>
        Object.assign(x, {
          options: orderBy(
            options.filter((y) => y.option.group.id === x.id),
            ['default', 'sortOrder', 'display'],
            ['desc', 'asc', 'asc']
          )
        })
      )
      .map((x) =>
        Object.assign(x, {
          selectedValue: x.options.map((x) => x.productOptionId)[0],
          show: !!x.options.filter((y) => y.show).length
        })
      );
    return orderBy(uniqBy(optionGroups, 'id'), ['sortOrder', 'id'], ['asc', 'asc']);
  },
  mailing_options: (state) => {
    return state.mailing_options || [];
  },
  price: (state, getters) => {
    if (!getters.campaign) {
      return state.price?.basePrice;
    }
    return state.price?.price || 0;
  },
  priceOriginal: (state) => {
    return state.price?.priceOriginal || 0;
  },
  delivery: (state) => {
    return state.delivery || {};
  },
  mailable: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['user/isMCS']) return false;
    return (
      getters.project.category &&
      (getters.project.category?.toLowerCase() === 'postcard' ||
        getters.project.category?.toLowerCase() === 'flyer')
    );
  },
  isProjectInCart: (state, getters) => (id) => {
    return getters.cart_lines.find((x) => x.project.id === id) !== undefined;
  },
  loading_price: (state) => {
    return state.loading_price;
  }
};

const mutations = {
  SET_CART(state, cart) {
    state.cart = cart;
  },
  SET_EDIT_ITEM(state, item) {
    // if (!item.pageOptions) item.pageOptions = []; // need this here for pageOptions to be observable
    // if (!item.pageSelections) item.pageSelections = [];
    state.edit_item = item;
  },
  SET_EDIT_ITEM_PROJECT(state, project) {
    state.edit_item.project = project;
  },
  SET_EDIT_ITEM_CAMPAIGN(state, campaign) {
    this._vm.$set(state.edit_item, 'campaign', campaign);
  },
  SET_EDIT_ITEM_PAGE_OPTIONS(state, pageOptions) {
    state.edit_item.pageOptions = pageOptions;
  },
  SET_EDIT_ITEM_PAGE_SELECTIONS(state, pageSelections) {
    console.log('setting it here ', pageSelections);
    state.edit_item.pageSelections = pageSelections;
  },
  SET_EDIT_ITEM_GUIDE(state, guide) {
    this._vm.$set(state.edit_item.project, 'guide', guide);
  },
  SET_EDIT_ITEM_EDITOR_URL(state, item) {
    this._vm.$set(state.edit_item.project, 'editorUrl', item);
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_CORE_PRODUCTS(state, list) {
    state.core_products = list;
  },
  SET_MATERIAL_QUANTITIES(state, list) {
    state.material_quantities = list;
  },
  SET_MAILING_OPTIONS(state, list) {
    state.mailing_options = list;
  },
  SET_PRICE(state, price) {
    state.price = price;
  },
  SET_DELIVERY(state, delivery) {
    state.delivery = delivery;
  },
  SET_INITIAL_LOAD_COMPLETE(state) {
    state.initialLoadComplete = true;
  },
  SET_LOADING_PRICE(state, val) {
    state.loading_price = val;
  }
};

const actions = {
  async getCart({ commit }, payload) {
    if (!payload) payload = { validate: false, calculateTax: false };
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(
          `printcart/getPrintCart?validate=${payload.validate}&calculateTax=${payload.calculateTax}&addressId=${payload.addressId}`
        )
        .then((response) => {
          commit('SET_CART', response.data);
          commit('SET_INITIAL_LOAD_COMPLETE');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async trackView({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`printcart/trackView?projectId=${projectId || 0}`)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async saveOrderItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('printcart/savePrintCartLine', item)
        .then((response) => {
          commit('SET_CART', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setEditItem({ commit }, item) {
    commit(
      'SET_EDIT_ITEM',
      Object.assign(
        {
          delivery: { earliestDate: null },
          quantity: 0,
          materialId: null,
          optionIds: [],
          addressId: undefined,
          project: {},
          categoryId: null,
          coreProductId: null,
          pageOptions: [],
          pageSelections: []
        },
        item
      )
    );
    commit('snailblast/campaign/SET_EDIT_CAMPAIGN', Object.assign({}, item?.campaign), {
      root: true
    });
  },
  setCampaign({ commit }, item) {
    commit('SET_EDIT_ITEM_CAMPAIGN', item ? Object.assign({}, item) : null);
    commit('snailblast/campaign/SET_EDIT_CAMPAIGN', Object.assign({}, item), { root: true });
  },
  async deleteItem({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`printcart/deletePrintCartLine?id=${id}`)
        .then((response) => {
          commit('SET_CART', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async placeOrder({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('printcart/launchPrintOrder', req)
        .then((response) => {
          var result = response.data;
          if (result.success) {
            commit('SET_CART', {});
          }
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async refreshCart({ commit }, cart) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('printcart/refreshPrintCart', cart)
        .then((response) => {
          commit('SET_CART', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async refreshEditItemProject({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`project/detail?id=${id}`)
        .then((response) => {
          commit('SET_EDIT_ITEM_PROJECT', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getCoreProducts({ commit }, preview) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`printcart/getCoreProducts?preview=${preview}`)
        .then((response) => {
          commit('SET_CORE_PRODUCTS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getMaterialQuantities({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('printcart/getMaterialQuantities', { params: req })
        .then((response) => {
          commit('SET_MATERIAL_QUANTITIES', response.data.materialQuantities);
          commit('SET_MAILING_OPTIONS', response.data.mailingOptions);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getPrice({ commit }, req) {
    return new Promise((resolve, reject) => {
      if (!req.item || (!req.item.quantity && !req.item.campaign)) {
        commit('SET_PRICE', 0);
        resolve();
        return;
      }

      this._vm.$api
        .post(`printcart/getPrice?preview=${req.preview}`, req.item)
        .then((response) => {
          commit('SET_PRICE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getPrintablePages({ commit, getters }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('printcart/getPrintablePages', { params: req })
        .then((response) => {
          commit('SET_EDIT_ITEM_PAGE_OPTIONS', response.data.pageOptions);
          commit('SET_EDIT_ITEM_PAGE_SELECTIONS', response.data.pageSelections);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getGuide({ commit, getters }, req) {
    return new Promise((resolve, reject) => {
      if (!req.projectId) {
        resolve();
        return;
      }
      this._vm.$api
        .get('printcart/getGuide', { params: req })
        .then((response) => {
          commit('SET_EDIT_ITEM_GUIDE', response.data.guide);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getEditorUrl({ commit, getters }, req) {
    return new Promise((resolve, reject) => {
      if (!req.projectId) {
        resolve();
        return;
      }
      this._vm.$api
        .get('printcart/getEditorUrl', { params: req })
        .then((response) => {
          commit('SET_EDIT_ITEM_EDITOR_URL', response.data.editorUrl);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async sendEditorMessage({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('printcart/sendEditorMessage')
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getDelivery({ commit }, req) {
    return new Promise((resolve, reject) => {
      if (!req.item) {
        commit('SET_DELIVERY', []);
        resolve();
      }

      this._vm.$api
        .post(`printcart/getDeliveryOptions?preview=${req.preview}`, req.item)
        .then((response) => {
          commit('SET_DELIVERY', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clearDeliveryOptions({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_DELIVERY', []);
      resolve();
    });
  },
  setLoadingPrice({ commit }, val) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_PRICE', val);
      resolve();
    });
  },
  async reorder({ commit }, jobId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`printcart/reorder?jobId=${jobId}`)
        .then((response) => {
          if (response.data.success) {
            commit('SET_CART', response.data.cart);
          }
          resolve(response.data);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
