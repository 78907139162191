<template>
  <mcs-card
    :transparent="transparent"
    :title="title"
    :subtitle="subtitle"
    titleSizeClass="subtitle-1"
    @click="$emit('click', $event)"
  >
    <template #header>
      <slot name="header"></slot>
      <v-sheet
        v-if="!thumbnail && url"
        :class="{ greyLight: !transparent, 'pa-2': true, 'mb-4': true }"
      >
        <v-img
          :width="imgWidth"
          :height="imgHeight"
          :max-width="imgMaxWidth"
          :max-height="imgMaxHeight"
          :contain="imgContain"
          :src="url"
        />
      </v-sheet>
    </template>
    <template #prepend>
      <v-sheet
        v-if="thumbnail && url"
        :class="{ greyLight: !transparent, 'pa-2': true }"
      >
        <v-img
          :width="imgWidth"
          :height="imgHeight"
          :max-width="imgMaxWidth"
          :max-height="imgMaxHeight"
          :contain="imgContain"
          :src="url"
        />
      </v-sheet>
    </template>

    <slot></slot>
    <template #actions>
      <slot name="actions"></slot>
    </template>
  </mcs-card>
</template>

<script>
export default {
  name: 'McsImageCard',

  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    url: { type: String, default: '' },
    imgWidth: { type: String, default: '' },
    imgHeight: { type: String, default: '' },
    imgMaxWidth: { type: String, default: '' },
    imgMaxHeight: { type: String, default: '' },
    imgContain: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    thumbnail: { type: Boolean, default: false }
  },

  data() {
    return {};
  },

  computed: {}
};
</script>
