<template>
  <div class="text-center d-flex flex-column my-auto">
    <LottieAnimation
      :path="path"
      :width="width"
      :height="height"
    />
    <div class="font-weight-bold mt-4">
      {{ title }}
    </div>
    <div class="px-8">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
export default {
  name: 'SnailblastLoading',
  components: { LottieAnimation },
  props: {
    path: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    width: { type: Number, default: 150 },
    height: { type: Number, default: 150 }
  }
};
</script>
