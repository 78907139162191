<template>
  <Drawer
    :id="drawerId"
    :title="title"
    preloadContent
    height="75%"
    bottom
    closeGoesBack
    hideBack
  >
    <div class="d-flex-hide flex-column justify-space-between h-full">
      <div class="pa-4 h-full">
        <ManageAddresses
          v-if="opened"
          :drawerId="drawerId"
          :backText="backText"
          @close="close"
        />
      </div>
    </div>
  </Drawer>
</template>

<script>
import Drawer from '@/components/layout/Drawer';
import ManageAddresses from '@/components/print/checkout/addresses/Manage';
import { DRAWERS } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'PrintCheckoutShippingDrawer',

  components: {
    Drawer,
    ManageAddresses
  },

  props: {},

  data() {
    return {
      saving: false,
      valid: true,
      loadingDelivery: false,
      validating: false,
      drawerId: DRAWERS.MANAGE_ADDRESSES,
      backText: ''
    };
  },

  computed: {
    title() {
      return 'Address Book';
    },

    opened() {
      return this.drawerId === this.$store.getters['drawers/topDrawer']?.id;
    },

    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt'
    })
  },

  watch: {
    opened(val, oldVal) {
      if (val) {
        const initial = this.initialData(this.drawerId) || {};
        this.backText = initial.backText || '< Go Back';
      }
    }
  },

  mounted() {},

  methods: {
    close() {
      this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
    }
  }
};
</script>

<style scoped></style>
