<template>
  <v-app-bar
    v-if="show"
    elevation="0"
    color="primaryLight"
  >
    <v-container>
      <div class="d-flex">
        <v-menu absolute>
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="font-weight-bold my-auto pl-8"
              v-on="on"
            >
              <v-icon
                color="primary"
                small
              >
                mdi-folder
              </v-icon>
              <span class="ml-2">
                {{ collection_name(collectionId) }}
              </span>
              <v-icon color="black"> mdi-menu-down </v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="collection in collections"
              :key="collection.id"
              @click="setCollectionId(collection.id)"
            >
              <v-list-item-title>{{ collection.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import collections from '@/utils/mixins/collections';
import { mapGetters } from 'vuex';
export default {
  mixins: [collections],
  props: { type: { type: String, default: '' } },
  data: () => ({
    saving: false
  }),
  computed: {
    show() {
      return this.$route.meta.showToggle;
    },
    ...mapGetters({
      collections: 'admin/collections/list',
      collection_name: 'admin/collections/collection_name'
    })
  },
  methods: {
    setCollectionId(collectionId) {
      this.$store.dispatch('admin/collections/setId', collectionId);
    }
  }
};
</script>
