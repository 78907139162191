<template>
  <v-avatar
    :size="size"
    class="hover-pointer"
    style="border: solid 1px"
    :style="{ border: borderStyle }"
    @click="clicked"
  >
    <template v-if="(everUploaded || link) && !loading">
      <img :src="imageUrl" />
    </template>
    <template v-else>
      <mcs-btn
        icon
        large
        prependIcon="mdi-camera"
        :loading="loading"
        color="black"
        :iconSize="size / 2.25"
      >
      </mcs-btn>
    </template>
    <input
      ref="fileInput"
      type="file"
      class="d-none"
      accept="image/*"
      @change="upload"
    />
  </v-avatar>
</template>

<script>
export default {
  name: 'Avatar',

  props: {
    size: {
      type: Number,
      default: 36
    },
    borderWidth: {
      type: Number,
      default: 0
    },
    link: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    imageUrl() {
      return this.$store.state.user.profile.avatar || '';
    },

    everUploaded() {
      return this.$store.getters['user/avatarUploaded'];
    },

    borderStyle() {
      var color = this.everUploaded ? this.$vuetify.theme.themes.light.primary : '#000';
      return (
        (this.link || this.everUploaded ? 'solid ' : 'dashed') +
        ' ' +
        this.borderWidth +
        'px ' +
        color
      );
    }
  },

  methods: {
    clicked() {
      if (this.link) this.$router.push(this.link);
      else this.$refs.fileInput.click();
    },

    async upload() {
      this.loading = true;
      await this.$store
        .dispatch('user/uploadProfileImage', this.$refs.fileInput.files[0])
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
