<template>
  <Drawer
    :id="drawerId"
    title="Nonprofit Info"
    subtitle="Manage your nonprofit details"
    padded
    :forceStateless="forceStateless"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="edit_company_info.taxIdNumber"
            v-mask="'##-#######'"
            outlined
            label="Tax Id #"
            placeholder="Tax Id #"
            persistent-placeholder
            :rules="[$rules.required]"
            maxlength="10"
            hide-details="auto"
            class="white"
            @keypress="isNumber($event)"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="edit_company_info.address.company"
            outlined
            label="Organization Name"
            placeholder="Organization Name"
            persistent-placeholder
            :rules="[$rules.required]"
            maxlength="100"
            hide-details="auto"
            class="white"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="edit_company_info.address.line1"
            outlined
            label="Street Address"
            placeholder="Street Address"
            persistent-placeholder
            :rules="[$rules.required]"
            maxlength="100"
            hide-details="auto"
            class="white"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="edit_company_info.address.line2"
            outlined
            label="Suite, Room, Apt#"
            placeholder="Suite, Room, Apt#"
            persistent-placeholder
            maxlength="100"
            hide-details="auto"
            class="white"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="edit_company_info.address.city"
            outlined
            label="City"
            placeholder="City"
            persistent-placeholder
            :rules="[$rules.required]"
            maxlength="100"
            hide-details="auto"
            class="white"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="edit_company_info.address.countryId"
            outlined
            label="Country"
            placeholder="Country"
            persistent-placeholder
            :items="countries"
            item-text="name"
            item-value="id"
            hide-details="auto"
            :rules="[$rules.required]"
            class="white"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="edit_company_info.address.stateProvinceId"
            outlined
            label="State"
            placeholder="State"
            persistent-placeholder
            :rules="[$rules.required]"
            :items="states(edit_company_info.address.countryId)"
            :disabled="states(edit_company_info.address.countryId).length == 0"
            hide-details="auto"
            item-text="name"
            item-value="id"
            class="white"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="edit_company_info.address.postalCode"
            outlined
            label="Zip / Postal Code"
            placeholder="Zip / Postal Code"
            persistent-placeholder
            :rules="[$rules.required]"
            maxlength="7"
            hide-details="auto"
            class="white"
          />
        </v-col>
      </v-row>
    </v-form>
    <mcs-sheet
      outlined
      class="bg-non-profit overflow-hidden align-center mt-5 px-4 py-3 relative"
    >
      <img
        src="https://res.cloudinary.com/mycreativeshop/image/upload/v1717085155/nonprofit-left-corner"
        style="width: 70px; position: absolute; right: 0; bottom: 0"
      />
      <div class="d-flex justify-space-between align-center mb-3">
        <div class="font-weight-bold d-flex align-center">
          <v-icon class="mr-2"> mdi-file-document </v-icon>
          Tax Exempt Certificates
        </div>
        <mcs-btn
          text
          color="primary"
          @click="openCertificates"
        >
          Manage >
        </mcs-btn>
      </div>
      <mcs-btn
        color="white"
        class="border border-black"
        block
        prepend-icon="mdi-plus-circle"
        @click="openCertificates"
      >
        Add Certificate
      </mcs-btn>
    </mcs-sheet>
    <Portal :to="`drawerAppend-${drawerId}`">
      <v-divider />
      <v-row class="pa-2 ma-0">
        <v-col cols="12">
          <mcs-btn
            :loading="saving"
            block
            color="black"
            large
            @click="save"
          >
            Save
          </mcs-btn>
        </v-col>
      </v-row>
    </Portal>
  </Drawer>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import { DRAWERS } from '../../../utils/constants';
import copyObject from '../../../utils/copyObject';
import Drawer from '../../layout/Drawer';

export default {
  name: 'NonProfitDrawer',

  components: {
    Drawer
  },

  data() {
    return {
      drawerId: DRAWERS.NON_PROFIT_DRAWER,
      valid: false,
      edit_company_info: { address: {} },
      saving: false,
      forceStateless: false
    };
  },

  computed: {
    ...mapGetters({
      addresses: 'user/addresses',
      drawerOpenedAt: 'drawers/lastOpenedAt',
      company_info: 'user/company_info',
      countries: 'lookups/countries',
      states: 'lookups/states'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.loading) return;
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        this.edit_company_info = cloneDeep(this.company_info);
      }
    },

    activeScreen() {
      var contentEl = document
        .getElementById(this.drawerId)
        .closest('.v-navigation-drawer')
        .querySelector('.v-navigation-drawer__content');
      if (contentEl) contentEl.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        // using this to handle the issue with v-mask and it not returning a raw value
        // we could extend the library to accomplish this if we wanted to in the future:
        // https://github.com/probil/v-mask/issues/42
        var payload = copyObject(this.edit_company_info);
        payload.taxIdNumber = payload.taxIdNumber.replace('-', '');
        this.$store
          .dispatch('user/updateCompanyInfo', payload)
          .then(async () => {
            this.$root.$snackbarSuccess(
              "Your organization's information has been saved successfully"
            );
            this.forceStateless = true;
            setTimeout(() => {
              this.forceStateless = false;
            }, 10000);
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },

    openCertificates() {
      if (!this.company_info.taxIdNumber) {
        this.$root.$alert(
          'Missing Federal Tax ID',
          "Please enter your organization's federal tax ID and address before adding any sales tax exemption certificates.",
          { hideSupport: true, cancelText: '' }
        );
      } else {
        this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.EXEMPTION_CERTIFICATES_DRAWER);
      }
    },

    isNumber(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    }
  }
};
</script>

<style></style>
