<template>
  <mcs-sheet
    outlined
    dense
    class="d-flex"
  >
    <v-icon
      small
      class="px-2"
    >
      mdi-file-document
    </v-icon>
    <div class="my-auto">
      {{ campaign.addressFileName }}
    </div>
    <mcs-btn
      class="ml-auto my-auto mt-n1"
      small
      text
      color="primary"
      @click="$emit('reset')"
    >
      Change list >
    </mcs-btn>
  </mcs-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'SnailblastUploadChange',

  data: () => ({}),

  computed: { ...mapGetters({ campaign: 'snailblast/campaign/edit_campaign' }) },

  methods: {}
};
</script>
