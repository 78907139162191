<template>
  <div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="d-flex align-center body-2"
      :class="[classes]"
    >
      <v-icon
        size="18"
        class="thick align-self-start"
        :color="item.iconColor || iconColor"
      >
        {{ item.icon || icon }}
      </v-icon>
      <span
        :class="textIndentClass"
        v-html="item.text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconList',

  props: {
    items: {
      type: Array,
      default: () => []
    },
    iconColor: {
      type: String,
      default: 'black'
    },
    icon: {
      type: String,
      default: 'mdi-check'
    },
    classes: {
      type: String,
      default: 'mb-2'
    },
    textIndentClass: {
      type: String,
      default: 'ml-3'
    }
  },

  data() {
    return {};
  },

  computed: {},

  methods: {}
};
</script>

<style></style>
