<template>
  <div class="d-flex justify-space-between">
    <div class="my-auto pl-2">
      <Label :field="field" />
    </div>
    <div class="pa-2">
      <mcs-btn
        color="black"
        class="mr-2"
        @click="$emit('back')"
      >
        Back
      </mcs-btn>
      <mcs-btn
        v-if="!campaign_idea"
        color="success"
        @click="$emit('save')"
      >
        Save
      </mcs-btn>
    </div>
  </div>
</template>

<script>
import Label from '@/components/print/checkout/snailblast/listpurchase/filters/field/Label';
import { mapGetters } from 'vuex';
export default {
  name: 'SnailblastListPurchaseFiltersFieldHeader',
  components: { Label },
  props: { field: { type: Object, default: () => {} } },
  computed: { ...mapGetters({ campaign_idea: 'printcart/campaign_idea' }) }
};
</script>
