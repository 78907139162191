import { BrowserClient, Hub, makeFetchTransport } from '@sentry/browser';
import axios from 'axios';
import store from '../store/index';

const httpClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  withCredentials: true
});

const log = new BrowserClient({
  dsn: process.env.VUE_APP_SENTRY_URL,
  environment: process.env.NODE_ENV,
  transport: makeFetchTransport,
  integrations: []
});

httpClient.interceptors.request.use(
  (config) => {
    if (!config.url.startsWith('http')) {
      const runAs = store.state.config.runas;
      if (runAs) {
        config.headers['X-Run-As-Email'] = runAs;
      }
    }
    // JF:  10/16/24: Commenting since we are moving all MBE to Remix
    // if (store.state.user.masterAccount.accountId === 'mbe') {
    //   var redirectTo = `${process.env.VUE_APP_WEB_URL}dashboard`;
    //   window.location.href = redirectTo;
    // }
    return Promise.resolve(config);
  },
  (err) => {
    return Promise.reject(err);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.config) {
      var fullMessage = `${error.config.url}: ${error.message} - ${error.response?.data?.exceptionMessage}`;
      console.log(fullMessage);
      if (error.response?.data?.exceptionMessage) {
        error.message = error.response.data.exceptionMessage;
      } else {
        error.message = fullMessage;
      }
    }

    const hub = new Hub(log);
    hub.addBreadcrumb({
      category: 'user',
      message: JSON.stringify({
        email: store.state.user.username
      }),
      level: 'info'
    });

    const config = error.config || {};
    hub.addBreadcrumb({
      category: 'xhr',
      message: `${config.method} ${config.url}: ${(error.response || {}).data}`,
      level: 'info'
    });

    hub.captureException(error);
    return Promise.reject(error);
  }
);

export default httpClient;
