import { COLORS } from './constants';

export default (backgroundColorNameOrHex) => {
  var hexPassed = false;
  if (backgroundColorNameOrHex.indexOf('#') > 0) hexPassed = true;
  var blackValue = hexPassed ? COLORS.BLACK.HEX : COLORS.BLACK.NAME;
  var primaryValue = hexPassed ? COLORS.PRIMARY.HEX : COLORS.PRIMARY.NAME;
  var errorValue = hexPassed ? COLORS.PRIMARY.HEX : COLORS.ERROR.NAME;
  var successValue = hexPassed ? COLORS.SUCCESS.HEX : COLORS.SUCCESS.NAME;
  if (
    backgroundColorNameOrHex === blackValue ||
    backgroundColorNameOrHex === primaryValue ||
    backgroundColorNameOrHex === errorValue ||
    backgroundColorNameOrHex === successValue ||
    backgroundColorNameOrHex === 'brand'
  ) {
    return COLORS.WHITE;
  }
  return COLORS.BLACK;
};
