import { cloneDeep, uniqBy, sumBy } from 'lodash';
const state = () => ({
  routes: [],
  selected: [],
  zip: '',
  map_center: null,
  map_radius: 5,
  hover_feature: null
});

const getters = {
  routes: (state) => {
    return state.routes || [];
  },
  selected: (state) => {
    return state.selected || [];
  },
  zip: (state) => {
    return state.zip || '';
  },
  map_center: (state) => {
    return state.map_center;
  },
  map_radius: (state) => {
    return state.map_radius;
  },
  address_count: (state) => {
    return (excludeBusinesses) => {
      let count = sumBy(state.selected, 'residentialCount');
      if (!excludeBusinesses) {
        count += sumBy(state.selected, 'businessCount');
      }
      return count;
    };
  },
  hover_feature: (state) => {
    return state.hover_feature;
  }
};

const mutations = {
  SET_ROUTES(state, routes) {
    state.routes = cloneDeep(routes);
  },
  SELECT_ROUTES_MAP(state, list) {
    list = uniqBy(list, 'zipRouteId');
    for (let i = 0; i < list.length; i++) {
      const index = state.selected.findIndex((x) => x.zipRouteId === list[i].zipRouteId);
      if (index >= 0) {
        state.selected.splice(index, 1);
      } else {
        state.selected.push(list[i]);
      }
    }
  },
  SELECT_ROUTES_TABLE(state, list) {
    state.selected = list;
  },
  SET_ZIP(state, zip) {
    state.zip = zip;
  },
  SET_MAP_CENTER(state, center) {
    state.map_center = center;
  },
  SET_MAP_RADIUS(state, radius) {
    state.map_radius = radius;
  },
  SET_HOVER(state, item) {
    state.hover_feature = item;
  }
};

const actions = {
  async getRoutes({ commit }, zip) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`snailblast/getRoutesByZip?zip=${zip}`)
        .then((response) => {
          commit('SET_ROUTES', response.data);
          resolve(response.data.length);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getRadiusRoutes({ commit }, radius) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(
          `snailblast/getRoutesByRadius?lat=${radius.lat}&lon=${radius.lon}&radius=${radius.radius}`
        )
        .then((response) => {
          commit('SET_ROUTES', response.data);
          resolve(response.data.length);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setRoutes({ commit }, routes) {
    commit('SET_ROUTES', routes);
  },
  selectRoutesFromMap({ commit }, routes) {
    commit('SELECT_ROUTES_MAP', routes);
  },
  selectRoutesFromTable({ commit }, routes) {
    commit('SELECT_ROUTES_TABLE', routes);
  },
  setZip({ commit }, zip) {
    commit('SET_ZIP', zip);
  },
  clear({ commit }) {
    commit('SELECT_ROUTES_TABLE', []);
    commit('SET_ROUTES', []);
  },
  setMapCenter({ commit }, center) {
    commit('SET_MAP_CENTER', center);
  },
  setMapRadius({ commit }, radius) {
    commit('SET_MAP_RADIUS', radius);
  },
  setHover({ commit }, item) {
    commit('SET_HOVER', item);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
