<template>
  <div class="h-full d-flex flex-column autoscroll-y">
    <div class="grey px-2 py-4">
      <v-row dense>
        <v-col
          cols="auto"
          class="my-auto font-weight-bold"
        >
          Address Preview
        </v-col>
        <v-spacer />
        <v-col
          cols="auto"
          class="py-0 my-auto"
        >
          <span
            class="text-decoration-underline hover-pointer"
            @click="$emit('guide')"
          >
            What's required?
          </span>
        </v-col>
        <v-col cols="12"> Let's preview how your addresses will look. </v-col>
      </v-row>
    </div>
    <v-divider />
    <div class="h-full d-flex flex-column pa-4">
      <mcs-alert
        :prepend-icon="ready ? 'mdi-check-circle' : 'mdi-close-circle'"
        :type="ready ? 'success' : 'alert'"
        :text="
          ready
            ? `You've mapped the required fields`
            : `You have not yet mapped the required fields`
        "
      >
      </mcs-alert>
      <div
        v-if="!fields_set.length"
        class="mt-4 pl-2"
      >
        No preview available
      </div>
      <template v-else>
        <div class="mt-6 d-flex justify-space-between">
          <div
            v-if="currentItem"
            class="my-auto border-grey border-thick border-left pl-2 py-4"
          >
            <div v-if="currentItem.firstName || currentItem.lastName">
              {{ currentItem.firstName | upper }} {{ currentItem.lastName | upper }}
            </div>
            <div v-if="currentItem.companyName">
              {{ currentItem.companyName | upper }}
            </div>
            <div>
              {{ currentItem.line1 | upper }}
            </div>
            <div v-if="currentItem.line2">
              {{ currentItem.line2 | upper }}
            </div>
            <div>
              {{ currentItem.city | upper }}, {{ currentItem.state | upper }}
              {{ currentItem.zip | upper }}
            </div>
          </div>
          <div>
            <mcs-btn
              icon
              color="black"
              prepend-icon="mdi-chevron-left"
              @click="next"
            >
            </mcs-btn>
            <mcs-btn
              icon
              color="black"
              prepend-icon="mdi-chevron-right"
              @click="prev"
            >
            </mcs-btn>
          </div>
        </div>
        <div class="mt-6">
          <div class="mb-2">You've connected & we're displaying these fields</div>
          <div>
            <mcs-chip
              v-for="item in fields_set"
              :key="item"
              color="grey"
              class="mb-2 mr-2"
            >
              {{ item }}
            </mcs-chip>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'SnailblastUploadMappingPreview',

  components: {},

  data: () => ({
    itemIndex: 0
  }),

  computed: {
    currentItem() {
      if (this.items.length) {
        return this.items[this.itemIndex];
      }
      return null;
    },
    items() {
      return this.mapping.preview.map((x) => ({
        firstName: x[this.mapping.firstName],
        lastName: x[this.mapping.lastName],
        companyName:
          this.mapping.companyName >= 0
            ? x[this.mapping.companyName]
            : this.mapping.overrideCompanyName,
        line1: x[this.mapping.line1],
        line2: (x[this.mapping.line2] || '').trim(),
        city: x[this.mapping.city],
        state: x[this.mapping.state],
        zip: x[this.mapping.zip]
      }));
    },
    fields_set() {
      const list = [];
      if (this.mapping.firstName >= 0) {
        list.push('First Name');
      }
      if (this.mapping.lastName >= 0) {
        list.push('Last Name');
      }
      if (this.mapping.companyName >= 0) {
        list.push('Company');
      }
      if (this.mapping.line1 >= 0) {
        list.push('Address 1');
      }
      if (this.mapping.line2 >= 0) {
        list.push('Address 2');
      }
      if (this.mapping.city >= 0) {
        list.push('City');
      }
      if (this.mapping.state >= 0) {
        list.push('State');
      }
      if (this.mapping.zip >= 0) {
        list.push('Zip');
      }
      return list;
    },
    saving: {
      get() {
        return this.$store.getters['snailblast/campaign/saving'];
      },
      set(val) {
        this.$store.dispatch('snailblast/campaign/setSaving', val);
      }
    },
    ...mapGetters({
      campaign: 'snailblast/campaign/edit_campaign',
      mapping: 'snailblast/campaign/addresses_mapping',
      ready: 'snailblast/campaign/addresses_mapping_ready'
    })
  },

  methods: {
    next() {
      this.itemIndex += 1;
      if (this.itemIndex === this.items.length) {
        this.itemIndex = 0;
      }
    },
    prev() {
      this.itemIndex -= 1;
      if (this.itemIndex < 0) {
        this.itemIndex = this.items.length - 1;
      }
    }
  }
};
</script>
