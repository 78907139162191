<template>
  <v-app-bar
    v-if="selected.length"
    app
    clipped-left
    clipped-right
    style="z-index: 100"
    dark
  >
    <v-row>
      <v-col cols="auto">
        <mcs-btn
          icon
          color="white"
          prepend-icon="mdi-close"
          @click="clear"
        ></mcs-btn>
      </v-col>
      <v-col class="my-auto"> {{ selected.length }} Selected </v-col>
      <v-spacer />
      <v-col cols="auto">
        <mcs-btn
          color="white"
          icon
          prepend-icon="mdi-folder"
          @click="move"
        >
        </mcs-btn>
      </v-col>
      <v-col
        v-if="showDelete"
        cols="auto"
      >
        <mcs-btn
          icon
          color="white"
          prepend-icon="mdi-delete"
          @click="deleteItems"
        >
        </mcs-btn>
      </v-col>
    </v-row>
    <MoveDialog
      ref="moveDialog"
      :folders-getter="item.foldersGetter"
      :root-folder-name="item.rootFolderName"
      :save-action="item.moveSaveAction"
      @save="clear"
    />
  </v-app-bar>
</template>

<script>
import MoveDialog from '@/components/collections/admin/folders/MoveDialog';
import collections from '@/utils/mixins/collections';
import { mapGetters } from 'vuex';
export default {
  components: { MoveDialog },
  mixins: [collections],
  props: { type: { type: String, default: '' } },
  data: () => ({
    saving: false
  }),
  computed: {
    showDelete() {
      return !!this.images.length;
    },
    item() {
      if (this.images.length) {
        return {
          rootFolderName: 'Images',
          foldersGetter: 'admin/collections/all_folders',
          moveSaveAction: 'admin/collections/moveImage',
          deleteAction: 'images/delete'
        };
      } else if (this.templates.length) {
        return {
          rootFolderName: 'Templates',
          foldersGetter: 'admin/collections/all_folders',
          moveSaveAction: 'admin/collections/moveTemplate',
          listGetter: 'designs/list'
        };
      }
      return {};
    },
    list() {
      if (this.images.length) {
        return this.images;
      } else if (this.templates.length) {
        return this.templates;
      }
      return [];
    },
    ...mapGetters({
      images: 'admin/collections/all_images',
      templates: 'admin/collections/all_templates',
      selected: 'admin/collections/selected'
    })
  },
  methods: {
    clear() {
      this.$store.dispatch('admin/collections/clearSelected');
    },
    move() {
      this.$refs.moveDialog.showDialog(
        this.list.find((x) => x.id === this.selected[0]),
        this.selected
      );
    },
    async deleteItems() {
      if (
        await this.$root.$confirm(
          `Delete ${this.item.rootFolderName.toLowerCase()}`,
          `Are you sure you want to delete ${
            this.selected.length
          } ${this.item.rootFolderName.toLowerCase()}?`,
          {
            color: 'error',
            top: false,
            buttonText: `Yes, delete ${this.item.rootFolderName.toLowerCase()}`
          },
          '',
          'DELETE'
        )
      ) {
        this.saving = true;
        this.$store
          .dispatch(this.item.deleteAction, this.selected)
          .then(() => {
            this.clear();
          })
          .catch((error) => {
            this.logError(error, 'An error occurred while deleting an item.');
          })
          .finally(() => {
            this.saving = false;
          });
      }
    }
  }
};
</script>
