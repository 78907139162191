<template>
  <mcs-sheet>
    <div>
      <div class="text-h6">Thank you 🎉</div>
    </div>
    <div>
      Our team will take good care of your order as it moves through our facility and into the mail.
      <br /><br />
      We've placed a copy of your receipt in your order history and you will receive an email with
      tracking information when this order ships.
    </div>
    <v-divider class="my-6" />
    <mcs-btn
      large
      color="primary"
      block
      class="mt-5"
      prepend-icon="mdi-package-variant-closed"
      @click="viewReceipt"
    >
      View Receipt
    </mcs-btn>
  </mcs-sheet>
</template>

<script>
import { ROUTES } from '../../../utils/constants';

export default {
  name: 'PrintCheckoutConfirmation',

  props: {
    iframed: Boolean,
    orderId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    viewReceipt() {
      // refresh our collection of orders which is used to show/hide the 'Orders' link in the main nav
      this.$store.dispatch('orders/getAll');
      this.$router.push(ROUTES.ORDER_DETAIL.PATH + '/' + this.orderId);
    }
  }
};
</script>
