<template>
  <v-menu
    v-model="radius_menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :position-x="radius_x"
    :position-y="radius_y"
  >
    <template #activator="{ on: { click }, attrs }">
      <mcs-chip
        color="grey"
        v-bind="attrs"
        @click.native="click"
      >
        <div class="my-auto pl-2">{{ value | twoDecimals }} miles</div>
        <v-icon class="mr-1"> mdi-menu-down </v-icon>
      </mcs-chip>
    </template>
    <mcs-sheet
      class="overflow-hidden px-0 pt-3 pb-6"
      style="width: 300px"
    >
      <v-row
        class="px-6"
        dense
      >
        <v-col
          class="my-auto"
          cols="auto"
        >
          {{ title }}
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <mcs-btn
            icon
            color="black"
            prepend-icon="mdi-close"
            @click="radius_menu = false"
          ></mcs-btn>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="radius"
            dense
            outlined
            :items="items"
            label="Radius (miles)"
            hide-details="auto"
          >
            <template #append-outer>
              <mcs-btn
                color="black"
                :disabled="!radius_changed"
                @click="apply"
              >
                Save
              </mcs-btn>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </mcs-sheet>
  </v-menu>
</template>

<script>
import snailblast from '@/utils/mixins/snailblast';
import { mapGetters } from 'vuex';
export default {
  mixins: [snailblast],
  props: {
    value: { type: Number, default: 5 },
    title: { type: String, default: 'Search address' }
  },
  data() {
    return {
      radius_menu: false,
      radius_x: 0,
      radius_y: 0,
      radius_changed: false,
      radius: 5
    };
  },
  computed: {
    items() {
      const list = [];
      for (var i = 0.25; i <= 10; i += 0.25) {
        list.push({ value: i, text: this.$options.filters.twoDecimals(i) });
      }
      return list;
    },
    ...mapGetters({ is_list_purchase: 'snailblast/campaign/is_list_purchase' })
  },
  watch: {
    radius(val, oldVal) {
      this.$emit('input', parseFloat(val));
      this.radius_changed = true;
    },
    value(val, oldVal) {
      this.radius = val;
    },
    radius_menu(val, oldVal) {
      if (val !== oldVal) {
        this.$emit(val ? 'open' : 'close');
        this.radius = this.value;
      }
    }
  },
  mounted() {
    this.radius = this.value;
  },
  methods: {
    apply() {
      this.$emit('apply', parseFloat(this.radius));
      this.radius_menu = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__append-outer {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .v-input__append-inner {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
