import moment from 'moment';
import axios from 'axios';
import turf from 'turf';
import { APP_EVENTS, ENUMS } from '@/utils/constants';
import { cloneDeep } from 'lodash';

export default {
  methods: {
    getScheduleRangeFormatted(date) {
      const dates = this.getScheduleRange(date);
      if (dates.length !== 2) {
        return '';
      }
      return `${this.$options.filters.regularDateNoYear(
        dates[0]
      )} - ${this.$options.filters.regularDateNoYear(dates[1])}`;
    },
    getScheduleRange(date) {
      if (!date) {
        return [];
      }
      return [moment(date).add(-1, 'days'), moment(date).add(3, 'days')];
    },
    async getMapboxPlaces(search, limitTypes) {
      let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        search
      )}.json?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}&country=US&autocomplete=true`;
      if (limitTypes) {
        url += '&types=region,postcode,district,place';
      }
      const list = await axios.get(url);
      return list.data.features;
    },
    async getMapboxPlace(lat, lng) {
      const list = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}&country=US&autocomplete=true`
      );
      return list.data.features;
    },
    convertMapboxToLocation(databaseId, val) {
      let state = '';
      const location = {
        text: val.place_name,
        boundingBox: val.bbox ? turf.bboxPolygon(val.bbox).geometry : null,
        centroid: val.geometry
      };
      switch (val.place_type[0]) {
        case 'place':
          state = val.context
            .filter((x) => x.id.startsWith('region'))
            .map((x) => x.short_code.replace('US-', ''))[0];
          location.field = this.fields(databaseId).find((x) => x.name === 'City');
          location.value = state + val.text;
          break;
        case 'district':
          state = val.context
            .filter((x) => x.id.startsWith('region'))
            .map((x) => x.short_code.replace('US-', ''))[0];
          location.field = this.fields(databaseId).find((x) => x.name === 'County');
          location.value = val.text.replace(' County', '');
          location.parent = state;
          break;
        case 'region':
          state = val.properties.short_code.replace('US-', '');
          location.field = this.fields(databaseId).find((x) => x.name === 'State');
          location.value = state;
          break;
        case 'postcode':
          location.field = this.fields(databaseId).find((x) => x.name === 'Postal Code');
          location.value = val.text;
          break;
      }
      return location;
    },
    convertMapboxToRadiusLocation(val) {
      return {
        text: val.place_name,
        shape: { type: val.geometry.type, coordinates: val.geometry.coordinates }
      };
    },
    async getDefaultListPurchase() {
      return await this.$store.dispatch('snailblast/campaign/getDefaultListPurchase');
    },
    getListPurchaseDatabaseIcon(id) {
      switch (id) {
        case ENUMS.SNAILBLAST.LIST_PURCHASE_DATABASE.CONSUMER:
          return '🏘️';
        case ENUMS.SNAILBLAST.LIST_PURCHASE_DATABASE.NEW_BUSINESS:
          return '💼';
        case ENUMS.SNAILBLAST.LIST_PURCHASE_DATABASE.BUSINESS:
          return '🏢';
        case ENUMS.SNAILBLAST.LIST_PURCHASE_DATABASE.NEW_MOVER:
          return '🚚';
        case ENUMS.SNAILBLAST.LIST_PURCHASE_DATABASE.NEW_HOMEOWNER:
          return '🏠';
      }
      return '';
    },
    async setListPurchaseField(field, val, ignoreCount) {
      const items = cloneDeep(this.$store.getters['snailblast/campaign/list_purchases']);
      for (const item of items) {
        item[field] = val;
      }
      await this.setListPurchases(items, ignoreCount);
    },
    async setListPurchases(val, ignoreCount) {
      await this.$store.dispatch('snailblast/campaign/setListPurchases', val);
      if (!ignoreCount) {
        this.$nextTick(() => {
          this.$eventBus.$emit(APP_EVENTS.GET_LIST_PURCHASE_COUNT);
        });
      }
    },
    async addListPurchase(item) {
      item.databaseId = this.$store.getters['snailblast/campaign/list_purchase_database_id'];
      item.filters = this.$store.getters['snailblast/campaign/list_purchase_filters'];
      await this.$store.dispatch('snailblast/campaign/addListPurchase', item);
      this.$nextTick(() => {
        this.$eventBus.$emit(APP_EVENTS.GET_LIST_PURCHASE_COUNT);
      });
    },
    removeListPurchaseRadius(index) {
      const searches = cloneDeep(this.$store.getters['snailblast/campaign/list_purchases']);
      searches.splice(index, 1);
      this.setListPurchases(searches);
    },
    getGuideType(campaignTypeId) {
      switch (campaignTypeId) {
        case ENUMS.SNAILBLAST.CAMPAIGN_TYPE.EDDM:
          return 'areablasteddm';
        case ENUMS.SNAILBLAST.CAMPAIGN_TYPE.UPLOAD:
        case ENUMS.SNAILBLAST.CAMPAIGN_TYPE.LIST_PURCHASE:
          return 'directblastplaceholder';
      }
      return '';
    }
  }
};
