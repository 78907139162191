<template>
  <div class="white rounded px-2 pb-1">
    <v-icon
      class="hover-pointer mr-1"
      style="margin-top: 5px"
      small
      @click="$emit('zoom', false)"
    >
      mdi-minus
    </v-icon>
    <v-icon
      class="hover-pointer"
      style="margin-top: 5px"
      small
      @click="$emit('zoom', true)"
    >
      mdi-plus
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'MapZoom',
  data() {
    return {};
  }
};
</script>
