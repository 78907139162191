import VueRouter from 'vue-router';
import store from '../store/index';
import { ROUTES } from '../utils/constants';

// this will suppress the navigation to same path error
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return new Promise((resolve, reject) => {
    originalPush.call(
      this,
      location,
      () => {
        resolve(this.currentRoute);
      },
      (error) => {
        // on abort
        // only ignore NavigationDuplicated error
        if (error.name === 'NavigationDuplicated') {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

const routes = [
  {
    name: ROUTES.DASHBOARD.NAME,
    path: ROUTES.DASHBOARD.PATH,
    meta: { name: ROUTES.DASHBOARD.DISPLAY },
    component: () => import(/* webpackChunkName: "dashboard", */ '../views/Dashboard')
  },
  {
    name: ROUTES.PROJECTS.NAME,
    path: ROUTES.PROJECTS.PATH,
    meta: { name: ROUTES.PROJECTS.DISPLAY },
    component: () => import(/* webpackChunkName: "projects" */ '../views/projects/AllProjects')
  },
  {
    name: ROUTES.PROJECT_DETAIL.NAME,
    path: ROUTES.PROJECT_DETAIL.PATH + '/:id',
    meta: {
      name: ROUTES.PROJECT_DETAIL.DISPLAY,
      breadcrumbs: [ROUTES.PROJECTS]
    },
    component: () =>
      import(/* webpackChunkName: "project-details" */ '../views/projects/ProjectDetail')
  },
  {
    name: ROUTES.USER_COLLECTIONS.NAME,
    path: ROUTES.USER_COLLECTIONS.PATH,
    meta: { name: ROUTES.USER_COLLECTIONS.DISPLAY },
    component: () => import(/* webpackChunkName: "user-collections" */ '../views/UserCollections')
  },
  {
    name: ROUTES.USER_COLLECTIONS_CHILD.NAME,
    path: ROUTES.USER_COLLECTIONS_CHILD.PATH,
    meta: { name: ROUTES.USER_COLLECTIONS_CHILD.DISPLAY },
    component: () => import(/* webpackChunkName: "user-collections" */ '../views/UserCollections')
  },
  {
    name: ROUTES.PRINT.NAME,
    path: ROUTES.PRINT.PATH,
    meta: { name: ROUTES.PRINT.DISPLAY },
    component: () => import(/* webpackChunkName: "print" */ '../views/Print')
  },
  {
    name: ROUTES.SUBSCRIPTION.NAME,
    path: ROUTES.SUBSCRIPTION.PATH,
    meta: { name: ROUTES.SUBSCRIPTION.DISPLAY },
    component: () => import(/* webpackChunkName: "subscription" */ '../views/Subscription')
  },
  {
    name: ROUTES.ORDER_HISTORY.NAME,
    path: ROUTES.ORDER_HISTORY.PATH,
    meta: { name: ROUTES.ORDER_HISTORY.DISPLAY },
    component: () => import(/* webpackChunkName: "order-history" */ '../views/OrderHistory')
  },
  {
    name: ROUTES.ORDER_DETAIL.NAME,
    path: ROUTES.ORDER_DETAIL.PATH + '/:id',
    meta: { name: ROUTES.ORDER_DETAIL.DISPLAY, title: 'Order Detail - ' + ':id' },
    component: () => import(/* webpackChunkName: "order-detail" */ '../views/OrderDetail')
  },
  {
    name: ROUTES.CARDS.NAME,
    path: ROUTES.CARDS.PATH,
    meta: { name: ROUTES.CARDS.DISPLAY },
    component: () => import(/* webpackChunkName: "cards" */ '../views/Cards')
  },
  {
    name: ROUTES.PROFILE.NAME,
    path: ROUTES.PROFILE.PATH,
    meta: { name: ROUTES.PROFILE.DISPLAY },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile')
  },
  {
    name: ROUTES.CONTRIBUTOR_DESIGN_PROJECTS.NAME,
    path: ROUTES.CONTRIBUTOR_DESIGN_PROJECTS.PATH,
    meta: { name: ROUTES.CONTRIBUTOR_DESIGN_PROJECTS.DISPLAY, title: 'Designer Projects' },
    component: () =>
      import(
        /* webpackChunkName: "contributor-projects" */ '../views/contributor/ContributorDesignProjects'
      )
  },
  {
    name: ROUTES.CONTRIBUTOR_ASSET_PROJECTS.NAME,
    path: ROUTES.CONTRIBUTOR_ASSET_PROJECTS.PATH,
    meta: { name: ROUTES.CONTRIBUTOR_ASSET_PROJECTS.DISPLAY, title: 'Asset Projects' },
    component: () =>
      import(
        /* webpackChunkName: "asset-projects" */ '../views/contributor/ContributorAssetProjects'
      )
  },
  {
    name: ROUTES.CONTRIBUTOR_IMAGE_PROJECTS.NAME,
    path: ROUTES.CONTRIBUTOR_IMAGE_PROJECTS.PATH,
    meta: { name: ROUTES.CONTRIBUTOR_IMAGE_PROJECTS.DISPLAY, title: 'Image Projects' },
    component: () =>
      import(
        /* webpackChunkName: "image-projects" */ '../views/contributor/ContributorImageProjects'
      )
  },
  {
    name: ROUTES.CONTRIBUTOR_CUSTOMER_PROJECTS.NAME,
    path: ROUTES.CONTRIBUTOR_CUSTOMER_PROJECTS.PATH,
    meta: { name: ROUTES.CONTRIBUTOR_CUSTOMER_PROJECTS.DISPLAY, title: 'Custmer Projects' },
    component: () =>
      import(/* webpackChunkName: "contributor-projects" */ '../views/contributor/CustomerProjects')
  },
  {
    name: ROUTES.CONTRIBUTOR_PAYOUTS.NAME,
    path: ROUTES.CONTRIBUTOR_PAYOUTS.PATH,
    meta: { name: ROUTES.CONTRIBUTOR_PAYOUTS.DISPLAY, title: 'Designer Payouts' },
    component: () =>
      import(
        /* webpackChunkName: "contributor-payouts" */ '../views/contributor/ContributorPayouts'
      )
  },
  {
    name: ROUTES.CAMPAIGN_IDEAS.NAME,
    path: ROUTES.CAMPAIGN_IDEAS.PATH,
    meta: { name: ROUTES.CAMPAIGN_IDEAS.DISPLAY, title: 'Campaign Ideas' },
    component: () => import(/* webpackChunkName: "campaigns" */ '../views/CampaignIdeas')
  },
  {
    name: ROUTES.ADMIN_APP_LINKS.NAME,
    path: ROUTES.ADMIN_APP_LINKS.PATH,
    meta: { name: ROUTES.ADMIN_APP_LINKS.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-app-links" */ '../views/admin/AppLinks')
  },
  {
    name: ROUTES.ADMIN_BANKING.NAME,
    path: ROUTES.ADMIN_BANKING.PATH,
    meta: { name: ROUTES.ADMIN_BANKING.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-banking" */ '../views/admin/Banking')
  },
  {
    name: ROUTES.ADMIN_SETTINGS.NAME,
    path: ROUTES.ADMIN_SETTINGS.PATH,
    meta: { name: ROUTES.ADMIN_SETTINGS.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-settings" */ '../views/admin/Settings')
  },
  {
    name: ROUTES.ADMIN_THEME.NAME,
    path: ROUTES.ADMIN_THEME.PATH,
    meta: { name: ROUTES.ADMIN_THEME.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-settings" */ '../views/admin/Theme')
  },
  {
    name: ROUTES.ADMIN_MASTER_ACCOUNTS.NAME,
    path: ROUTES.ADMIN_MASTER_ACCOUNTS.PATH,
    meta: { name: ROUTES.ADMIN_MASTER_ACCOUNTS.DISPLAY },
    component: () =>
      import(/* webpackChunkName: "admin-master-accounts" */ '../views/admin/MasterAccounts')
  },
  {
    name: ROUTES.ADMIN_ORDERS_PAYOUTS.NAME,
    path: ROUTES.ADMIN_ORDERS_PAYOUTS.PATH,
    meta: {
      name: ROUTES.ADMIN_ORDERS_PAYOUTS.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_ORDERS]
    },
    component: () =>
      import(/* webpackChunkName: "admin-reports-payouts" */ '../views/admin/orders/Payouts')
  },
  {
    name: ROUTES.ADMIN_ORDERS_PAYMENTS.NAME,
    path: ROUTES.ADMIN_ORDERS_PAYMENTS.PATH,
    meta: {
      name: ROUTES.ADMIN_ORDERS_PAYMENTS.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_ORDERS]
    },
    component: () =>
      import(/* webpackChunkName: "admin-reports-payments" */ '../views/admin/orders/Payments')
  },
  {
    name: ROUTES.ADMIN_DESIGN_FEED.NAME,
    path: ROUTES.ADMIN_DESIGN_FEED.PATH,
    meta: {
      name: ROUTES.ADMIN_DESIGN_FEED.DISPLAY
    },
    component: () => import(/* webpackChunkName: "admin-design-feed" */ '../views/admin/DesignFeed')
  },
  {
    name: ROUTES.ADMIN_EXEMPTION_CERTS.NAME,
    path: ROUTES.ADMIN_EXEMPTION_CERTS.PATH,
    meta: { name: ROUTES.ADMIN_EXEMPTION_CERTS.DISPLAY },
    component: () =>
      import(
        /* webpackChunkName: "admin-exemption-certificates" */ '../views/admin/exemptionCertificates/ManageCertificates'
      )
  },
  {
    name: ROUTES.ADMIN_PRODUCT_SETUP.NAME,
    path: ROUTES.ADMIN_PRODUCT_SETUP.PATH,
    meta: { name: ROUTES.ADMIN_PRODUCT_SETUP.DISPLAY },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-setup-entry" */ '../views/admin/productSetup/Entry'
      )
  },
  {
    name: ROUTES.ADMIN_SETUP_INDUSTRIES.NAME,
    path: ROUTES.ADMIN_SETUP_INDUSTRIES.PATH,
    meta: {
      name: ROUTES.ADMIN_SETUP_INDUSTRIES.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_PRODUCT_SETUP]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-setup-industries" */ '../views/admin/productSetup/Industries'
      )
  },
  {
    name: ROUTES.ADMIN_SETUP_CATEGORY.NAME,
    path: ROUTES.ADMIN_SETUP_CATEGORY.PATH,
    meta: {
      name: ROUTES.ADMIN_SETUP_CATEGORY.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_PRODUCT_SETUP]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-setup-category" */ '../views/admin/productSetup/Category'
      )
  },
  {
    name: ROUTES.ADMIN_SETUP_COREPRODUCT.NAME,
    path: ROUTES.ADMIN_SETUP_COREPRODUCT.PATH,
    meta: {
      name: ROUTES.ADMIN_SETUP_COREPRODUCT.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_PRODUCT_SETUP, ROUTES.ADMIN_SETUP_CATEGORY]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-setup-coreproduct" */ '../views/admin/productSetup/Product'
      )
  },
  {
    name: ROUTES.ADMIN_SETUP_MATERIAL.NAME,
    path: ROUTES.ADMIN_SETUP_MATERIAL.PATH,
    meta: {
      name: ROUTES.ADMIN_SETUP_MATERIAL.DISPLAY,
      breadcrumbs: [
        ROUTES.ADMIN_PRODUCT_SETUP,
        ROUTES.ADMIN_SETUP_CATEGORY,
        ROUTES.ADMIN_SETUP_COREPRODUCT
      ]
    },
    component: () =>
      import(/* webpackChunkName: "admin-setup-material" */ '../views/admin/productSetup/Material')
  },
  {
    name: ROUTES.ADMIN_PEOPLE.NAME,
    path: ROUTES.ADMIN_PEOPLE.PATH,
    meta: { name: ROUTES.ADMIN_PEOPLE.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-users" */ '../views/admin/people/List')
  },
  {
    name: ROUTES.ADMIN_PEOPLE_DETAIL.NAME,
    path: ROUTES.ADMIN_PEOPLE_DETAIL.PATH,
    meta: { name: ROUTES.ADMIN_PEOPLE_DETAIL.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-users" */ '../views/admin/people/Detail')
  },
  {
    name: ROUTES.ADMIN_COLLECTIONS_SETUP.NAME,
    path: ROUTES.ADMIN_COLLECTIONS_SETUP.PATH,
    meta: { name: ROUTES.ADMIN_COLLECTIONS_SETUP.DISPLAY },
    component: () =>
      import(/* webpackChunkName: "admin-collections" */ '../views/admin/collections/List')
  },
  {
    name: ROUTES.ADMIN_ASSETS_SETUP.NAME,
    path: ROUTES.ADMIN_ASSETS_SETUP.PATH,
    meta: {
      name: ROUTES.ADMIN_ASSETS_SETUP.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_COLLECTIONS_SETUP],
      showToggle: true
    },
    component: () =>
      import(/* webpackChunkName: "admin-assets-setup" */ '../views/admin/collections/Assets')
  },
  {
    name: ROUTES.ADMIN_ASSETS_CHILD_SETUP.NAME,
    path: ROUTES.ADMIN_ASSETS_CHILD_SETUP.PATH,
    meta: {
      name: ROUTES.ADMIN_ASSETS_CHILD_SETUP.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_COLLECTIONS_SETUP],
      showToggle: true
    },
    component: () =>
      import(/* webpackChunkName: "admin-assets-child-setup" */ '../views/admin/collections/Assets')
  },
  {
    name: ROUTES.ADMIN_API_DOCS.NAME,
    path: ROUTES.ADMIN_API_DOCS.PATH,
    meta: { name: ROUTES.ADMIN_API_DOCS.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-api-docs" */ '../views/admin/api/Index')
  },
  {
    name: ROUTES.PRINT_CHECKOUT_IFRAME.NAME,
    path: ROUTES.PRINT_CHECKOUT_IFRAME.PATH,
    meta: { name: 'Print Checkout', iframe: true },
    component: () =>
      import(/* webpackChunkName: "print-checkout-iframe" */ '../views/PrintCheckoutIframe')
  },
  {
    name: ROUTES.SUBSCRIPTION_CHECKOUT_IFRAME.NAME,
    path: ROUTES.SUBSCRIPTION_CHECKOUT_IFRAME.PATH,
    meta: { name: 'Subscription Checkout', iframe: true },
    component: () =>
      import(
        /* webpackChunkName: "subscription-checkout-iframe" */ '../views/SubscriptionCheckoutIframe'
      )
  },
  {
    name: ROUTES.ADMIN_ORDERS.NAME,
    path: ROUTES.ADMIN_ORDERS.PATH,
    meta: { name: ROUTES.ADMIN_ORDERS.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-orders" */ '../views/admin/orders/List')
  },
  {
    name: ROUTES.ADMIN_STYLES.NAME,
    path: ROUTES.ADMIN_STYLES.PATH,
    meta: { name: ROUTES.ADMIN_STYLES.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-orders" */ '../views/admin/Styles')
  },
  {
    name: ROUTES.NOTFOUND.NAME,
    path: ROUTES.NOTFOUND.PATH,
    meta: { title: 'Page Not Found' },
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    meta: { title: 'Page Not Found' },
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound')
  }
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // if (to.path === '/profile') {
  //   next(ROUTES.DASHBOARD + '#profile');
  // }
  next();
});

router.afterEach((to, from, failure) => {
  document.title = to.meta?.name || to.meta?.title || 'unset';
  store.commit('config/SET_ROUTE', to);
});

export default router;
