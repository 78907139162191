const state = {
  item: {},
  allPlans: [],
  currentPlanDiscount: null,
  reactivationPromoCode: null,
  selectedPlan: null,
  invoicePreview: null
};

const getters = {
  currencies(state) {
    return state.allPlans?.map((x) => x.currency) || [];
  },

  currentCurrencyCode(state, getters) {
    // stripe currency always overrides - it's the currency set on the stripe customer and it can't ever be changed
    if (state.stripeCurrency) return state.stripeCurrency;
    if (!state.selectedPlan) return 'USD';
    return state.selectedPlan.currency.code;
  },

  plansForCurrency(state, getters) {
    return state.allPlans.find((x) => x.currency.code === getters.currentCurrencyCode);
  },

  enableMultiCurrency(state, getters, rootState) {
    if (getters.currencies.length <= 1 || state.stripeCurrency) return false;
    // if (this.$store.state.config.inDevEnv) return true;
    if (rootState.user.profile.country.isInternational && getters.plansForCurrency?.length) { return true; }
    return false;
  },

  monthlyPlan(state, getters) {
    return getters.plansForCurrency?.monthlyPlan;
  },

  annualPlan(state, getters) {
    return getters.plansForCurrency?.annualPlan;
  },

  selectedPlan(state, getters) {
    if (state.selectedPlan) return state.selectedPlan;
    return getters.monthlyPlan;
  }
};

const actions = {
  getDetail({ commit, rootState }, id) {
    console.log('getting subscription detail');
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('subscription/detail')
        .then((response) => {
          commit('SET_ITEM', response.data);
          commit('cards/SET_CARDS', response.data.cards, { root: true });
          commit('user/UPDATE_PLAN', response.data.userPlan, { root: true });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  cancel({ commit, rootState }, reasonId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`subscription/cancel?reasonId=${reasonId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  reactivate({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('subscription/reactivate', payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  previewInvoice({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      var payload = {
        subscriptionId: state.item.subscriptionId,
        stripePlanId: state.selectedPlan.stripePlanId
      };
      this._vm.$api
        .post('subscription/previewInvoice', payload)
        .then((response) => {
          commit('SET_INVOICE_PREVIEW', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changePlan({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('subscription/changePlan', payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  },

  retryPayment({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('subscription/retryPayment', payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setDefaultPayment({ commit, rootState }, cardId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`subscription/setDefaultPayment?cardId=${cardId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_ITEM(state, payload) {
    state.item = payload.subscription || {};
    state.allPlans = payload.allPlans;
    state.currentPlanDiscount = payload.currentPlanDiscount;
    state.reactivationPromoCode = payload.reactivationPromoCode;
    state.stripeCurrency = payload.stripeCurrency?.toUpperCase();
  },

  SET_SELECTED_PLAN(state, plan) {
    state.selectedPlan = plan;
  },

  SET_INVOICE_PREVIEW(state, invoice) {
    state.invoicePreview = invoice;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
