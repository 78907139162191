<template>
  <UploadMapping
    v-if="has_addresses_mapping"
    ref="child"
    @reset="$emit('reset')"
    @guide="$emit('guide')"
  />
  <UploadAddresses
    v-else
    ref="child"
    @reset="$emit('reset')"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import UploadMapping from '@/components/print/checkout/snailblast/upload/mapping/Index';
import UploadAddresses from '@/components/print/checkout/snailblast/upload/addresses/Index';

export default {
  name: 'SnailblastUpload',

  components: { UploadMapping, UploadAddresses },

  data: () => ({}),

  computed: {
    ...mapGetters({
      mapping: 'snailblast/campaign/addresses_mapping',
      has_addresses_mapping: 'snailblast/campaign/has_addresses_mapping'
    })
  },

  methods: {
    save() {
      this.$refs.child.save();
    }
  }
};
</script>
