import moment from 'moment';

const methods = {
  isoDate(date) {
    if (date) {
      var local = moment.utc(date).local().format('YYYY-MM-DD');
      return local;
    }
    return '';
  },
  normalDate(date) {
    if (date) {
      var local = moment(date).format('MM/D/YYYY');
      return local;
    }
    return '';
  },
  regularDate(date) {
    if (date) {
      var local = moment(date).format('MMM D, YYYY');
      return local;
    }
    return '';
  },
  regularDateWithTime(date) {
    if (date) {
      var local = moment(date).format('MMM D, YYYY [@] hh:mm A');
      return local;
    }
    return '';
  },
  regularDateNoYear(date) {
    if (date) {
      var local = moment(date).format('MMM D');
      return local;
    }
    return '';
  },
  normalDateWithTime(date) {
    if (date) {
      var local = moment(date).format('MM/D/YYYY [@] hh:mm A');
      return local;
    }
    return '';
  },
  longDate(date) {
    if (date) {
      var local = moment(date).format('MMMM D, YYYY');
      return local;
    }
    return '';
  },
  longDateWithTime(date) {
    if (date) {
      var local = moment(date).format('MMMM D, YYYY [@] hh:mm A');
      return local;
    }
    return '';
  },
  shortDateWithTime(date) {
    if (date) {
      var local = moment(date).format('MMM D, YYYY [@] hh:mm A');
      return local;
    }
    return '';
  },
  time(date) {
    if (date) {
      var local = moment(date).format('hh:mm A');
      return local;
    }
    return '';
  },
  timeWithSeconds(date) {
    if (date) {
      var local = moment(date).format('HH:mm:ss');
      return local;
    }
    return '';
  },
  year(date) {
    if (date) {
      var local = moment(date).format('YYYY');
      return local;
    }
    return '';
  },
  monthDate(date) {
    if (date) {
      var local = moment(date).format('MMMM Do');
      return local;
    }
    return '';
  }
};

export default methods;
