<template>
  <mcs-image-card
    thumbnail
    :url="imageUrl"
    img-width="150"
    :title="name"
    :subtitle="`${text1}<br/>${modifiedTerm} ${text2}`"
    class="overflow-hidden"
  >
    <template #actions>
      <slot name="actions"></slot>
    </template>
  </mcs-image-card>
</template>

<script>
export default {
  name: 'SelectProjectCard',

  props: {
    name: {
      type: String,
      default: ''
    },
    isUpload: Boolean,
    text1: {
      type: String,
      default: ''
    },
    text2: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    }
  },

  data() {
    return {};
  },

  computed: {
    modifiedTerm() {
      return this.isUpload ? 'Uploaded: ' : 'Modified: ';
    }
  }
};
</script>
