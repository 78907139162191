<template>
  <div class="pa-4 mt-8">© {{ year }} {{ name }}, All rights reserved.</div>
</template>

<script>
export default {
  name: 'Footer',

  components: {},

  data() {
    return {
      year: ''
    };
  },

  computed: {
    name() {
      return this.$store.state.user.masterAccount.name;
    }
  },

  mounted() {
    this.year = new Date().getFullYear();
  },

  methods: {}
};
</script>

<style></style>
