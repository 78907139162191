<template>
  <v-snackbar
    v-model="show"
    :timeout="timeout"
    top
    light
  >
    <mcs-toast
      :isError="type === 'error'"
      :title="title"
      :message="message"
      :closeFunc="close"
    />
  </v-snackbar>
</template>

<script>
import { APP_EVENTS } from '../../utils/constants';

export default {
  name: 'Snackbar',

  data: () => ({
    show: false,
    type: 'success', // options are 'success' or 'error'
    message: '',
    timeout: 5000
  }),

  computed: {
    title() {
      return this.type === 'success' ? 'Success' : 'Error';
    }
  },

  mounted() {
    this.$eventBus.$emit(APP_EVENTS.SNACKBAR_MOUNTED);
  },

  methods: {
    $openSnackbar(message, type, timeout) {
      this.message = message;
      this.type = type || 'success';
      this.show = true;
      this.timeout = timeout;
    },
    openSnackbarSuccess(message, timeout) {
      this.$openSnackbar(message, 'success', timeout || 5000);
    },
    openSnackbarError(message, timeout) {
      this.$openSnackbar(message, 'error', timeout || 5000);
    },
    close() {
      this.show = false;
    }
  }
};
</script>

<style scoped>
>>> .v-snack__wrapper {
  display: block;
}
</style>
