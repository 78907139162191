<template>
  <mcs-card
    :title="title"
    :subtitle="subtitle"
  >
    <template #title-prepend>
      <div
        v-if="$slots['title-prepend']"
        class="d-flex"
      >
        <slot name="title-prepend"> </slot>
      </div>
    </template>
    <template #title-append>
      <div
        v-if="$slots.actions"
        class="d-flex"
      >
        <slot name="actions"> </slot>
      </div>
    </template>
    <div
      v-if="$slots.filters"
      class="mb-4 d-flex"
    >
      <slot name="filters"> </slot>
    </div>
    <slot></slot>
  </mcs-card>
</template>

<script>
export default {
  props: { title: { type: String, default: '' }, subtitle: { type: String, default: '' } }
};
</script>
