import moment from 'moment';

const state = {
  list: [],
  totalCount: 0,
  totalValue: 0,
  totalsLoading: false,
  totalsLoadedAt: null
};

const getters = {
  list(state) {
    return state.list || [];
  }
};

const actions = {
  async getList({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('admin/orders/list', req)
        .then((response) => {
          commit('SET_LIST', response.data.list);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getTotals({ commit }) {
    if (state.totalsLoading) return;
    if (state.totalsLoadedAt) {
      var now = moment();
      var duration = moment.duration(now.diff(state.totalsLoadedAt));
      var minutes = duration.asMinutes();
      if (minutes < 30) return;
    }
    state.totalsLoading = true;
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('admin/orders/getTotals')
        .then((response) => {
          commit('SET_TOTALS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_TOTALS(state, data) {
    state.totalCount = data.totalCount;
    state.totalValue = data.totalValue;
    state.totalsLoadedAt = Date.now();
    state.totalsLoading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
