<template>
  <Drawer
    :id="drawerId"
    title="Fix Subscription"
    padded
  >
    <div>
      <PaymentSelector @paymentMethodSelected="(id) => (paymentMethodId = id)" />
      <mcs-btn
        color="primary"
        block
        large
        :disabled="!paymentMethodId"
        :loading="processing"
        @click="retryPayment"
      >
        Retry Payment
      </mcs-btn>
      <mcs-alert
        v-show="error"
        type="error"
        class="mt-3"
        :text="error"
      />
    </div>
  </Drawer>
</template>

<script>
import Drawer from '../../components/layout/Drawer';
import PaymentSelector from '../../components/payments/PaymentSelector';
import { DRAWERS } from '../../utils/constants';

export default {
  name: 'RetryPaymentDrawer',

  components: {
    Drawer,
    PaymentSelector
  },

  data() {
    return {
      paymentMethodId: 0,
      drawerId: DRAWERS.RETRY_PAYMENT_DRAWER,
      error: '',
      processing: false
    };
  },

  methods: {
    retryPayment() {
      var payload = {
        paymentMethodId: this.paymentMethodId,
        subscriptionId: this.$store.state.subscription.item.id
      };
      this.processing = true;
      this.$store
        .dispatch('subscription/retryPayment', payload)
        .then(async (result) => {
          if (result.success) {
            await this.$store.dispatch('subscription/getDetail');
            this.$store.commit('drawers/CLOSE_ALL');
            this.$root.$snackbarSuccess('Subscription Reactivated');
          } else {
            this.error = result.message;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>

<style scoped></style>
