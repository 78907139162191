<template>
  <Drawer
    :id="drawerId"
    title="Create A New Project"
    width="95%"
  >
    <v-progress-circular
      v-if="!loaded"
      :size="125"
      color="primary"
      indeterminate
      class="loader"
    />
    <div
      v-if="catalogUrl"
      class="d-flex h-full"
    >
      <iframe
        :src="catalogUrl"
        class="w-full h-full"
        style="border: none"
      />
    </div>
  </Drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import Drawer from '../../components/layout/Drawer';
import { APP_EVENTS, DRAWERS } from '../../utils/constants';

export default {
  name: 'CatalogDrawer',

  components: {
    Drawer
  },

  props: {
    iframed: Boolean
  },

  data() {
    return {
      drawerId: DRAWERS.CATALOG_DRAWER,
      catalogUrl: '',
      loaded: false
    };
  },

  computed: {
    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt'
    })
  },

  watch: {
    drawerOpenedAt(val, oldVal) {
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        var url =
          this.initialData(this.drawerId).catalogUrl || this.$store.getters['config/catalogUrl'];
        this.catalogUrl = url + '?embedded=true';
      }
    }
  },

  mounted() {
    this.$eventBus.$on(APP_EVENTS.IFRAMED_CATALOG_LOADED, () => {
      this.loaded = true;
    });
  },

  methods: {}
};
</script>

<style scoped>
.loader {
  position: absolute;
  left: calc(50% - 62px);
  top: calc(50% - 62px);
}
</style>
