<template>
  <div :style="{ height: `${height}`, position: 'relative' }">
    <div
      :id="id"
      class="h-full w-full"
    />
    <SnailblastSearch
      v-if="!readonly"
      style="left: 10px; width: calc(100% - 20px); top: 10px; position: absolute"
      @search="$emit('search', $event)"
    />
    <mcs-btn
      ref="searchHere"
      color="accent"
      :class="{ 'd-none': !marker_new }"
      @click="searchHere"
    >
      Search here
    </mcs-btn>
    <div
      v-if="hover_feature"
      style="z-index: 10000; left: 10px; bottom: 10px; position: absolute"
    >
      <mcs-chip
        color="white"
        class="px-2 py-1"
      >
        <v-icon
          small
          class="mt-n1"
        >
          mdi-map-marker-outline
        </v-icon>
        {{ hover_feature.properties.totalCount }} addresses
      </mcs-chip>
    </div>
    <div
      class="d-flex"
      style="z-index: 10000; right: 10px; bottom: 10px; position: absolute"
    >
      <MapZoom @zoom="changeZoom" />
      <MapStyle @select="changeStyle" />
      <MapExpand
        v-if="!readonly && !is_mobile"
        :expanded="expanded"
        @expand="$emit('expand')"
      />
    </div>
  </div>
</template>

<script>
import SnailblastSearch from '@/components/print/checkout/snailblast/Search';
import MapExpand from '@/components/print/checkout/snailblast/map/Expand';
import MapStyle from '@/components/print/checkout/snailblast/map/Style';
import MapZoom from '@/components/print/checkout/snailblast/map/Zoom';
import { ANALYTICS_EVENTS, APP_EVENTS, COLORS, ENUMS } from '@/utils/constants';
import snailblast from '@/utils/mixins/snailblast';
import { union } from 'lodash';
import mapboxgl from 'mapbox-gl';
import turf from 'turf';
import { mapGetters } from 'vuex';
export default {
  components: { SnailblastSearch, MapStyle, MapExpand, MapZoom },
  mixins: [snailblast],
  props: {
    height: { type: String, default: '100%' },
    readonly: { type: Boolean, default: false },
    id: { type: String, default: 'map' },
    expanded: { type: Boolean, default: false }
  },
  data() {
    return {
      map: null,
      marker_center: null,
      marker_new: null,
      source: 'routes',
      layer: 'routes',
      highlightedLayer: 'highlighted-routes',
      popup: null,
      initialCenter: [-96, 37.8],
      initialZoom: 3,
      center: [-96, 37.8],
      zoom: 3,
      style: ENUMS.SNAILBLAST.MAP_STYLES.STREETS,
      loaded: false
    };
  },
  computed: {
    features() {
      return this.allRoutes.map((x, i) => {
        return { type: 'Feature', geometry: x.shape, properties: x, id: i };
      });
    },
    selectedFeatures() {
      return this.selectedRoutes.map((x, i) => {
        return { type: 'Feature', geometry: x.shape, properties: x, id: i };
      });
    },
    notSelectedFeatures() {
      return this.routesToUse.map((x, i) => {
        return { type: 'Feature', geometry: x.shape, properties: x, id: i };
      });
    },
    allRoutes() {
      return union(this.routesToUse, this.selectedRoutes);
    },
    routesToUse() {
      if (this.readonly) {
        return [];
      }
      return this.routes;
    },
    data() {
      return {
        type: 'FeatureCollection',
        features: this.features
      };
    },
    is_mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    ...mapGetters({
      routes: 'snailblast/routes/routes',
      selectedRoutes: 'snailblast/routes/selected',
      map_center: 'snailblast/routes/map_center',
      map_radius: 'snailblast/routes/map_radius',
      address_count: 'snailblast/routes/address_count',
      campaign: 'snailblast/campaign/edit_campaign',
      hover_feature: 'snailblast/routes/hover_feature',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },
  watch: {
    routes(val, oldVal) {
      if (val !== oldVal && this.loaded) {
        this.setSource();
        this.showRouteInfo(null);
      }
    },
    selectedRoutes(val, oldVal) {
      if (val !== oldVal && this.loaded) {
        this.updateHighlighted();
        this.showRouteInfo(null);
      }
    },
    map_center(val, oldVal) {
      if (val !== oldVal && val && this.loaded) {
        this.setMarker([val.lon, val.lat]);
      }
    },
    hover_feature(val, oldVal) {
      if (val?.id !== oldVal?.id) {
        if (oldVal) {
          this.map.setFeatureState(
            { source: this.source, id: oldVal.id },
            { hover: false, selected: false }
          );
        }
        if (val) {
          this.map.setFeatureState(
            { source: this.source, id: val.id },
            {
              hover: true,
              selected:
                this.selectedRoutes.filter((x) => x.zipRouteId === val.properties.zipRouteId)
                  .length > 0
            }
          );
        }
      }
    }
  },
  mounted() {
    this.createMap();
    const self = this;
    document.addEventListener('keyup', function (evt) {
      if (evt.keyCode === 27) {
        self.removeSearchMarker();
        this.showRouteInfo(null);
      }
    });
  },
  methods: {
    changeStyle(val) {
      this.style = val;
      this.zoom = this.map.getZoom();
      this.center = this.map.getCenter();
      this.createMap();
    },
    setMarker(val) {
      if (val == null || this.readonly) {
        if (this.marker_center) {
          this.marker_center.remove();
        }
      } else if (!this.marker_center) {
        this.marker_center = new mapboxgl.Marker({
          color: this.$vuetify.theme.themes.light.primary
        })
          .setLngLat(val)
          .addTo(this.map);
      } else {
        this.marker_center.setLngLat(val);
      }
      this.showRouteInfo(null);
    },
    createMap() {
      if (this.map) {
        this.map = null;
      }
      this.map = new mapboxgl.Map({
        accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
        container: this.id, // <div id="map"></div>
        style: this.style,
        center: this.center, // starting position
        zoom: this.zoom, // starting zoom
        attributionControl: false
      });
      this.map.on('load', () => {
        this.setSource(true);
        this.map.resize();
        this.loaded = true;
      });
    },
    setSource(initial) {
      this.removeSearchMarker();
      const source = this.map.getSource(this.source);
      if (source) {
        source.setData(this.data);
      } else {
        this.map.addSource(this.source, {
          type: 'geojson',
          data: this.data
        });

        if (this.notSelectedFeatures.length) {
          const center = turf.center(turf.featureCollection(this.notSelectedFeatures));
          this.setMarker(center.geometry.coordinates);
        }
        this.map.addLayer({
          id: this.layer,
          type: 'line',
          source: this.source,
          paint: {
            'line-color':
              this.style === ENUMS.SNAILBLAST.MAP_STYLES.STREETS
                ? COLORS.BLACK.HEX
                : COLORS.WHITE.HEX,
            'line-width': 4,
            'line-opacity': [
              'case',
              ['boolean', ['feature-state', 'selected'], false],
              0,
              ['boolean', ['feature-state', 'hover'], false],
              1,
              0.5
            ]
          }
        });

        this.map.addLayer({
          id: this.highlightedLayer,
          type: 'line',
          source: this.source,
          paint: {
            'line-color': COLORS.SUCCESS.HEX,
            'line-width': 6,
            'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 1]
          },
          filter: ['in', 'zipRouteId', '']
        });

        // Create a popup, but don't add it to the map yet.
        const markerHeight = 50;
        const markerRadius = 10;
        const linearOffset = 25;
        const popupOffsets = {
          top: [0, 0],
          'top-left': [0, 0],
          'top-right': [0, 0],
          bottom: [0, markerHeight],
          'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
          'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
          left: [markerRadius, (markerHeight - markerRadius) * -1],
          right: [-markerRadius, (markerHeight - markerRadius) * -1]
        };
        this.popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          offset: popupOffsets
        });

        // When the user moves their mouse over the state-fill layer, we'll update the
        // feature state for the feature under the mouse.
        this.map.off('mousemove');
        this.map.on('mousemove', this.layer, (e) => {
          if (e.features.length > 0) {
            this.map.getCanvas().style.cursor = 'pointer';
            this.showRouteInfo(e.features[0]);
          } else {
            this.showRouteInfo(null);
          }
        });

        this.map.off('mouseleave');
        this.map.on('mouseleave', this.layer, (e) => {
          this.showRouteInfo(null);
        });

        this.map.off('click');
        this.map.on('click', (e) => {
          if (this.readonly) {
            return;
          }
          // Set `bbox` as 5px reactangle area around clicked point.
          const bbox = [
            [e.point.x - 5, e.point.y - 5],
            [e.point.x + 5, e.point.y + 5]
          ];
          // Find features intersecting the bounding box.
          const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
            layers: [this.layer]
          });

          if (selectedFeatures.length > 0) {
            this.removeSearchMarker();
            this.selectFeatures([selectedFeatures[0]]);
          } else {
            this.setSearchMarker(e.lngLat);
          }
        });
        this.updateHighlighted();
      }

      if (initial) {
        this.zoomToSelected();
      } else {
        this.zoomToBounds();
      }
    },
    showRouteInfo(feature) {
      this.$store.dispatch('snailblast/routes/setHover', this.readonly ? null : feature);
    },
    selectFeatures(selectedFeatures) {
      const zips = [];
      selectedFeatures.forEach((x) => {
        zips.push(x.properties.zipRouteId);
        this.map.setFeatureState(
          { source: this.source, id: x.id },
          {
            hover: true,
            selected:
              this.selectedRoutes.filter((y) => y.zipRouteId === x.properties.zipRouteId).length ===
              0
          }
        );
      });

      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.ROUTE_SET,
        this.analytics_data
      );
      this.$store
        .dispatch(
          'snailblast/routes/selectRoutesFromMap',
          this.allRoutes.filter((x) => zips.includes(x.zipRouteId))
        )
        .then(() => {
          this.$emit('selected', selectedFeatures[0].properties);
          this.updateHighlighted();
          this.updatePrice();
        });
    },
    setSearchMarker(lngLat) {
      if (!this.marker_new) {
        this.marker_new = new mapboxgl.Marker({ color: this.$vuetify.theme.themes.light.accent })
          .setLngLat(lngLat)
          .addTo(this.map);
      } else {
        this.marker_new.setLngLat(lngLat);
      }
      this.popup.setLngLat(lngLat).setDOMContent(this.$refs.searchHere.$el).addTo(this.map);
      this.map.panTo(lngLat);
      this.showRouteInfo(null);
    },
    searchHere() {
      this.selectSearchAddress(this.marker_new.getLngLat());
      this.removeSearchMarker();
    },
    removeSearchMarker() {
      if (this.marker_new) {
        this.marker_new.remove();
        this.marker_new = null;
      }
      if (this.popup) {
        this.popup.remove();
      }
      this.showRouteInfo(null);
    },
    async selectSearchAddress(val) {
      this.loading = true;
      if (!val.text) {
        const features = await this.getMapboxPlace(val.lat, val.lng);
        if (features && features.length) {
          let index = features.findIndex((x) => x.place_type.includes('place'));
          if (index < 0) {
            index = 0;
          }
          val.text = features[index].place_name;
        }
      }
      const data = { lat: val.lat, lon: val.lng, radius: this.map_radius, text: val.text };
      this.$emit('search', data);
    },
    zoomToBounds() {
      if (this.readonly) {
        this.zoomToSelected();
        return;
      }
      const features = this.notSelectedFeatures.map((v) => {
        return turf.multiLineString(v.geometry.coordinates);
      });

      if (!features.length) {
        return;
      }

      const bounds = turf.bbox(turf.featureCollection(features));
      this.map.fitBounds(bounds, { padding: 50, maxZoom: 16 });
    },
    zoomToSelected() {
      const features = this.selectedFeatures.map((v) => {
        return turf.multiLineString(v.geometry.coordinates);
      });

      if (!features.length) {
        return;
      }

      const bounds = turf.bbox(turf.featureCollection(features));
      this.map.fitBounds(bounds, { padding: 50, maxZoom: 16 });
    },
    resetMap() {
      this.$store.dispatch('snailblast/routes/clear');
      this.map.easeTo({ center: this.initialCenter, zoom: this.initialZoom });
      this.setMarker(null);
      this.updatePrice();
    },
    updateHighlighted() {
      const zips = this.selectedRoutes.map((feature) => feature.zipRouteId);
      // Set a filter matching selected features by FIPS codes
      // to activate the 'counties-highlighted' layer.
      this.map.setFilter(this.highlightedLayer, ['in', 'zipRouteId', ...zips]);
    },
    updatePrice() {
      this.$eventBus.$emit(APP_EVENTS.GET_EDIT_ITEM_PRICE, { source: 'routesMap' });
    },
    changeZoom(zoomIn) {
      if (zoomIn) {
        this.map.zoomIn();
      } else {
        this.map.zoomOut();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .mapboxgl-popup-tip {
    display: none;
  }
  .mapboxgl-popup-content {
    background-color: transparent;
    padding: 0 !important;
    font-family: $body-font-family !important;
  }
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
}

@import 'https://api.mapbox.com/mapbox-gl-js/v2.5.1/mapbox-gl.css';
</style>
