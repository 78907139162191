<template>
  <div :class="marginClass">
    <v-tabs
      background-color="transparent"
      show-arrows
    >
      <v-tab
        v-for="link in links"
        :key="link.path"
        :to="link.path"
      >
        {{ link.title }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'McsPageNav',

  props: {
    // links is: { title: '', path: ''}
    links: {
      type: Array,
      default: () => []
    },
    noMargin: { type: Boolean, default: false }
  },

  data() {
    return {};
  },

  computed: {
    marginClass() {
      if (this.noMargin) {
        return 'my-0';
      }
      return 'my-6';
    },
    path() {
      return this.$route.path;
    }
  },

  methods: {}
};
</script>

<style scoped>
.theme--light.v-btn--active::before {
  opacity: 0 !important;
}
</style>
