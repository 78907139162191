/* eslint-disable vue/component-definition-name-casing */
import error from '@/utils/mixins/error';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import 'core-js/stable';
import PortalVue from 'portal-vue';
import 'regenerator-runtime/runtime';
import VueMask from 'v-mask';
import Vue from 'vue';
import VueRouter from 'vue-router';
import httpClient from './api/httpClient';
import './plugins/analytics';
import vuetify from './plugins/vuetify';
import './plugins/vuetify-mask';
import router from './router/index';
import mixpanelService from './services/mixpanelService';
import store from './store';
import dateFilter from './utils/filters/date';
import einFilter from './utils/filters/ein';
import numeralFilter from './utils/filters/numeral';
import stringFilter from './utils/filters/string';
import formValidationRules from './utils/formValidationRules';
import general from './utils/mixins/general';

// import mcs core components
import McsActionCard from './components/_core/mcs-action-card';
import McsAlert from './components/_core/mcs-alert';
import McsBtn from './components/_core/mcs-btn';
import McsCard from './components/_core/mcs-card';
import McsChip from './components/_core/mcs-chip';
import McsImageCard from './components/_core/mcs-image-card';
import McsInfoCard from './components/_core/mcs-info-card';
import McsInlineNav from './components/_core/mcs-inline-nav';
import McsPageNav from './components/_core/mcs-page-nav';
import McsSheet from './components/_core/mcs-sheet';
import McsTableCard from './components/_core/mcs-table-card';
import McsToast from './components/_core/mcs-toast';

// import root components
import App from './App';

// attach some 'global' (non reactive) components
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$http = axios;
Vue.prototype.$mixpanel = mixpanelService;
Vue.prototype.$rules = formValidationRules;

// register mcs core components
Vue.component('mcs-alert', McsAlert);
Vue.component('mcs-btn', McsBtn);
Vue.component('mcs-card', McsCard);
Vue.component('mcs-chip', McsChip);
Vue.component('mcs-action-card', McsActionCard);
Vue.component('mcs-image-card', McsImageCard);
Vue.component('mcs-info-card', McsInfoCard);
Vue.component('mcs-inline-nav', McsInlineNav);
Vue.component('mcs-page-nav', McsPageNav);
Vue.component('mcs-sheet', McsSheet);
Vue.component('mcs-table-card', McsTableCard);
Vue.component('mcs-toast', McsToast);

// add filters
Vue.filter('regularDate', dateFilter.regularDate);
Vue.filter('regularDateNoYear', dateFilter.regularDateNoYear);
Vue.filter('regularDateWithTime', dateFilter.regularDateWithTime);
Vue.filter('normalDate', dateFilter.normalDate);
Vue.filter('normalDateWithTime', dateFilter.normalDateWithTime);
Vue.filter('longDate', dateFilter.longDate);
Vue.filter('longDateWithTime', dateFilter.longDateWithTime);
Vue.filter('shortDateWithTime', dateFilter.shortDateWithTime);
Vue.filter('time', dateFilter.time);
Vue.filter('oneDecimal', numeralFilter.oneDecimal);
Vue.filter('noDecimal', numeralFilter.noDecimal);
Vue.filter('noDecimalAbs', numeralFilter.noDecimalAbs);
Vue.filter('lower', stringFilter.lower);
Vue.filter('upper', stringFilter.upper);
Vue.filter('capitalize', stringFilter.capitalize);
Vue.filter('separator', numeralFilter.separator);
Vue.filter('currency', numeralFilter.currency);
Vue.filter('currencyShort', numeralFilter.currencyShort);
Vue.filter('currencyBig', numeralFilter.currencyBig);
Vue.filter('currencySmall', numeralFilter.currencySmall);
Vue.filter('percentage', numeralFilter.percentage);
Vue.filter('twoDecimals', numeralFilter.twoDecimals);
Vue.filter('year', dateFilter.year);
Vue.filter('monthDate', dateFilter.monthDate);
Vue.filter('ein', einFilter.ein);

// final configurations
Vue.use(VueRouter);
Vue.use(PortalVue);
Vue.use(VueMask);
Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.mixin(error);
Vue.mixin(general);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_URL,
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  denyUrls: [/mcsdev.com/i, /extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
  tracesSampleRate: process.env.VUE_APP_IN_DEV === 'true' ? 1.0 : 0.1,
  tracePropagationTargets: [process.env.VUE_APP_DASHBOARD_URL, process.env.VUE_APP_API_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

/* eslint-disable no-new */
new Vue({
  vuetify,
  router,
  store,
  mixins: [],

  computed: {
    pageLoading() {
      return this.$store.state.config.pageLoading;
    },

    username() {
      return this.$store.state.user.username;
    }
  },

  watch: {
    pageLoading(val) {
      if (val) {
        window.loadingTimeout = setTimeout(() => {
          this.$store.commit('config/SET_BLOCK_UI_LOADING', true);
        }, 2000);
      } else {
        if (window.loadingTimeout) window.clearTimeout(window.loadingTimeout);
        this.$store.commit('config/SET_BLOCK_UI_LOADING', false);
      }
    },

    username(val) {
      Sentry.setUser({ email: val, id: this.$store.state.user.userId });
    }
  },

  created() {
    Vue.prototype.$api = httpClient;
    Vue.prototype.$portalMode = () => {
      return this.$store.state.user.masterAccount.enablePortal;
    };
    this.$mixpanel.initialize(
      this.$store.state.config.mixpanelConfig.token,
      this.$store.state.config.mixpanelConfig.proxyDomain
    );
  },

  methods: {
    setPageLoading(val) {
      this.$store.commit('config/SET_PAGE_LOADING', val);
    }
  },
  render: (h) => h(App)
}).$mount('#app');
