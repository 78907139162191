import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '../styles/helpers.css';
import { COLORS } from '../utils/constants';

Vue.use(Vuetify, {});

const opts = {
  breakpoint: {
    mobileBreakpoint: 'xs',
    thresholds: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1200
    }
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        primary: COLORS.PRIMARY.HEX,
        primaryLight: COLORS.PRIMARY_LIGHT.HEX,
        accent: COLORS.ACCENT.HEX,
        accentLight: COLORS.ACCENT_LIGHT.HEX,
        grey: COLORS.GREY.HEX,
        greyLight: COLORS.GREY_LIGHT.HEX,
        success: COLORS.SUCCESS.HEX,
        successLight: COLORS.SUCCESS_LIGHT.HEX,
        error: COLORS.ERROR.HEX,
        errorLight: COLORS.ERROR_LIGHT.HEX
      }
    }
  }
};

export default new Vuetify(opts);
