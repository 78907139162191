<template>
  <v-dialog
    v-model="show"
    content-class="rounded-xl"
    :width="width + 'px'"
    @input="close"
  >
    <div class="white">
      <ProjectPreviewCarousel
        v-if="projectId"
        :id="projectId"
        :key="projectId"
      />
    </div>
  </v-dialog>
</template>

<script>
import ProjectPreviewCarousel from '../../components/projects/ProjectPreviewCarousel';
import { APP_EVENTS } from '../../utils/constants';

export default {
  name: 'ProofOverlay',

  components: {
    ProjectPreviewCarousel
  },

  data() {
    return {
      show: false,
      projectId: 0,
      width: 400
    };
  },

  computed: {},

  mounted() {
    this.$eventBus.$on(APP_EVENTS.SHOW_PROOF_OVERLAY, (data) => {
      // data is: { show: boolean, id: projectId }
      if (data.show) this.$store.commit('config/INCREMENT_DIALOGS_OPEN');
      else this.$store.commit('config/DECREMENT_DIALOGS_OPEN');
      this.show = data.show;
      this.projectId = data.projectId || 0;
      this.width = Math.min(document.body.clientWidth - 20, 900);
    });
  },

  methods: {
    close() {
      this.$eventBus.$emit(APP_EVENTS.SHOW_PROOF_OVERLAY, { show: false });
    }
  }
};
</script>

<style></style>
