export function getLocal(key, val) {
  try {
    return localStorage.getItem(key);
  } catch (err) {
    return val;
  }
}

export function setLocal(key, val) {
  try {
    if (!val) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, val);
    }
  } catch (err) {
    // do nothing
  }
}
