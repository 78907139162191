<template>
  <v-sheet
    :class="classes"
    :outlined="outlined"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </v-sheet>
</template>

<script>
export default {
  props: {
    outlined: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    fillHeight: Boolean,
    showOverflow: Boolean,
    smallRadius: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      var list = [];
      if (this.noPadding) list.push('pa-0');
      else if (this.dense) list.push('pa-2');
      else list.push('pa-6');

      if (this.fillHeight) list.push('h-full');
      if (!this.showOverflow) {
        list.push('overflow-hidden');
      }
      if (this.smallRadius) {
        list.push('rounded');
      }
      return list;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-sheet ::v-deep .v-data-table {
  border-radius: 18px !important;
}
</style>
