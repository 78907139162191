<template>
  <div class="h-full d-flex flex-column autoscroll-y">
    <div class="grey px-2 py-4">
      <v-row dense>
        <v-col
          cols="auto"
          class="my-auto font-weight-bold"
        >
          Connect your fields
        </v-col>
        <v-spacer />
        <v-col
          cols="auto"
          class="py-0 my-auto"
        >
          <span
            class="text-decoration-underline hover-pointer"
            @click="$emit('guide')"
          >
            What's required?
          </span>
        </v-col>
        <v-col cols="12"> Match your spreadsheet to our data </v-col>
      </v-row>
    </div>
    <v-divider />
    <div class="h-full d-flex flex-column">
      <v-form ref="form">
        <v-simple-table>
          <thead>
            <tr>
              <th class="px-2">Our fields</th>
              <th />
              <th class="px-2">Your spreadsheet</th>
            </tr>
          </thead>
          <UploadMappingItem
            v-model="mapping.city"
            label="City"
            :rules="[$rules.requiredIndex]"
          />
          <UploadMappingItem
            v-model="mapping.state"
            label="State"
            :rules="[$rules.requiredIndex]"
          />
          <UploadMappingItem
            v-model="mapping.zip"
            label="Zip"
            :rules="[$rules.requiredIndex]"
          />
          <UploadMappingItem
            v-model="mapping.line1"
            label="Address 1"
            :rules="[$rules.requiredIndex]"
          />
          <UploadMappingItem
            v-model="mapping.line2"
            label="Address 2"
          />
          <UploadMappingItem
            v-model="mapping.firstName"
            label="First Name or Full"
            :rules="
              mapping.companyName >= 0 || mapping.overrideCompanyName || mapping.lastName >= 0
                ? []
                : [$rules.requiredIndex]
            "
          />
          <UploadMappingItem
            v-model="mapping.lastName"
            label="Last Name"
            :rules="
              mapping.companyName >= 0 || mapping.overrideCompanyName || mapping.firstName >= 0
                ? []
                : [$rules.requiredIndex]
            "
          />
          <UploadMappingItem
            v-model="mapping.companyName"
            label="Company/Message"
            :rules="
              mapping.firstName >= 0 || mapping.lastName >= 0 || mapping.overrideCompanyName
                ? []
                : [$rules.requiredIndex]
            "
            :override-value="mapping.overrideCompanyName"
            allow-override
            @override="override"
          />
        </v-simple-table>
      </v-form>
    </div>
  </div>
</template>

<script>
import UploadMappingItem from '@/components/print/checkout/snailblast/upload/mapping/format/Item';
import { ANALYTICS_EVENTS } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'SnailblastUploadMappingFormat',

  components: { UploadMappingItem },

  data: () => ({}),

  computed: {
    saving: {
      get() {
        return this.$store.getters['snailblast/campaign/saving'];
      },
      set(val) {
        this.$store.dispatch('snailblast/campaign/setSaving', val);
      }
    },
    ...mapGetters({
      campaign: 'snailblast/campaign/edit_campaign',
      mapping: 'snailblast/campaign/addresses_mapping',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },

  methods: {
    override(val) {
      this.mapping.overrideCompanyName = val;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
          ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.SAVE_FORMAT,
          this.analytics_data
        );
        this.saving = true;
        this.$store
          .dispatch('snailblast/campaign/applyMapping', {
            campaignId: this.campaign.id,
            mapping: this.mapping
          })

          .catch((error) => {
            this.logError(error, 'Error applying mapping');
          })
          .finally(() => {
            this.saving = false;
          });
      }
    }
  }
};
</script>
