<template>
  <mcs-btn
    :block="block"
    :large="large"
    :small="small"
    :text="text"
    :color="finalColor"
    :loading="loading"
    :prependIcon="editIcon"
    @click="click"
  >
    {{ buttonText }}
  </mcs-btn>
</template>

<script>
import { ANALYTICS_EVENTS, DRAWERS, ROUTES } from '../../utils/constants';

export default {
  name: 'EditorButton',

  props: {
    editorAccess: {
      type: Object,
      default: () => ({})
    },
    buttonText: {
      type: String,
      default: 'Edit'
    },
    color: {
      type: String,
      default: 'primary'
    },
    appendToUrl: {
      type: String,
      default: ''
    },
    forProjectId: {
      type: Number,
      default: 0
    },
    text: Boolean,
    block: Boolean,
    large: Boolean,
    small: Boolean,
    showEditIcon: Boolean,
    openInNewTab: Boolean,
    openInDrawer: Boolean
  },

  data() {
    return {
      loading: false,
      loadedAccess: {}
    };
  },

  computed: {
    unloaded() {
      return Object.keys(this.finalAccess).length === 0;
    },

    editIcon() {
      var icon = this.showEditIcon ? 'mdi-pencil-outline' : '';
      return this.finalAccess.grant || this.unloaded ? icon : 'mdi-alert-outline';
    },

    finalColor() {
      if (!this.unloaded && !this.finalAccess.grant) return 'error';
      return this.color || 'primary';
    },

    finalAccess() {
      if (this.forProjectId) return this.loadedAccess;
      return this.editorAccess;
    }
  },

  methods: {
    async click() {
      if (this.forProjectId) {
        this.loading = true;
        this.loadedAccess = (
          await this.$api.get(`project/getEditorAccess/${this.forProjectId}`)
        ).data;
      }
      if (this.finalAccess.grant) {
        var url = this.finalAccess.url + this.appendToUrl;
        this.loading = true;
        setTimeout(() => {
          if (this.openInNewTab) {
            window.open(url, '_blank');
          } else if (this.openInDrawer) {
            this.$store.commit('drawers/SET_DATA', {
              drawerId: DRAWERS.EDITOR_DRAWER,
              data: { editorUrl: url }
            });
            this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.EDITOR_DRAWER);
          } else window.location.href = url;
          this.loading = false;
        }, 1000);
      } else {
        var message = '';
        var title = '';
        var route = ROUTES.SUBSCRIPTION.PATH;
        var opts = { cancelText: '', okText: 'Manage Subscription', hideSupport: true };
        var analyticsAction = '';
        switch (this.finalAccess.denyCode) {
          case 'access_denied':
            analyticsAction = ANALYTICS_EVENTS.EDITOR_BLOCKED.ACTIONS.ACCESS_DENIED;
            title = 'Access Denied';
            message = 'You do not have permission to access this project.';
            route = '';
            break;
          case 'subscription_required':
            analyticsAction = ANALYTICS_EVENTS.EDITOR_BLOCKED.ACTIONS.SUBSCRIPTION_REQUIRED;
            title = 'Action Required';
            message = 'Please activate your subscription to continue editing this project.';
            break;
          case 'subscription_canceled':
            analyticsAction = ANALYTICS_EVENTS.EDITOR_BLOCKED.ACTIONS.SUBSCRIPTION_CANCELED;
            title = 'Action Required';
            message = 'Please activate your subscription to continue editing this project.';
            break;
          case 'subscription_pastdue':
            analyticsAction = ANALYTICS_EVENTS.EDITOR_BLOCKED.ACTIONS.SUBSCRIPTION_PASTDUE;
            title = 'Action Required';
            message =
              'Your subscription is past due and must be current before you can edit this project';
            break;
        }
        var val = await this.$root.$alert(title, message, opts);
        if (val && route) this.$router.push(route);
        if (analyticsAction) {
          this.$mixpanel.trackEvent(ANALYTICS_EVENTS.EDITOR_BLOCKED.NAME, analyticsAction);
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
