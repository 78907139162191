<template>
  <mcs-btn
    small
    color="accent"
    class="ml-2"
    :prepend-icon="expanded ? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all'"
    @click="expand"
  >
    {{ expanded ? 'Collapse' : 'Expand' }}
  </mcs-btn>
</template>

<script>
import { ANALYTICS_EVENTS } from '@/utils/constants';
import { mapGetters } from 'vuex';
export default {
  name: 'MapExpand',
  props: { expanded: { type: Boolean, default: false } },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      is_eddm: 'snailblast/campaign/is_eddm',
      is_list_purchase: 'snailblast/campaign/is_list_purchase',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },
  methods: {
    expand() {
      if (!this.expanded) {
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
          ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.MAP_EXPAND,
          this.analytics_data
        );
      }
      this.$emit('expand');
    }
  }
};
</script>
