<template>
  <mcs-sheet
    no-padding
    class="d-flex-hide flex-column h-full"
  >
    <!-- If we are not iframed we will show the 'Add Another Project' button -->
    <v-row
      v-if="!iframed"
      class="flex-grow-0 px-4 mt-2"
      dense
    >
      <v-col cols="12">
        <mcs-btn
          block
          color="black"
          large
          outlined
          prepend-icon="mdi-plus-circle"
          @click="$emit('add')"
        >
          Print another project
        </mcs-btn>
      </v-col>
    </v-row>

    <!-- error showing validation -->
    <v-row
      v-if="linesInvalid"
      class="flex-grow-0 px-4 mt-5"
      dense
    >
      <v-col cols="12">
        <mcs-alert
          type="alert"
          prepend-icon="mdi-alert-circle"
          text="Delivery dates have changed. Update below"
        />
      </v-col>
    </v-row>

    <!-- If we are iframed and the user has more than 1 project then we will show the "Dashboard Print" option -->
    <v-row
      v-if="iframed && projectCount > 1"
      dense
      class="flex-grow-0 px-4"
    >
      <v-col cols="12">
        <mcs-info-card>
          <div class="d-flex align-center">
            <div
              style="font-size: 30px"
              class="mr-4"
            >
              👋
            </div>
            <div>
              <div>
                You are using<span class="font-weight-bold font-italic ml-1">Express Checkout</span>
              </div>
              <div class="body-2 mt-1">
                To order prints of multiple projects, please checkout from your dashboard by
                clicking
                <span
                  class="primary--text hover-pointer"
                  @click="goDashboard"
                  >here</span
                >.
              </div>
            </div>
          </div>
        </mcs-info-card>
      </v-col>
    </v-row>

    <!-- Render each cart line-->
    <div class="h-full d-flex flex-column overflow-hidden-x autoscroll-y mb-4">
      <v-row
        class="flex-grow-0 pa-4"
        dense
      >
        <v-col
          v-for="(item, index) in cart.lines"
          :key="index"
          cols="12"
        >
          <CartItem
            :item="item"
            @delete="deleteItem"
            @edit="$emit('edit', item)"
            @edit-shipping="$emit('edit-shipping', item)"
          />
        </v-col>
      </v-row>

      <!-- Design Review promo -->
      <v-row
        class="flex-grow-0"
        dense
      >
        <v-col cols="12">
          <DesignReview
            class="mx-4"
            bordered
          />
        </v-col>
      </v-row>
    </div>

    <Portal :to="`drawerAppend-${drawerId}`">
      <v-divider />
      <v-row class="flex-grow-0 pa-2 ma-0">
        <v-col cols="auto">
          <mcs-btn
            large
            color="black"
            :loading="validating"
            :disabled="linesInvalid"
            @click="goToCheckout"
          >
            Go to checkout
          </mcs-btn>
        </v-col>
        <v-col class="my-auto">
          <div class="d-flex align-center font-weight-bold">
            <div
              v-if="cart.totalPriceOriginal > cart.totalPrice"
              class="text-decoration-line-through mr-2"
            >
              {{ cart.subTotalPriceOriginal | currency }}
            </div>
            <div :class="{ 'success--text': cart.totalPriceOriginal > cart.totalPrice }">
              {{ cart.subTotalPrice | currency }}
            </div>
          </div>
          <div class="body-2">* Price includes shipping</div>
        </v-col>
      </v-row>
    </Portal>
  </mcs-sheet>
</template>

<script>
import CartItem from '@/components/print/checkout/CartItem';
import { ROUTES } from '@/utils/constants';
import snailblast from '@/utils/mixins/snailblast';
import { mapGetters } from 'vuex';
import DesignReview from './DesignReview';

export default {
  name: 'PrintCart',

  components: {
    DesignReview,
    CartItem
  },

  mixins: [snailblast],

  props: {
    drawerId: {
      type: String,
      default: ''
    },
    iframed: Boolean
  },

  data: () => ({
    validating: false,
    showCosts: false
  }),

  computed: {
    projectCount() {
      return this.$store.getters['projects/designed'].length;
    },
    linesInvalid() {
      return !!this.cart.lines.filter((x) => x.delivery && !x.delivery.valid).length;
    },
    allowCosts() {
      return this.isAdmin || !!this.runas;
    },
    ...mapGetters({
      cart: 'printcart/cart',
      material: 'lookups/material',
      project: 'projects/project',
      isAdmin: 'user/isAdmin',
      runas: 'config/runas'
    })
  },

  methods: {
    deleteItem() {
      if (!this.cart.lines.length) {
        this.$emit('empty');
      }
    },

    goDashboard() {
      var projectId = this.cart.lines[0].project.id;
      var url =
        this.$store.state.config.dashboardUrl +
        ROUTES.PROJECT_DETAIL.PATH +
        '/' +
        projectId +
        '?doPrint=true';
      window.parent.postMessage({ type: 'go-to-url', data: { url: url, newWindow: true } }, '*');
    },

    goToCheckout() {
      this.validating = true;
      this.$store
        .dispatch('printcart/getCart', { validate: true, calculateTax: true })
        .then(() => {
          var invalidItems = this.cart.lines.filter((x) => !x.delivery.valid);
          if (!invalidItems.length) {
            this.$emit('checkout');
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.validating = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
