import mixpanel from 'mixpanel-browser';
import store from '../store';

const mixpanelService = {
  initialize(token, proxyDomain) {
    mixpanel.init(token, {
      api_host: proxyDomain
    });
  },

  trackPage() {
    if (this.$reject()) return;
    var data = Object.assign(this.$getBaseObj(), { page_type: 'dashboard' });
    var name = 'page_view';
    mixpanel.track(name, data);
  },

  trackEvent(name, action, properties) {
    if (this.$reject()) return;
    try {
      var data = Object.assign(this.$getBaseObj(), properties || {});
      if (action) data.action = action;
      mixpanel.track(name, data);
    } catch {
      console.log('Error tracking mixpanel event: ', name, properties);
    }
  },

  peopleSetOnce(properties, userId) {
    if (this.$reject()) return;
    mixpanel.identify(userId);
    mixpanel.people.set_once(properties);
  },

  $getBaseObj() {
    var obj = {};
    if (store.state.config.route.meta?.iframe) obj.iframed = true;
    return obj;
  },

  $reject() {
    return localStorage.getItem('runas') !== null;
  }
};

export default mixpanelService;
