<template>
  <mcs-card
    class="greyLight"
    :title="title"
    :subtitle="subtitle"
    titleSizeClass="body-1 font-weight-bold"
    subtitleSizeClass="body-2"
  >
    <slot></slot>
  </mcs-card>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' }
  },
  computed: {}
};
</script>
