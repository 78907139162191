import { cloneDeep } from 'lodash';

const state = {
  items: [],
  loaded: false,
  selectedCardId: null
};

const getters = {
  selectedCard(state, rootState) {
    return cloneDeep(state.items.find((x) => x.id === state.selectedCardId));
  },

  selectList(state) {
    var arr = [];
    state.items.forEach((x) => {
      arr.push({ id: x.id, display: x.displaySimple });
    });
    return arr;
  },

  selectListWithAdd(state) {
    var arr = [{ id: 0, display: '---- Enter a new card ---' }];
    state.items.forEach((x) => {
      arr.push({ id: x.id, display: x.displaySimple });
    });
    return arr;
  }
};

const actions = {
  getAll({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('cards/getAll')
        .then((response) => {
          commit('SET_CARDS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  get({ commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('project/detail/' + id)
        .then((response) => {
          commit('SET_SELECTED_CARD', response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('cards/update', payload)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  delete({ commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`cards/delete/${id}`)
        .then((response) => {
          if (response.success) commit('SET_SELECTED_CARD', null);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_CARDS(state, cards) {
    state.items = cards || [];
    state.loaded = true;
  },

  SET_SELECTED_CARD_ID(state, cardId) {
    state.selectedCardId = cardId;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
