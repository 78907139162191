const state = {
  occupations: [],
  workAreas: [],
  years: [],
  months: [],
  countries: [],
  yesNo: [],
  units: [],
  materials: [],
  states: [],
  subscriptionCancelReasons: [],
  taxExemptCertificateStatuses: []
};

const getters = {
  material(state) {
    return (id) => {
      return state.materials.find((x) => x.id === id) || {};
    };
  },
  countries(state) {
    return state.countries || [];
  },
  states(state) {
    return (countryId) => {
      return state.states.filter((x) => x.countryId === countryId) || [];
    };
  },
  state_abbr(state) {
    return (id) => {
      return state.states.filter((x) => x.id === id).map((x) => x.abbr)[0] || '';
    };
  },
  units(state) {
    return state.units || [];
  }
};

const actions = {
  get({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('data/getLookupData')
        .then((response) => {
          commit('INITIALIZE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  INITIALIZE(state, payload) {
    state.occupations = payload.occupations;
    state.workAreas = payload.workAreas;
    state.years = payload.years;
    state.months = payload.months;
    state.countries = payload.countries;
    state.yesNo = payload.yesNo;
    state.units = payload.units;
    state.materials = payload.materials;
    state.states = payload.states;
    state.subscriptionCancelReasons = payload.subscriptionCancelReasons;
    state.taxExemptCertificateStatuses = payload.taxExemptCertificateStatuses;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
