import _ from 'lodash';

const methods = {
  lower(val) {
    return (val || '').toLowerCase();
  },
  upper(val) {
    return (val || '').toUpperCase();
  },
  capitalize(val) {
    return _.capitalize(val);
  }
};

export default methods;
