<template>
  <div>
    <FieldHeader
      :field="field"
      @back="$emit('back')"
      @save="applyFilters"
    />
    <v-divider />
    <div class="pa-4">
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row>
          <v-col cols="12">
            <template v-for="(value, i) in values">
              <v-text-field
                :key="i"
                v-model="values[i]"
                dense
                outlined
                hide-details="auto"
                :disabled="!!campaign_idea"
                :rules="values.length > 1 ? [$rules.required] : []"
                placeholder="Write in your filter"
                clearable
              >
                <template #append-outer>
                  <mcs-btn
                    v-if="values.length > 1 && !campaign_idea"
                    icon
                    color="black"
                    prepend-icon="mdi-close"
                    small
                    class="my-auto"
                    @click="removeFilter(i)"
                  ></mcs-btn>
                </template>
              </v-text-field>
              <div
                v-if="i != values.length - 1"
                :key="i + 'or'"
                class="ml-2 caption white--text"
              >
                <span
                  class="rounded pa-1 black"
                  style="position: relative; z-index: 100"
                >
                  or
                </span>
              </div>
            </template>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <mcs-btn
              v-if="!campaign_idea"
              text
              color="black"
              class="px-0"
              @click="addFilter"
            >
              Add additional keywords
            </mcs-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import FieldHeader from '@/components/print/checkout/snailblast/listpurchase/filters/field/Header';
import { mapGetters } from 'vuex';
export default {
  name: 'SnailblastListPurchaseFiltersText',
  components: { FieldHeader },
  props: { field: { type: Object, default: () => {} } },
  data() {
    return {
      values: [],
      valid: false
    };
  },
  computed: {
    ...mapGetters({
      lookup_field: 'snailblast/lookup/list_purchase_field',
      filters: 'snailblast/campaign/list_purchase_filters',
      campaign_idea: 'printcart/campaign_idea'
    })
  },
  created() {
    this.values = this.filters.filter((x) => x.field.id === this.field.id).map((x) => x.value);
    if (!this.values.length) {
      this.values = [''];
    }
  },
  methods: {
    addFilter() {
      this.values.push('');
    },
    removeFilter(index) {
      this.values.splice(index, 1);
    },
    applyFilters() {
      if (this.$refs.form.validate()) {
        let newFilters = this.filters.filter((x) => x.field.id !== this.field.id);
        newFilters = newFilters.concat(
          this.values.filter((x) => x).map((x) => ({ field: this.field, value: x, text: x }))
        );
        this.$emit('setfilters', newFilters);
      }
    }
  }
};
</script>
