<template>
  <v-navigation-drawer
    :id="id"
    v-model="show"
    temporary
    :hide-overlay="iframed"
    right
    app
    :stateless="stateless"
    touchless
    :width="computedWidth"
    :style="style"
    :height="height"
    :bottom="bottom || drawerData.bottom"
    :class="{ 'rounded-t-xl': bottom }"
  >
    <template
      v-if="!hideHeader"
      #prepend
    >
      <div
        class="py-4 pl-6 pr-2 d-flex justify-space-between align-center"
        style="min-height: 68px"
      >
        <div class="d-flex align-center">
          <slot name="customBack" />
          <mcs-btn
            v-if="enableBack && isTopDrawer && !hideBack"
            icon
            color="black"
            prepend-icon="mdi-keyboard-backspace"
            @click="goBack(false)"
          >
          </mcs-btn>
          <div class="text-h6 text-truncate">
            <v-icon
              v-if="titleIcon"
              :color="titleIconColor"
              class="mr-1"
              size="28"
            >
              {{ titleIcon }}
            </v-icon>
            {{ title }}
            <slot name="title" />
            <div
              v-if="subtitle"
              class="font-weight-normal body-1"
            >
              {{ subtitle }}
            </div>
          </div>
        </div>
        <div>
          <slot name="cart" />
          <mcs-btn
            icon
            color="black"
            prepend-icon="mdi-close"
            @click="close"
          >
          </mcs-btn>
        </div>
      </div>
      <v-divider />
    </template>
    <div
      v-if="loading"
      class="d-flex align-center justify-center h-full"
    >
      <v-progress-circular
        :size="90"
        color="primary"
        indeterminate
      />
    </div>
    <template v-if="(!loading && show) || preloadContent">
      <div
        v-show="!loading && show"
        :class="{ 'pa-5': padded }"
        class="d-flex-hide flex-column h-full"
      >
        <slot />
      </div>
    </template>
    <template #append>
      <PortalTarget :name="`drawerAppend-${id}`">
        <slot name="append" />
      </PortalTarget>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Drawer',

  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    titleIcon: {
      type: String,
      default: ''
    },
    titleIconColor: {
      type: String,
      default: '#000'
    },
    subtitle: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '450'
    },
    height: {
      type: String,
      default: '100%'
    },
    hideHeader: Boolean,
    preloadContent: Boolean,
    loading: Boolean,
    padded: Boolean,
    closeGoesBack: Boolean,
    bottom: Boolean,
    hideBack: Boolean,
    forceStateless: Boolean, // useful for ensuring the drawer always stays open even if clicked outside
    customBackFunc: {
      type: Function,
      default: null
    }
  },

  data() {
    return {
      mobileHeight: 0,
      drawerData: {}
    };
  },

  computed: {
    drawer() {
      return this.$store.state.drawers.items.find((x) => x.id === this.id);
    },

    enableBack() {
      return this.$store.getters['drawers/enableBack'];
    },

    isTopDrawer() {
      return this.id === this.$store.getters['drawers/topDrawer'].id;
    },

    show: {
      get() {
        return this.drawer.open;
      },

      set(val) {
        if (!val) {
          this.$store.commit('drawers/CLOSE_DRAWER', this.id);
        }
      }
    },

    stateless() {
      if (this.forceStateless) return true;
      if (this.enableBack && !this.isTopDrawer) return true;
      if (this.$store.getters['config/anyDialogsOpen']) return true;
      return false;
    },

    style() {
      var obj = { 'z-index': this.drawer.zIndex };
      if (this.$vuetify.breakpoint.mobile) {
        obj.height = this.mobileHeight + 'px';
      }
      if (this.height !== '100%') {
        obj.maxHeight = this.height + ' !important';
      }
      if (this.bottom || this.drawerData.bottom) {
        obj.top = 'inherit';
        obj.bottom = '0px !important';
        if (this.$vuetify.breakpoint.mobile) {
          obj.height = '90%';
        } else if (this.height !== '100%') {
          obj.height = this.height;
        } else {
          // if the height hasn't been set anywhere we'll set it to something less than 100%
          // because there is not a case where a bottom drawer should ever have 100% height
          obj.height = '75%';
        }
      }
      return obj;
    },

    computedWidth() {
      if (this.iframed) return '100%';
      return this.width || '450';
    },

    iframed() {
      return this.$store.state.config.route.meta?.iframe;
    },

    ...mapGetters({
      drawerOpenedAt: 'drawers/lastOpenedAt',
      initialData: 'drawers/data'
    })
  },

  watch: {
    show(val) {
      if (val && this.$vuetify.breakpoint.mobile) {
        this.mobileHeight = window.innerHeight;
      }
    },
    drawerOpenedAt() {
      if (this.id === this.$store.getters['drawers/topDrawer']?.id) {
        this.drawerData = this.initialData(this.id) || {};
      }
    }
  },

  methods: {
    goBack(isClose) {
      if (this.customBackFunc) this.customBackFunc(isClose);
      else this.$store.commit('drawers/GO_BACK');
    },

    close() {
      if (this.closeGoesBack || this.drawerData.closeGoesBack) {
        this.goBack(true);
        return;
      }
      if (this.iframed) {
        window.parent.postMessage({ type: 'close-iframed-drawers' }, '*');
      } else {
        this.$store.commit('drawers/CLOSE_ALL');
      }
    }
  }
};
</script>

<style></style>
