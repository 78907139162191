export default {
  methods: {
    async runas(user) {
      if (!this.$store.getters['user/isAdmin']) return;
      if (!user) {
        user = await this.$root.$confirm(
          'Run As',
          'Enter the email address of the user to run as.',
          { showTextInputField: true }
        );
      }
      var opts = { cancelText: '', okText: 'Close' };
      if (user) {
        this.$store
          .dispatch('user/runas', user)
          .then((result) => {
            if (result.error) {
              this.$root.$alert('Unable to Runas User', result.error, opts);
            } else {
              localStorage.setItem('runas', user);
              window.location.href = this.$store.state.config.dashboardUrl;
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              this.$root.$alert('Access Denied', '', opts);
            }
          });
      }
    }
  }
};
