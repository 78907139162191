<template>
  <mcs-card
    class="overflow-hidden greyLight"
    :outlined="false"
  >
    <v-carousel
      v-if="!singleImageOnly"
      hide-delimiters
    >
      <template #prev="{ on, attrs }">
        <mcs-btn
          icon
          color="white"
          v-bind="attrs"
          prependIcon="mdi-arrow-left"
          v-on="on"
        >
        </mcs-btn>
      </template>
      <template #next="{ on, attrs }">
        <mcs-btn
          icon
          color="white"
          v-bind="attrs"
          prependIcon="mdi-arrow-right"
          v-on="on"
        />
      </template>
      <v-carousel-item
        v-for="(image, i) in imagesToRender"
        :key="i"
      >
        <mcs-sheet
          class="h-full d-flex align-center justify-center greyLight"
          noPadding
        >
          <v-img
            :src="image.url"
            :lazy-src="image.thumbUrl"
            max-width="90%"
            max-height="100%"
            contain
          />
        </mcs-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-img v-else-if="images.length">
      <mcs-sheet
        class="h-full d-flex align-center justify-center greyLight"
        noPadding
      >
        <v-img
          :src="imagesToRender[0].url"
          :lazy-src="imagesToRender[0].thumbUrl"
          max-width="90%"
          max-height="100%"
          contain
        />
      </mcs-sheet>
    </v-img>
  </mcs-card>
</template>

<script>
export default {
  name: 'ProjectPreviewCarousel',

  props: {
    images: {
      type: Array,
      default: () => []
    },
    id: {
      type: Number,
      default: 0
    },
    singleImageOnly: Boolean
  },

  data() {
    return {
      project: {}
    };
  },

  computed: {
    imagesToRender() {
      if (this.images.length) return this.images;
      if (this.project) return this.project.images;
      return [];
    }
  },

  mounted() {
    if (this.id) {
      this.$api.get(`project/detail/${this.id}`).then((response) => {
        this.project = response.data;
      });
    }
  },

  methods: {}
};
</script>

<style scoped></style>
