<template>
  <div class="h-full d-flex flex-column autoscroll-y mt-4 overflow-hidden">
    <UploadChange @reset="$emit('reset')" />
    <mcs-sheet
      v-if="saving"
      outlined
      no-padding
      class="d-flex h-full flex-column autoscroll-y mt-4"
    >
      <Loading
        path="//res.cloudinary.com/mycreativeshop-blog/raw/upload/v1716519622/lf20_vubr23l1.json"
        :width="200"
        :height="200"
        title="Reviewing your list"
        subtitle="This can take a minute depending on the size of your list."
      />
    </mcs-sheet>
    <template v-else>
      <mcs-sheet
        outlined
        no-padding
        class="d-flex h-full flex-column mt-4"
      >
        <UploadMappingFormat
          v-if="tab == 0"
          ref="format"
          @guide="$emit('guide')"
          @preview="tab = 1"
        />
        <UploadMappingPreview
          v-else-if="tab == 1"
          @guide="$emit('guide')"
          @connect="tab = 0"
        />
        <v-divider />
        <div class="pa-4">
          <mcs-btn
            block
            text
            :prepend-icon="tab == 0 ? 'mdi-eye-outline' : ''"
            color="black"
            @click="tab = tab == 0 ? 1 : 0"
          >
            <template v-if="tab == 0"> Preview </template>
            <template v-else> &lt; Connect Fields </template>
          </mcs-btn>
        </div>
      </mcs-sheet>
    </template>
  </div>
</template>

<script>
import Loading from '@/components/print/checkout/snailblast/Loading';
import UploadChange from '@/components/print/checkout/snailblast/upload/Change';
import UploadMappingFormat from '@/components/print/checkout/snailblast/upload/mapping/format/Index';
import UploadMappingPreview from '@/components/print/checkout/snailblast/upload/mapping/Preview';
import { ANALYTICS_EVENTS } from '@/utils/constants';
import { mapGetters } from 'vuex';
export default {
  name: 'SnailblastUploadMapping',

  components: { UploadMappingFormat, UploadChange, UploadMappingPreview, Loading },

  data: () => ({
    tab: 0
  }),

  computed: {
    ...mapGetters({
      saving: 'snailblast/campaign/saving',
      ready: 'snailblast/campaign/addresses_mapping_ready',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },

  watch: {
    tab(val, oldVal) {
      if (val !== oldVal) {
        const screen =
          val === 0
            ? ANALYTICS_EVENTS.MAILING_SERVICE.SCREENS.FORMAT
            : ANALYTICS_EVENTS.MAILING_SERVICE.SCREENS.FORMAT_PREVIEW;
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
          null,
          Object.assign(this.analytics_data, { screen })
        );
      }
    }
  },

  mounted() {
    this.$mixpanel.trackEvent(
      ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
      null,
      Object.assign(this.analytics_data, {
        screen: ANALYTICS_EVENTS.MAILING_SERVICE.SCREENS.FORMAT
      })
    );
  },

  methods: {
    save() {
      this.tab = 0;
      this.$nextTick(() => {
        this.$refs.format.save();
      });
    }
  }
};
</script>
