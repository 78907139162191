<template>
  <v-chip
    small
    :color="color"
    :text-color="textColor"
    :to="to"
  >
    <v-img
      v-if="avatarUrl"
      :src="avatarUrl"
      :width="15"
      class="mr-1"
    />
    <slot></slot>
    <v-icon
      v-if="appendIcon"
      class="ml-1"
      small
    >
      {{ appendIcon }}
    </v-icon>
  </v-chip>
</template>

<script>
import { COLORS } from '../../utils/constants';
export default {
  props: {
    color: { type: String, default: '' },
    avatarUrl: { type: String, default: '' },
    appendIcon: { type: String, default: '' },
    to: { type: String, default: '' }
  },
  computed: {
    textColor() {
      const colors = Object.keys(COLORS).map((x) => COLORS[x]);

      const color = colors.find((x) => x.HEX === this.color || x.NAME === this.color);
      if (color && color.NAME !== COLORS.GREY.NAME && color.NAME !== COLORS.WHITE.NAME) {
        if (color.NAME.includes('Light')) {
          const newColor = colors.find((x) => x.NAME === color.NAME.replace('Light', ''));
          if (newColor) {
            return newColor.HEX;
          }
        } else {
          return COLORS.WHITE.HEX;
        }
      }
      return COLORS.BLACK.HEX;
    }
  }
};
</script>
