import { ROUTES } from '../../../utils/constants';

const state = {
  links: [
    {
      name: 'Category Setup',
      route: { name: ROUTES.ADMIN_PRODUCT_SETUP.NAME }
    },
    {
      name: 'Industry Setup',
      route: { name: ROUTES.ADMIN_SETUP_INDUSTRIES.NAME }
    }
  ],
  margins: []
};

const getters = {
  margins: (state) => {
    return state.margins || [];
  },
  margin(state) {
    return (categoryId, coreProductId, materialId, quantity, optionGroupId) => {
      const margins = state.margins.filter((x) => x.optionGroupId === (optionGroupId || null));
      // quantity check
      let margin = null;
      margin = margins.find(
        (x) =>
          x.quantity === quantity &&
          x.materialId === materialId &&
          x.coreProductId === coreProductId
      );
      if (margin) {
        return margin.margin;
      }
      // material check
      margin = margins.find(
        (x) =>
          x.quantity == null && x.materialId === materialId && x.coreProductId === coreProductId
      );
      if (margin) {
        return margin.margin;
      }
      // core product check
      margin = margins.find(
        (x) => x.quantity == null && x.materialId == null && x.coreProductId === coreProductId
      );
      if (margin) {
        return margin.margin;
      }
      // category check
      margin = margins.find(
        (x) =>
          x.quantity == null &&
          x.materialId == null &&
          x.coreProductId == null &&
          x.categoryId === categoryId
      );
      if (margin) {
        return margin.margin;
      }
      // blanket check
      margin = margins.find(
        (x) =>
          x.quantity == null &&
          x.materialId == null &&
          x.coreProductId == null &&
          x.categoryId == null
      );
      if (margin) {
        return margin.margin;
      }
      return 0;
    };
  },
  default_margin(state) {
    return (categoryId, coreProductId, materialId, quantity, optionGroupId) => {
      return !state.margins.find(
        (x) =>
          x.optionGroupId === optionGroupId &&
          x.quantity === quantity &&
          x.materialId === materialId &&
          x.coreProductId === coreProductId &&
          x.categoryId === categoryId
      );
    };
  }
};

const mutations = {
  SET_MARGINS(state, list) {
    state.margins = list;
  }
};

const actions = {
  async getMargins({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('productSetup/getMargins')
        .then((response) => {
          commit('SET_MARGINS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async saveMargin({ commit }, item) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('productSetup/saveMargin', item)
        .then((response) => {
          commit('SET_MARGINS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
