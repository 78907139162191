<template>
  <Drawer
    :id="drawerId"
    :title="title"
    padded
    @close="show = false"
  >
    <div v-show="!success">
      <div class="mb-2">We'd love to hear about your experience.</div>

      <v-form ref="form">
        <v-rating
          v-model="rating"
          class="mb-5"
          backgroundColor="grey grey-lighten-1"
          :color="$vuetify.theme.themes.light.accent"
          size="32"
        />
        <v-text-field
          v-model="name"
          label="Name"
          placeholder="Name"
          persistent-placeholder
          outlined
          :rules="[$rules.required]"
        />
        <v-text-field
          v-model="company"
          label="Company"
          placeholder="Company"
          persistent-placeholder
          outlined
        />
        <v-textarea
          v-model="comment"
          label="Comment"
          outlined
          persistent-placeholder
          :rules="[$rules.required]"
        />

        <mcs-btn
          large
          block
          :loading="saving"
          @click="save"
        >
          Submit
        </mcs-btn>
      </v-form>
    </div>
    <v-slide-x-transition>
      <div v-show="success">
        <div class="text-center">
          <div>Your feedback is greatly appreciated!</div>
          <v-icon
            size="96"
            class="my-5"
            color="red"
          >
            mdi-heart
          </v-icon>
        </div>
        <mcs-btn
          large
          block
          @click="close"
        >
          Close
        </mcs-btn>
      </div>
    </v-slide-x-transition>
  </Drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import Drawer from '../../components/layout/Drawer';
import { DRAWERS } from '../../utils/constants';

export default {
  name: 'ReviewDrawer',

  components: {
    Drawer
  },

  data() {
    return {
      drawerId: DRAWERS.REVIEW,
      orderId: 0,
      rating: 0,
      name: '',
      company: '',
      comment: '',
      saving: false,
      success: false
    };
  },

  computed: {
    title() {
      return this.success ? 'Thank you for your review!' : 'Submit your review';
    },

    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        this.success = false;
        this.comment = '';
        var data = this.initialData(this.drawerId);
        this.rating = data.rating;
        this.orderId = data.orderId;
        this.name = this.$store.state.user.profile.name;
        this.company = this.$store.state.user.profile.company;
      }
    }
  },

  methods: {
    save() {
      var valid = this.$refs.form.validate();
      if (!valid) return;
      var payload = {
        orderId: this.orderId,
        rating: this.rating,
        name: this.name,
        company: this.company,
        comment: this.comment
      };
      this.saving = true;
      this.$store.dispatch('orders/submitReview', payload).finally(() => {
        setTimeout(() => {
          this.saving = false;
          this.success = true;
        }, 2000);
      });
    },

    close() {
      this.$store.commit('drawers/CLOSE_ALL');
    }
  }
};
</script>

<style></style>
