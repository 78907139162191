<template>
  <div>
    {{ field.name }}
    <v-tooltip
      v-if="field.description"
      top
    >
      <template #activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          :class="{ 'ml-1': true }"
          small
          color="grey"
          v-on="on"
        >
          mdi-information
        </v-icon>
      </template>
      <div class="tooltip-limit-width--large caption">
        {{ field.description }}
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'SnailblastListPurchaseFiltersFieldLabel',
  props: { field: { type: Object, default: () => {} } },
  data: () => ({})
};
</script>

<style lang="scss" scoped>
.tooltip-limit-width--large {
  max-width: 300px;
}
</style>
