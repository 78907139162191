<template>
  <mcs-sheet
    :outlined="bordered"
    no-padding
    class="relative overflow-hidden border-black accentLight"
  >
    <div>
      <img
        src="https://res.cloudinary.com/mycreativeshop/image/upload/f_auto/w_75/v1692115128/public/lady-clive"
        class="absolute"
        style="bottom: 0"
      />
      <div class="ml-10 pl-10 pr-4 pt-4 pb-4">
        <div class="d-flex align-center">
          <div class="body-1 mr-3 font-weight-bold">Design Review</div>
          <mcs-chip
            color="grey"
            class="border border-black"
          >
            Included for free
          </mcs-chip>
        </div>
        <div class="body-2 mt-2">
          Our print experts will review your design for alignment, bleed & image quality.
        </div>
      </div>
    </div>
  </mcs-sheet>
</template>

<script>
export default {
  name: 'DesignReview',
  props: {
    bordered: Boolean
  }
};
</script>
