<template>
  <mcs-card>
    <template #header>
      <div class="d-flex justify-space-between align-center mb-4">
        <div class="d-flex align-center">
          <v-icon>mdi-credit-card-outline</v-icon>
          <div class="font-weight-bold ml-2">Payment</div>
        </div>
        <div
          v-if="cards.length"
          class="font-weight-medium primary--text hover-pointer body-2"
          @click="add"
        >
          Add New Card +
        </div>
      </div>
    </template>
    <v-select
      v-if="cards.length > 0"
      v-model="selectionId"
      :items="cards"
      item-value="id"
      item-text="display"
      hide-details
      outlined
      label="Set Payment Method"
      persistent-placeholder
      class="mt-2"
      @change="$emit('paymentMethodSelected', selectionId)"
    >
      <template #append-outer>
        <mcs-btn
          icon
          class="mt-n2"
          prependIcon="mdi-credit-card-edit-outline"
          color="black"
          @click="edit"
        />
      </template>
    </v-select>
    <mcs-btn
      v-else
      outlined
      large
      color="black"
      block
      :loading="loading"
      prependIcon="mdi-plus"
      @click="add"
    >
      Debit / Credit Card
    </mcs-btn>
  </mcs-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { APP_EVENTS, DRAWERS } from '../../utils/constants';

export default {
  name: 'PaymentSelector',

  props: {
    bottomDrawer: Boolean
  },

  data() {
    return {
      selectionId: '',
      loading: false
    };
  },

  computed: {
    cards() {
      return this.$store.getters['cards/selectList'];
    },
    ...mapGetters({
      top_drawer: 'drawers/topDrawer'
    })
  },

  watch: {
    cards(val) {
      this.selectDefaultCard();
    }
  },

  mounted() {
    this.selectDefaultCard();
    if (!this.$store.state.cards.loaded) {
      this.loading = true;
      this.$store.dispatch('cards/getAll').finally(() => {
        this.loading = false;
      });
    }
    this.$eventBus.$on(APP_EVENTS.SET_PAYMENT_METHOD, (id) => {
      var match = this.cards.find((x) => x.id === id);
      if (match) this.slectionId = id;
    });
  },

  methods: {
    selectDefaultCard() {
      var id = '';
      if (this.cards.length) {
        var match = this.cards.find(
          (x) => x.id === this.$store.state.subscription.item.defaultPaymentMethodId
        );
        if (match) id = match.id;
        if (!match) id = this.cards[0].id;
      }
      this.selectionId = id;
      this.$emit('paymentMethodSelected', id);
    },

    add() {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.ADD_CARD_DRAWER,
        data: {
          bottom: this.bottomDrawer || this.top_drawer?.id !== DRAWERS.ADD_CARD_DRAWER,
          closeGoesBack: true
        }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.ADD_CARD_DRAWER);
    },

    edit() {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.UPDATE_CARD_DRAWER,
        data: {
          bottom: this.bottomDrawer || this.top_drawer?.id !== DRAWERS.UPDATE_CARD_DRAWER,
          closeGoesBack: true
        }
      });
      this.$store.commit('cards/SET_SELECTED_CARD_ID', this.selectionId);
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.UPDATE_CARD_DRAWER);
    }
  }
};
</script>

<style scoped></style>
