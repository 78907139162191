<template>
  <div class="error--text">
    {{ label }} ({{ value | separator }})
    <v-tooltip
      top
      max-width="300"
      min-width="250"
      content-class="white black--text border border-grey "
    >
      <template #activator="{ on, attrs }">
        <v-icon
          small
          class="ml-1 hover-pointer"
          color="error"
          v-bind="attrs"
          v-on="on"
        >
          mdi-information
        </v-icon>
      </template>
      <div class="body-2">
        {{ tooltip }}
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'AudienceError',
  props: {
    label: { type: String, default: '' },
    value: { type: Number, default: 0 },
    tooltip: { type: String, default: '' }
  },
  data() {
    return {};
  }
};
</script>
