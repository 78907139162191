<template>
  <Drawer
    :id="drawerId"
    title="The Editor Title"
    hideHeader
    closeGoesBack
    forceStateless
    width="95%"
  >
    <div
      v-if="loadedCoreProductId || editorUrl"
      class="d-flex h-full"
    >
      <iframe
        :src="editorUrl"
        class="w-full h-full"
        style="border: none"
      />
    </div>
  </Drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import Drawer from '../../components/layout/Drawer';
import { DRAWERS } from '../../utils/constants';

export default {
  name: 'EitorDrawer',

  components: {
    Drawer
  },

  data() {
    return {
      drawerId: DRAWERS.EDITOR_DRAWER,
      editorUrl: '',
      loadedCoreProductId: 0
    };
  },

  computed: {
    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt'
    })
  },

  watch: {
    drawerOpenedAt(val, oldVal) {
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        var drawerData = this.initialData(this.drawerId);
        if (drawerData.coreProductId) {
          if (drawerData.coreProductId !== this.loadedCoreProductId) {
            this.loadedCoreProductId = drawerData.coreProductId;
            this.$api
              .get(`editor/getFileUploadUrl?coreProductId=${this.loadedCoreProductId}`)
              .then((response) => {
                this.editorUrl = `${response.data}&mcsIframed=true`;
              });
          }
        } else if (drawerData.editorUrl) {
          this.editorUrl = `${drawerData.editorUrl}&mcsIframed=true`;
        }
      }
    }
  },

  methods: {}
};
</script>
