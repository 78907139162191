export default {
  data() {
    return {};
  },

  methods: {
    $loadHubspot() {
      if (this.$store.getters['user/isMissionPrint'] && !this.$store.state.config.inDevEnv) {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.id = 'hs-script-loader';
        s.defer = true;
        s.src = '//js.hs-scripts.com/20215267.js';
        document.head.appendChild(s);
      }
    }
  }
};
