<template>
  <div>
    <CertificateUploadButton />
    <template v-if="certificates.length">
      <v-divider class="mt-5 mb-3" />
      <div class="body-2 mb-3 font-weight-bold">Manage your forms</div>
      <mcs-sheet
        v-for="certificate in certificates"
        :key="certificate.id"
        dense
        outlined
        class="d-flex flex-column mb-4 hover-pointer overflow-hidden"
        @click.native="goDetails(certificate.id)"
      >
        <div
          v-if="certificate.expired"
          class="font-weight-medium body-2 error--text d-flex flex-column error"
        >
          <div class="py-2 d-flex align-center justify-center white--text">
            <v-icon
              color="white"
              small
              class="mr-1"
            >
              mdi-alert-circle
            </v-icon>
            Please update this expired certificate.
          </div>
          <v-divider />
        </div>
        <div class="d-flex align-center pa-2">
          <div
            class="rounded-lg file-preview d-flex flex-shrink-0 align-center justify-center grey"
          >
            <v-icon> mdi-file-document</v-icon>
          </div>
          <div class="ml-4 d-flex flex-column w-min-0 body-2">
            <div
              class="font-weight-bold text-truncate"
              :title="certificate.name"
            >
              {{ certificate.name }}
            </div>
            <div>Modified: {{ certificate.modifiedDate | shortDateWithTime }}</div>
            <div v-if="certificate.expirationDate">
              <template v-if="certificate.expired"> Expired on: </template>
              <template v-else> Expires on: </template>
              {{ certificate.expirationDate | regularDate }}
            </div>
            <div class="mt-2">
              <v-icon :color="certificate.color"> mdi-circle-medium </v-icon>
              {{ certificate.status }}
            </div>
          </div>
        </div>
      </mcs-sheet>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CertificateUploadButton from './CertificateUploadButton';

export default {
  name: 'CertificateManagementView',

  components: {
    CertificateUploadButton
  },

  data() {
    return {
      saving: false
    };
  },

  computed: {
    ...mapGetters({
      certificates: 'user/taxExemptCertificates'
    })
  },

  watch: {},

  mounted() {},

  methods: {
    goDetails(id) {
      this.$emit('goDetails', id);
    }
  }
};
</script>

<style scoped>
.file-preview {
  width: 130px;
  height: 85px;
}
</style>
