<template>
  <div class="d-flex-hide flex-column justify-space-between h-full">
    <div class="pa-4 h-full">
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row>
          <template v-if="preview || !project.id">
            <v-col cols="12">
              <v-select
                v-model="edit_item.categoryId"
                outlined
                label="Category"
                :items="categories"
                hide-details="auto"
                item-text="name"
                item-value="id"
                :rules="[$rules.required]"
                @input="changeCategory"
              >
                <template #item="{ active, item, attrs, on }">
                  <div>
                    {{ item.name }}
                    <span
                      v-if="item.projectCount > 0"
                      class="caption ml-1"
                      >({{ item.projectCount }}
                      <span> project{{ item.projectCount > 1 ? 's' : '' }}</span
                      >)</span
                    >
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="edit_item.coreProductId"
                outlined
                label="Product"
                :items="coreProducts"
                hide-details="auto"
                item-text="name"
                item-value="id"
                :rules="[$rules.required]"
              >
                <template #item="{ active, item, attrs, on }">
                  <div>
                    {{ item.name }}
                    <span
                      v-if="item.projectCount > 0"
                      class="caption ml-1"
                      >({{ item.projectCount }}
                      <span> project{{ item.projectCount > 1 ? 's' : '' }}</span
                      >)</span
                    >
                  </div>
                </template>
              </v-select>
            </v-col>
          </template>

          <template v-if="!project.id && !preview">
            <v-col
              cols="12"
              class="pb-0"
            >
              <mcs-btn
                block
                outlined
                color="black"
                large
                prepend-icon="mdi-plus-circle"
                @click="addDesign"
              >
                Add your design
              </mcs-btn>
            </v-col>
            <v-col
              cols="12"
              class="pt-2"
            >
              <div class="body-2">
                Don't have a {{ category }}?
                <span
                  class="primary--text hover-pointer"
                  @click="newDesign"
                  >Design your own</span
                >
              </div>
            </v-col>
          </template>

          <v-col
            v-if="showMailingOptions"
            cols="12"
          >
            <v-text-field
              v-if="!!campaign_idea"
              disabled
              :value="campaign_idea.name"
              label="Campaign Idea"
              hide-details
              outlined
              :menu-props="{ maxWidth: '420px', maxHeight: '400px' }"
            />
            <v-select
              v-else
              v-model="mailingOption"
              :items="mailing_options"
              :menu-props="{ maxWidth: '420px', maxHeight: '400px' }"
              :label="mailingOption ? 'Mailing Service' : undefined"
              outlined
              placeholder="Do you need direct mail services?"
              hide-details="auto"
              :rules="[$rules.required]"
              return-object
              @change="resetCampaign"
            >
              <template #item="{ item, on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  class="py-3 px-7"
                  v-on="on"
                >
                  <v-list-item-action class="mr-5">
                    <v-img :src="item.iconUrl" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{ item.text }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.subText }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="showPrint">
          <v-col cols="12">
            <v-select
              v-model="edit_item.quantity"
              outlined
              label="Quantity"
              :items="quantities"
              hide-details="auto"
              item-text="display"
              item-value="value"
              :rules="[$rules.required]"
              @input="changeQuantity"
            />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="edit_item.materialId"
              outlined
              label="Material"
              :items="materials"
              item-text="name"
              item-value="id"
              hide-details="auto"
              :rules="[$rules.required]"
              @input="changeMaterial"
            />
            <v-text-field
              v-if="preview && material_quantity"
              class="mt-4"
              outlined
              hide-details
              readonly
              label="Provider"
              background-color="error"
              :value="material_quantity.provider"
            />
            <CupsleeveAlert v-if="isCupsleeve" />
            <IconList
              :items="materialTraits"
              classes="body-2 mb-1"
              class="mt-4"
            />
          </v-col>
          <template v-if="edit_item.materialId && edit_item.quantity">
            <template v-for="(group, i) in optionGroupsToShow">
              <v-col
                v-if="group.options.length"
                :key="i"
                cols="12"
              >
                <v-select
                  v-model="group.selectedValue"
                  outlined
                  :label="group.display"
                  :items="group.options"
                  :rules="[$rules.required]"
                  item-text="option.display"
                  item-value="productOptionId"
                  hide-details="auto"
                  :background-color="
                    preview && group.options.filter((x) => x.show).length == 0 ? 'error' : undefined
                  "
                  :disabled="group.options.length < 2"
                  @input="changeOption"
                />
              </v-col>
            </template>
          </template>
          <v-col cols="12">
            <mcs-card
              title="Shipping"
              @click.native="showShipping"
            >
              <template #title-append>
                <mcs-btn
                  v-if="shipping_items.length"
                  color="primary"
                  text
                >
                  Edit
                </mcs-btn>
              </template>
              <template v-if="shipping_items.length">
                <v-row
                  v-for="(item, i) in shipping_items"
                  :key="i"
                  dense
                >
                  <v-col cols="auto">
                    <v-icon
                      :color="item.iconColor"
                      x-small
                    >
                      {{ item.icon }}
                    </v-icon>
                  </v-col>
                  <v-col>
                    {{ item.label }}
                    <span class="text-decoration-underline">{{ item.text }}</span>
                  </v-col>
                </v-row>
                <mcs-btn
                  v-if="!edit_item.delivery"
                  block
                  outlined
                  large
                  color="black"
                  class="mt-4"
                >
                  Select Delivery
                </mcs-btn>
              </template>
              <mcs-btn
                v-else
                block
                outlined
                large
                color="black"
                prepend-icon="mdi-plus-circle"
              >
                Add Address
              </mcs-btn>
            </mcs-card>
          </v-col>
        </v-row>
        <EditItemOldEditorCheck
          v-else-if="mailingOption && oldEditor && !loading"
          :mailing-option="mailingOption"
        />
        <EditItemMailingGuideCheck
          v-else-if="mailingOption && (!mailingOption.enabled || guideInvalid) && !loading"
          :mailing-option="mailingOption"
          :sizeError="!mailingOption.enabled"
          :guideError="guideInvalid"
        />
        <EditItemSnailblast v-else-if="campaign" />
      </v-form>
    </div>

    <Portal
      v-if="!hideFooter"
      :to="`drawerAppend-${drawerId}`"
    >
      <v-divider />
      <v-row class="pa-2 ma-0">
        <v-col cols="auto">
          <mcs-btn
            v-if="!preview"
            large
            color="black"
            :loading="saving"
            :disabled="disableButton"
            @click="save($event)"
          >
            {{ buttonText }}
          </mcs-btn>
        </v-col>
        <v-col class="my-auto">
          <div
            v-if="edit_item.campaign"
            class="body-2"
          >
            Total for print, postage & mailing
          </div>
          <div class="font-weight-bold d-flex align-center">
            <div
              v-if="totalPriceOriginal > totalPrice"
              class="font-weight-bold text-decoration-line-through mr-2"
            >
              {{ totalPriceOriginal | currency }}
            </div>
            <div :class="{ 'success--text': totalPriceOriginal > totalPrice }">
              {{ totalPrice | currency }}
            </div>
          </div>
          <div
            v-if="deliveryPrice"
            class="body-2"
          >
            Delivery {{ edit_item.delivery.earliestDate | regularDateNoYear }} -
            {{ edit_item.delivery.latestDate | regularDateNoYear }} (+{{
              edit_item.delivery.price | currency
            }})
          </div>
        </v-col>
      </v-row>
    </Portal>
  </div>
</template>

<script>
import CupsleeveAlert from '@/components/print/categoryAlerts/CupsleeveAlert';
import EditItemMailingGuideCheck from '@/components/print/checkout/edititem/MailingGuideCheck';
import EditItemOldEditorCheck from '@/components/print/checkout/edititem/OldEditorCheck';
import EditItemSnailblast from '@/components/print/checkout/edititem/Snailblast';
import IconList from '@/components/utilities/IconList';
import { ANALYTICS_EVENTS, APP_EVENTS, DRAWERS, ENUMS } from '@/utils/constants';
import snailblast from '@/utils/mixins/snailblast';
import { cloneDeep, orderBy, sortBy, uniq } from 'lodash';
import { mapGetters } from 'vuex';
import copyObject from '../../../../utils/copyObject';

export default {
  components: {
    IconList,
    CupsleeveAlert,
    EditItemSnailblast,
    EditItemMailingGuideCheck,
    EditItemOldEditorCheck
  },

  mixins: [snailblast],
  props: {
    drawerId: {
      type: String,
      default: ''
    },
    preview: Boolean,
    hideFooter: Boolean,
    iframed: Boolean
  },

  data: () => ({
    saving: false,
    valid: true,
    loading: false,
    option_groups: [],
    mailingOption: null,
    loaded: false
  }),

  computed: {
    shipping_items() {
      const list = [];
      if (this.edit_item.addressId) {
        list.push({
          label: 'Address',
          text: this.addresses
            .filter((x) => x.id === this.edit_item.addressId)
            .map((x) => `${x.firstName} ${x.lastName} - ${x.line1} ${x.city}, ${x.postalCode}`)[0],
          icon: 'mdi-check-circle',
          iconColor: 'success'
        });
      }
      if (this.edit_item.delivery) {
        if (!this.edit_item.delivery.earliestDate) {
          if (this.edit_item.addressId) {
            list.push({
              label: 'Delivery Date not set',
              icon: 'mdi-alert-circle',
              iconColor: 'error'
            });
          }
        } else {
          list.push({
            label: 'Expected Delivery Date',
            text: `${this.$options.filters.regularDateNoYear(
              this.edit_item.delivery.earliestDate
            )} - ${this.$options.filters.regularDateNoYear(this.edit_item.delivery.latestDate)}`,
            icon: this.edit_item.delivery.valid ? 'mdi-check-circle' : 'mdi-alert-circle',
            iconColor: this.edit_item.delivery.valid ? 'success' : 'accent'
          });
        }
      }
      return list;
    },

    disableButton() {
      if (this.loading || this.loading_price || !this.project.id) {
        return true;
      }
      if (
        (!this.shipping_items.length === 2 || !this.edit_item.delivery?.valid) &&
        (!this.campaign || !this.campaign?.recipientCount || !this.edit_item.delivery?.earliestDate)
      ) {
        return true;
      }

      return false;
    },

    isAdd() {
      return !this.edit_item.id;
    },

    buttonText() {
      return this.isAdd ? 'Add to cart' : 'Continue';
    },

    category() {
      if (this.project && this.project.category) {
        return this.project.category.toLowerCase();
      }
      return (
        this.categories
          .filter((x) => x.id === this.edit_item.categoryId)
          .map((x) => x.name.toLowerCase())[0] || 'project'
      );
    },

    isCupsleeve() {
      return this.category === 'cup sleeve';
    },

    deliveryPrice() {
      return this.edit_item.delivery && this.edit_item.addressId
        ? this.edit_item.delivery.price
        : 0;
    },

    totalPrice() {
      // return this.deliveryPrice + this.price;
      return this.price;
    },

    totalPriceOriginal() {
      // return this.deliveryPrice + this.priceOriginal;
      return this.priceOriginal;
    },

    quantities() {
      return uniq(sortBy(this.material_quantities, 'value').map((y) => y.value)).map((x) => ({
        value: x,
        display: this.$options.filters.separator(x)
      }));
    },

    materials() {
      return orderBy(
        this.material_quantities
          .filter((x) => x.value === this.edit_item.quantity)
          .map((x) => x.material),
        ['gsm', 'name'],
        ['asc', 'asc']
      );
    },

    materialTraits() {
      var iconList = [];
      if (
        this.material_quantity &&
        this.material_quantity.material &&
        this.material_quantity.material.traits
      ) {
        this.material_quantity.material.traits.forEach((x) => {
          iconList.push({ text: x, icon: 'mdi-circle-medium' });
        });
      }
      return iconList;
    },

    // all options groups that are shown to the user
    optionGroupsToShow() {
      let list = this.option_groups;
      if (!this.preview) {
        list = list.filter((x) => x.show);
      }
      return list;
    },

    pageCount() {
      return this.edit_item.pageCount;
    },

    showMailingOptions() {
      return this.mailing_options.length;
    },

    guideInvalid() {
      if (this.mailingOption && this.project && this.project.id) {
        return this.guide.toLowerCase() !== this.getGuideType(this.mailingOption.campaignTypeId);
      }
      return false;
    },

    oldEditor() {
      if (this.mailingOption && this.project && this.project.id && !this.project.useFreedomEditor) {
        return this.guide.toLowerCase() !== this.getGuideType(this.mailingOption.campaignTypeId);
      }
      return false;
    },

    showPrint() {
      if (!this.showMailingOptions) {
        return true;
      }
      if (!this.campaign && this.mailingOption) {
        return true;
      }
      return false;
    },

    categories() {
      var categories = copyObject(this.$store.getters['templates/printableCategories']);
      var projects = this.$store.getters['projects/all'];
      categories.forEach((category) => {
        category.projectCount = projects.filter((x) => x.categoryId === category.id).length;
      });
      categories = orderBy(categories, ['projectCount'], ['desc']);
      return categories;
    },

    coreProducts() {
      var products = copyObject(
        this.$store.getters['printcart/core_products'](
          this.project?.categoryId || this.edit_item.categoryId
        )
      );
      var projects = this.$store.getters['projects/all'];
      products.forEach((product) => {
        product.projectCount = projects.filter((x) => x.coreProductId === product.id).length;
      });
      products = orderBy(products, ['projectCount', 'isDefault'], ['desc', 'desc']);
      return products;
    },

    edit_item: {
      get() {
        return this.$store.getters['printcart/edit_item'];
      },
      set(val) {
        this.$store.dispatch('printcart/setEditItem', val);
      }
    },

    campaign: {
      get() {
        return this.$store.getters['printcart/campaign'];
      },
      set(val) {
        this.$store.dispatch('printcart/setCampaign', val);
      }
    },

    loading_price: {
      get() {
        return this.$store.getters['printcart/loading_price'];
      },
      set(val) {
        this.$store.dispatch('printcart/setLoadingPrice', val);
      }
    },

    ...mapGetters({
      material_quantities: 'printcart/material_quantities',
      material_quantity: 'printcart/material_quantity',
      option_groups_def: 'printcart/option_groups',
      mailing_options: 'printcart/mailing_options',
      price: 'printcart/price',
      priceOriginal: 'printcart/priceOriginal',
      addresses: 'user/addresses',
      project: 'printcart/project',
      selected_routes: 'snailblast/routes/selected',
      isAdmin: 'user/isAdmin',
      edit_campaign: 'snailblast/campaign/edit_campaign',
      address_count: 'snailblast/campaign/address_count',
      guide: 'printcart/guide',
      campaign_idea: 'printcart/campaign_idea'
    })
  },

  watch: {
    'edit_item.coreProductId'(val, oldVal) {
      if (val !== oldVal && oldVal && val) {
        console.log('firing from edit_item.coreProductId watcher');
        this.getMaterialQuantities();
      }
    },
    option_groups_def(val, oldVal) {
      this.option_groups = val;
      // reset selectedValue back to what is already selected
      const selected = this.edit_item.optionIds;
      for (var item of this.option_groups) {
        for (var option of item.options) {
          if (
            selected.includes(option.productOptionId) &&
            option.productOptionId !== item.selectedValue
          ) {
            item.selectedValue = option.productOptionId;
          }
        }
      }
    },
    mailingOption(val, oldVal) {
      if (!this.loaded) {
        return;
      }
      if (!val || !val.campaignTypeId) {
        this.campaign = null;
        this.getPrice(true);
      } else if (val.enabled) {
        const newCampaign = Object.assign(cloneDeep(this.edit_item.campaign || {}), {
          typeId: val.campaignTypeId
        });
        if (!this.campaign || this.campaign?.typeId !== newCampaign.typeId) {
          this.campaign = newCampaign;
        }
      } else if (!this.project?.id) {
        // change core product and keep mailing option
        const alternateCoreProduct = this.coreProducts.filter(
          (x) => x.id === this.mailingOption.defaultCoreProductId
        )[0];

        this.edit_item.coreProductId = alternateCoreProduct.id;
        this.$nextTick(() => {
          this.mailingOption = alternateCoreProduct.mailingOptions.find(
            (x) => x.campaignTypeId === this.mailingOption.campaignTypeId
          );
          this.getPrice(true);
        });
      } else {
        console.log('not enabled');
      }
    },
    edit_item(val, oldVal) {
      if (val?.id !== oldVal?.id) {
        this.loading = true;
        this.setMailingOption();
      }
    },
    'edit_item.project.id'(val, oldVal) {
      if (this.showMailingOptions) {
        this.$store.dispatch('printcart/getGuide', { projectId: val });
        if (!this.loading) {
          if (this.campaign_idea) {
            this.mailingOption = this.mailing_options.find(
              (x) => x.campaignTypeId === this.campaign_idea.campaignTypeId
            );
          } else {
            this.mailingOption = null;
          }
        }
      }
    }
  },

  created() {
    this.$eventBus.$off(APP_EVENTS.REFRESH_SELECTED_PRINT_PROJECT);
    this.$eventBus.$on(APP_EVENTS.REFRESH_SELECTED_PRINT_PROJECT, (data) => {
      this.loading = true;
      this.$store.dispatch('printcart/refreshEditItemProject', data.projectId).then(() => {
        if (this.mailingOption) {
          this.campaign = Object.assign(this.edit_item.campaign || {}, {
            typeId: this.mailingOption.campaignTypeId
          });
          this.mailingOption.enabled = true;
        }
        this.loading = false;
        this.$store.commit('drawers/CLOSE_DRAWER', DRAWERS.EDITOR_DRAWER);
      });
    });

    this.$eventBus.$off(APP_EVENTS.GET_EDIT_ITEM_PRICE);
    this.$eventBus.$on(APP_EVENTS.GET_EDIT_ITEM_PRICE, (data) => {
      console.log('getPrice event ', data);
      if (!this.loading_price) {
        this.getPrice(true, data.qtyOverride);
      }
    });

    this.$eventBus.$off(APP_EVENTS.EDIT_ITEM_LOAD_DATA);
    this.$eventBus.$on(APP_EVENTS.EDIT_ITEM_LOAD_DATA, (data) => {
      console.log('loadData event ', data);
      this.loadData();
    });

    this.setMailingOption();
    console.log('created');
  },

  methods: {
    save(evt) {
      var approvalNeeded =
        this.$store.state.printcart.requireProjectApproval && !this.edit_item.project.printApproved;
      if (evt.target) {
        var contentEl = evt.target
          .closest('.v-navigation-drawer')
          .querySelector('.v-navigation-drawer__content');
        var scrollTo = contentEl;
        if (contentEl) {
          if (approvalNeeded) scrollTo = document.getElementById('projectPrintApproval');
        }
        contentEl.scrollTo({ top: scrollTo.scrollHeight, behavior: 'smooth' });
      }
      if (this.$refs.form.validate() && !approvalNeeded) {
        this.saving = true;
        this.$store
          .dispatch('printcart/saveOrderItem', this.getRequest())
          .then(() => {
            this.$emit('save');
          })
          .catch((error) => {
            console.log(error);
            this.$mixpanel.trackEvent(
              ANALYTICS_EVENTS.PRINT.NAME,
              ANALYTICS_EVENTS.PRINT.ACTIONS.CART_ERROR,
              { message: 'Error saving. ' + error.message }
            );
            this.$root.$alert('Error Occurred', 'An error occurred while saving.');
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },

    getPrice(skipValidation, qtyOverride) {
      if (skipValidation || (this.$refs.form && this.$refs.form.validate())) {
        this.loading_price = true;
        this.$store
          .dispatch('printcart/getPrice', {
            item: this.getRequest(qtyOverride),
            preview: this.preview
          })
          .then(() => {})
          .catch((error) => {
            console.log(error);
            this.$mixpanel.trackEvent(
              ANALYTICS_EVENTS.PRINT.NAME,
              ANALYTICS_EVENTS.PRINT.ACTIONS.CART_ERROR,
              { message: 'Error getting price. ' + error.message }
            );
            this.$root.$alert('Error Occurred', 'An error occurred while loading price.');
          })
          .finally(() => {
            this.loading_price = false;
          });
      }
    },

    async loadData(showShipping) {
      this.loading = true;
      if (this.preview || !this.project.id) {
        if (!this.edit_item.categoryId) {
          this.$set(this.edit_item, 'categoryId', this.categories.map((x) => x.id)[0]);
        }
        await this.$store.dispatch('printcart/getCoreProducts', this.preview).then(() => {
          if (!this.edit_item.coreProductId) {
            this.$set(this.edit_item, 'coreProductId', this.coreProducts.map((x) => x.id)[0]);
          }
        });
      }
      if (
        !this.edit_item.project.disablePrintPageSelection &&
        !this.edit_item.pageSelection?.pages
      ) {
        var pagesPayload = {};
        if (this.edit_item.id) {
          pagesPayload.printCartItemId = this.edit_item.id;
        } else {
          pagesPayload.projectId = this.project.id;
        }
        await this.$store.dispatch('printcart/getPrintablePages', pagesPayload);
      }
      this.getMaterialQuantities(true, showShipping);
      console.log('firing from loadData');
    },

    getMaterialQuantities(initial, showShipping) {
      this.$store
        .dispatch('printcart/getMaterialQuantities', {
          projectId: this.project.id,
          coreProductId: this.edit_item.coreProductId,
          preview: this.preview
        })
        .then(() => {
          if (!this.material_quantities.length) {
            this.$mixpanel.trackEvent(ANALYTICS_EVENTS.LIMITATION.NAME, null, {
              message: 'project not printable'
            });
            this.$root.$alert(
              'Custom Quote Needed',
              'This product is not printable from our online checkout. If you would like a custom quote please reach out to our support team.',
              { closeOnly: true }
            );
            this.$emit('selectProject');
            return;
          }
          if (this.isAdd) {
            this.edit_item.quantity = this.quantities[0].value;
            // here is where we can set the lowest priced product
            this.edit_item.materialId = this.getLowestPricedMaterialId();
          }
          // this.setColorSpec();

          if (initial) {
            this.$nextTick(() => {
              console.log('calling getPrice() from getMaterialQuantities');
              this.getPrice();
            });

            if (this.showMailingOptions) {
              this.$mixpanel.trackEvent(ANALYTICS_EVENTS.MAILING_SERVICE.NAME, null, {
                screen: ANALYTICS_EVENTS.MAILING_SERVICE.SCREENS.START
              });
            }
          }

          if (!initial) {
            this.changeQuantity();
          }

          if (showShipping) {
            this.showShipping();
          }

          this.setMailingOption();

          if (this.edit_item.project.id && this.showMailingOptions) {
            this.$store.dispatch('printcart/getGuide', { projectId: this.edit_item.project.id });
          }

          this.$nextTick(() => {
            this.$refs.form.resetValidation();
          });
        })
        .catch((error) => {
          console.log(error);
          this.$mixpanel.trackEvent(
            ANALYTICS_EVENTS.PRINT.NAME,
            ANALYTICS_EVENTS.PRINT.ACTIONS.CART_ERROR,
            { message: 'Error getting products. ' + error.message }
          );
          this.$root.$alert('Error Occurred', 'An error occurred while loading print checkout.');
        })
        .finally(() => {
          this.$emit('loaded');
          this.loading = false;
        });
    },

    changeCategory() {
      this.campaign = null;
      this.$set(this.edit_item, 'coreProductId', this.coreProducts.map((x) => x.id)[0]);
      // JF 4/4/24:  I don't think this call to getMaterialQuantities is needed. The watcher for edit_item.coreProductId calls it already
      // this.getMaterialQuantities();
    },

    changeQuantity() {
      if (this.materials.filter((x) => x.id === this.edit_item.materialId).length === 0) {
        this.edit_item.materialId = this.getLowestPricedMaterialId();
      }
      // this.setColorSpec();
      this.$nextTick(() => {
        console.log('calling getPrice() from changeQuantity');
        this.getPrice();
      });
      this.clearDelivery();
    },

    changeMaterial() {
      this.$nextTick(() => {
        console.log('calling getPrice() from changeMaterial');
        this.getPrice();
      });
      this.clearDelivery();
    },

    changeOption() {
      this.$nextTick(() => {
        console.log('calling getPrice() from changeOption');
        this.getPrice();
      });
      this.clearDelivery();
    },

    pageSelected(selection, page) {
      var pages = copyObject(this.edit_item.pageSelections);
      pages.forEach((x) => {
        if (x.number === page.number) {
          x.pageId = selection.pageId;
          x.previewUrl = selection.previewUrl;
        }
      });
      this.$store.commit('printcart/SET_EDIT_ITEM_PAGE_SELECTIONS', pages);
      var colorSpecChanged = this.setColorSpec();
      if (colorSpecChanged) this.getPrice();
    },

    clearDelivery() {
      this.edit_item.delivery = { earliestDate: null };
    },

    getRequest(qtyOverride) {
      this.edit_item.basePrice = this.price;
      if (!this.edit_item.campaign) {
        this.edit_item.optionIds = this.option_groups.map((x) => x.selectedValue).filter((x) => x);
      }
      const req = cloneDeep(this.edit_item);
      if (req.campaign) {
        if (!qtyOverride) {
          qtyOverride = this.address_count;
        }
        req.campaign = Object.assign(req.campaign, { recipientCount: qtyOverride });
        if (
          (this.mailingOption.maximum && qtyOverride > this.mailingOption.maximum) ||
          !qtyOverride
        ) {
          return null;
        }
      }
      return req;
    },

    getLowestPricedMaterialId() {
      return sortBy(
        this.material_quantities.filter((x) => x.value === this.edit_item.quantity),
        'cost'
      ).map((x) => x.material.id)[0];
    },

    managePages() {
      window.parent.postMessage({ type: 'open-pages-panel' }, '*');
    },

    addDesign(id) {
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.PRINT_SELECT_PROJECT);
    },

    newDesign() {
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.CATALOG_DRAWER);
    },

    showShipping() {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.PRINT_CHECKOUT_SHIPPING,
        data: { preview: this.preview }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.PRINT_CHECKOUT_SHIPPING);
    },

    setMailingOption() {
      this.loaded = false;
      if (this.showMailingOptions) {
        if (this.campaign) {
          this.mailingOption = this.mailing_options.find(
            (x) => x.campaignTypeId === this.campaign.typeId
          );
        } else if (this.edit_item.id) {
          this.mailingOption = this.mailing_options.find((x) => !x.campaignTypeId);
        } else if (this.campaign_idea) {
          this.mailingOption = this.mailing_options.find(
            (x) => x.campaignTypeId === this.campaign_idea.campaignTypeId
          );
        } else {
          this.mailingOption = null;
        }
      }
      this.loaded = true;
    },

    resetCampaign(val) {
      this.campaign = {};
      this.clearDelivery();
      this.$store.dispatch('snailblast/campaign/resetEddm');
      this.$store.dispatch('snailblast/campaign/resetUpload');
      let type = 'none';
      switch (val.campaignTypeId) {
        case ENUMS.SNAILBLAST.CAMPAIGN_TYPE.EDDM:
          type = ANALYTICS_EVENTS.MAILING_SERVICE.TYPE.EDDM;
          break;
        case ENUMS.SNAILBLAST.CAMPAIGN_TYPE.UPLOAD:
          type = ANALYTICS_EVENTS.MAILING_SERVICE.TYPE.UPLOAD;
          break;
        case ENUMS.SNAILBLAST.CAMPAIGN_TYPE.LIST_BUILDER:
          type = ANALYTICS_EVENTS.MAILING_SERVICE.TYPE.LIST_BUILDER;
          break;
      }
      this.$mixpanel.trackEvent(ANALYTICS_EVENTS.MAILING_SERVICE.NAME, null, { type });
    }
  }
};
</script>

<style scoped></style>
