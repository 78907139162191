<template>
  <tr>
    <td
      v-if="allowOverride"
      style="width: 175px"
    >
      <v-select
        v-if="allowOverride"
        v-model="showOverride"
        hide-details
        solo
        flat
        :items="overrideItems"
      />
    </td>
    <td
      v-else
      class="px-2 py-3"
      style="font-size: 1.15rem"
    >
      {{ label }}
    </td>
    <td class="px-2">
      <v-icon>mdi-arrow-right-thin</v-icon>
    </td>
    <td>
      <v-select
        v-if="!showOverride"
        :value="value"
        :items="items"
        solo
        dense
        flat
        hide-details="auto"
        :rules="rules"
        :placeholder="placeholder"
        clearable
        color="black"
        @change="$emit('input', $event)"
      />
      <v-text-field
        v-else
        :value="overrideValue"
        solo
        flat
        dense
        hide-details="auto"
        placeholder="Example: OR CURRENT RESIDENT"
        clearable
        maxlength="100"
        color="black"
        @input="$emit('override', $event)"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    label: { type: String, default: '' },
    value: { type: Number, default: -1 },
    rules: { type: Array, default: () => {} },
    allowOverride: { type: Boolean, default: false },
    overrideValue: { type: String, default: '' }
  },
  data: () => ({
    showOverride: false,
    loaded: false,
    overrideItems: [
      { text: 'Company', value: false },
      { text: 'Message', value: true }
    ]
  }),
  computed: {
    matchIconColor() {
      if (this.value >= 0) {
        return 'green';
      }
      if (this.rules && this.rules.length) {
        return 'error';
      }
      return 'lightborder';
    },
    placeholder() {
      return 'Connect a field';
    },
    items() {
      const list = this.mapping.headers.map((x) => ({ value: x.id, text: x.display }));
      return list;
    },
    ...mapGetters({
      mapping: 'snailblast/campaign/addresses_mapping'
    })
  },
  watch: {
    value(val, oldVal) {
      if (val == null || val === undefined) {
        this.clear();
      }
    },
    showOverride(val, oldVal) {
      if (val !== oldVal && this.loaded) {
        this.clear();
        this.clearOverride();
      }
    }
  },
  mounted() {
    if (this.allowOverride) {
      this.showOverride = !!this.overrideValue;
    }
    this.$nextTick(() => {
      this.loaded = true;
    });
  },
  methods: {
    clear() {
      this.$emit('input', -1);
    },
    clearOverride() {
      this.$emit('override', '');
    }
  }
};
</script>

<style lang="scss" scoped>
td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  &:first-child {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
  &:last-child {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }
}
</style>
